import { Controller } from 'stimulus';

const LEAVING_PAGE_MESSAGE =
  'You have unsaved changes. Are you sure you want to leave this page?';

export default class extends Controller {
  clean;
  initialFormValues;

  static targets = ['form', 'unsavedBadge'];

  connect() {
    //for more info: https://www.betterstimulus.com/events.html#how-to-cleanup-event-listeners-in-connect--disconnect
    this.boundHandleSubmit = this.handleSubmit.bind(this);
    this.formTarget.addEventListener('submit', this.boundHandleSubmit);

    this.boundGetInitialFormValues = this.getInitialFormValues.bind(this);
    // we need to wait for the DOM to be loaded before we can get the initial values
    // or certain values will not be present in the dom immediately
    window.addEventListener('DOMContentLoaded', this.boundGetInitialFormValues);

    this.boundTrixIsChanged = this.trixIsChanged.bind(this);
    window.addEventListener('trix-change', this.boundTrixIsChanged);
  }

  formIsChanged(_event) {
    this.setChanged('true');
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == 'turbo:before-visit') {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault();
        } else {
          event.returnValue = LEAVING_PAGE_MESSAGE;
          return event.returnValue;
        }
      }
    }
  }

  allowFormSubmission(_event) {
    this.setChanged('false');
  }

  setChanged(changed) {
    this.data.set('changed', changed);
  }

  isFormChanged() {
    return this.data.get('changed') == 'true';
  }

  deepCompare(obj1, obj2) {
    const props1 = Object.getOwnPropertyNames(obj1);
    const props2 = Object.getOwnPropertyNames(obj2);

    if (props1.length !== props2.length) {
      return false;
    }

    for (let i = 0; i < props1.length; i++) {
      const propName = props1[i];

      if (
        typeof obj1[propName] === 'object' &&
        typeof obj2[propName] === 'object'
      ) {
        if (!this.deepCompare(obj1[propName], obj2[propName])) {
          return false;
        }
      } else {
        if (obj1[propName] !== obj2[propName]) {
          return false;
        }
      }
    }

    return true;
  }

  getInitialFormValues() {
    const initialValues = {};
    const form = this.formTarget;
    const elements = form.elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.name) {
        if (element.type === 'checkbox') {
          initialValues[element.name] = element.checked;
        } else {
          initialValues[element.name] = element.value;
        }
      }
    }

    this.initialFormValues = initialValues;
    this.checkValues();
  }

  isFormClean() {
    let currentValues = {};
    const form = this.formTarget;
    const elements = form.elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.name) {
        if (element.type === 'checkbox') {
          currentValues[element.name] = element.checked;
        } else {
          currentValues[element.name] = element.value;
        }
      }
    }

    this.clean = this.deepCompare(this.initialFormValues, currentValues);
    return this.deepCompare(this.initialFormValues, currentValues);
  }

  checkValues() {
    const els = this.formTarget.querySelectorAll('input, textarea, select');

    this.isFormClean();

    els.forEach((el) => {
      el.addEventListener('change', () => {
        this.isFormClean();

        if (!this.clean) {
          this.unsavedBadgeTarget.classList.remove('!hidden');
        } else {
          this.unsavedBadgeTarget.classList.add('!hidden');
        }
      });
    });
  }

  trixIsChanged() {
    this.isFormClean();
    if (!this.clean) {
      this.unsavedBadgeTarget.classList.remove('!hidden');
    } else {
      this.unsavedBadgeTarget.classList.add('!hidden');
    }
  }

  handleSubmit() {
    this.getInitialFormValues();
    this.isFormClean(this.initialFormValues);
    this.unsavedBadgeTarget.classList.add('!hidden');
  }

  unmountFormEvents() {
    const els = this.formTarget.querySelectorAll('input, textarea, select');

    els.forEach((el) => {
      el.removeEventListener('change', () => {});
    });
  }

  disconnect() {
    this.formTarget.removeEventListener('submit', this.boundHandleSubmit);
    window.removeEventListener(
      'DOMContentLoaded',
      this.boundGetInitialFormValues
    );
    this.unmountFormEvents();

    window.removeEventListener('trix-change', this.boundTrixIsChanged);
  }
}
