export class MagnetError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MagnetError';
  }
}

export class MagnetUserRejectedRequestError extends MagnetError {
  constructor() {
    super('User rejected the request');
    this.name = 'MagnetError';
  }
}

export function extractWagmiErrorDetails(errorMessage: string) {
  const wellKnownErrorMessagesMarkers = [
    { prefix: 'Details: ', suffix: 'Version:' },
    { prefix: 'reverted with the following reason:', suffix: 'Contract Call:' },
  ];

  for (const { prefix, suffix } of wellKnownErrorMessagesMarkers) {
    const textDetails = substringBetween(errorMessage, prefix, suffix);
    if (textDetails) return textDetails;
  }

  return 'The operation could not be completed';
}

function substringBetween(
  text: string,
  prefix: string,
  suffix: string
): string | undefined {
  const detailsStartIndex = text.indexOf(prefix);
  const versionStartIndex = text.indexOf(suffix);
  if (detailsStartIndex === -1 || versionStartIndex === -1) return;

  const details = text.substring(
    detailsStartIndex + prefix.length,
    versionStartIndex
  );
  return details.trim();
}
