import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['actionElement', 'expandableElement'];

  declare actionElementTarget: HTMLAnchorElement;
  declare expandableElementTarget: HTMLElement;

  connect() {
    this.collapse();
  }

  toggle(event?: Event): void {
    event?.preventDefault();
    if (this.expandableElementTarget.classList.contains('expanded')) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand(): void {
    this.actionElementTarget.classList.remove('expand');
    this.expandableElementTarget.classList.add('expanded');
  }

  collapse(): void {
    this.actionElementTarget.classList.add('expand');
    this.expandableElementTarget.classList.remove('expanded');
  }
}
