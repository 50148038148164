import { Controller } from 'stimulus';
import debounce from 'debounce';

export default class extends Controller {
  static targets = ['selectSpan', 'unselectSpan'];

  static values = {
    toggled: Boolean,
    filterBy: String,
    option: String,
  };

  filterInputTarget = null;
  filterBadgeTarget = null;
  filterCountTarget = null;
  isToggled = false;

  connect() {
    const filterInputTarget = this.contentController.filterInputTargets.find(
      (input) => input.name === this.filterByValue
    );
    const filterBadgeTarget = this.contentController.filterBadgeTargets.find(
      (badge) =>
        badge.getAttribute('name') ===
        `${this.filterByValue}-${this.optionValue}`
    );
    const filterCountTarget = this.contentController.filterCountTargets.find(
      (count) =>
        count.getAttribute('name') === `selected-${this.filterByValue}-count`
    );
    this.filterInputTarget = filterInputTarget;
    this.filterBadgeTarget = filterBadgeTarget;
    this.filterCountTarget = filterCountTarget;

    if (filterInputTarget && !!filterInputTarget.value) {
      this.isToggled = filterInputTarget.value
        .split(',')
        .includes(this.optionValue);
    }

    this.toggle = debounce(this.toggle.bind(this), 300);
  }

  toggle() {
    this.isToggled ? this.removeFilterFromInput() : this.addFilterToInput();
  }

  removeFilterFromInput() {
    this.isToggled = false;
    // Check if this.optionValue is in the filterInputTarget value
    if (this.filterInputTarget.value.includes(this.optionValue)) {
      // Split the filterInputTarget value by comma into an array of values
      let values = this.filterInputTarget.value.split(',');

      // Remove the specified value from the array of values
      values = values.filter((item) => item !== this.optionValue);

      // If there are no values left, remove the key from the filterInputTarget
      if (values.length === 0) {
        this.updateFilter('');
      } else {
        // Otherwise, join the remaining values and set the key with the new value list
        this.updateFilter(values.join(','));
      }
      this.unselectSpanTarget.classList.add('hidden');
      this.selectSpanTarget.classList.remove('hidden');
      this.filterBadgeTarget.classList.add('hidden');
      this.decrementFilterCount();
    }
  }

  addFilterToInput() {
    this.isToggled = true;
    // If the filterInputTarget value is empty, set the key with the new value
    if (this.filterInputTarget.value === '') {
      this.updateFilter(this.optionValue);
      this.unselectSpanTarget.classList.remove('hidden');
      this.selectSpanTarget.classList.add('hidden');
      this.filterBadgeTarget.classList.remove('hidden');
      this.incrementFilterCount();
    } else {
      // Otherwise, append the new value to the filterInputTarget value unless it already exists
      const values = this.filterInputTarget.value.split(',');

      if (!values.includes(this.optionValue)) {
        values.push(this.optionValue);
        this.updateFilter(values.join(','));
        this.unselectSpanTarget.classList.remove('hidden');
        this.selectSpanTarget.classList.add('hidden');
        this.filterBadgeTarget.classList.remove('hidden');
        this.incrementFilterCount();
      }
    }
  }

  incrementFilterCount() {
    const count = parseInt(this.filterCountTarget.textContent, 10);
    this.filterCountTarget.textContent = count + 1;
  }

  decrementFilterCount() {
    const count = parseInt(this.filterCountTarget.textContent, 10);
    if (count > 0) this.filterCountTarget.textContent = count - 1;
  }

  updateFilter(newValue) {
    this.contentController.update({
      filterUpdate: {
        name: this.filterByValue,
        value: newValue,
      },
    });
  }

  get contentController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~=tables--content]'),
      'tables--content'
    );
  }
}
