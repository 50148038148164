import { Controller } from 'stimulus';
import { showElement } from '@/helpers/animations_helpers';

export default class extends Controller {
  static targets = [
    'add_item',
    'template',
    'alertTemplate',
    'associationsAmount',
  ];
  static values = { alertShown: { type: Boolean, default: false } };

  add_association(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content);

    this.dispatch('associationAdded', {
      detail: { message: 'Association added' },
    });
  }

  add_many_associations(event) {
    // Since add_association relies on the timestamp for generating unique ids,
    // we need to add a little delay here so no two items get the same id.
    const delayBetweenItems = 1;

    let currentIndex = 0;
    const maxIndex = parseInt(this.associationsAmountTarget.value);

    const addNextAssociation = () => {
      if (currentIndex < maxIndex) {
        this.add_association(event);
        currentIndex++;
        setTimeout(addNextAssociation, delayBetweenItems);
      } else {
        this.dispatch('finishAssociationAddedSequence', {
          detail: { message: 'Association sequence complete' },
        });
      }
    };
    addNextAssociation();
  }

  remove_association(event) {
    event.preventDefault();
    let timeJS = new Date();
    let timeRails =
      timeJS.getFullYear().toString() +
      '-' +
      (1 + timeJS.getMonth()).toString() +
      '-' +
      timeJS.getDate() +
      ' ' +
      timeJS.toString().slice(16, 25).trim() +
      '.000000';
    let wrapper = event.target.closest('.nested-fields');
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove();
    } else if (
      wrapper.querySelector("input[name*='custom_question_answers']") ==
        undefined &&
      wrapper.querySelector("input[name*='checkbox_acceptances']") ==
        undefined &&
      wrapper.querySelector(
        "input[name*='required_distribution_wallets_attributes']"
      ) == undefined
    ) {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    } else if (
      wrapper.querySelector(
        "input[name*='required_distribution_wallets_attributes']"
      ) != undefined
    ) {
      if (
        confirm(
          'Deleting this wallet requirement will also delete related wallet answers for this chain and deal. Are you sure you want to proceeed?'
        )
      ) {
        wrapper.querySelector("input[name*='_destroy']").value = 1;
        wrapper.style.display = 'none';
      }
    } else {
      let confirmationMessage = wrapper.querySelector(
        '.confirmation-message'
      ).textContent;
      if (confirm(confirmationMessage)) {
        wrapper.querySelector("input[name*='trashed_at']").value = timeRails;
        console.log(wrapper.querySelector("input[name*='trashed_at']").value);
        wrapper.style.display = 'none';
      }
    }
  }

  show_alert() {
    if (this.hasAlertTemplateTarget && !this.alertShownValue) {
      const clone =
        this.alertTemplateTarget.content.firstElementChild.cloneNode(true);

      this.add_itemTarget.insertAdjacentElement('afterend', clone);
      showElement(clone);
      this.alertShownValue = true;
    }
  }
}
