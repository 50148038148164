import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hideable', 'button'];

  toggle(event) {
    if (event.currentTarget.checked) {
      this.hideableTarget.classList.remove('hidden');
    } else {
      this.hideableTarget.classList.add('hidden');
    }
  }

  toggleWithButtons(event) {
    const clickedButton = event.currentTarget;
    const buttonIndex = clickedButton.getAttribute('data-button-index');
    this.buttonTargets.forEach((button) => {
      button.disabled = button === clickedButton;
    });

    this.hideableTargets.forEach((hideable) => {
      const hideableIndex = hideable.getAttribute('data-hideable-index');
      if (parseInt(hideableIndex) === parseInt(buttonIndex)) {
        hideable.classList.remove('hidden');
      } else {
        hideable.classList.add('hidden');
      }
    });
  }

  toggleWithCheckboxes(event) {
    const elementIndex = event.currentTarget.getAttribute('data-button-index');

    this.hideableTargets.forEach((hideable) => {
      const hideableIndex = hideable.getAttribute('data-hideable-index');
      if (parseInt(hideableIndex) === parseInt(elementIndex)) {
        hideable.classList.remove('hidden');
      } else {
        hideable.classList.add('hidden');
      }
    });
  }
}
