import { isAddress } from 'viem';
import TextWithLabelComponentController from '../../../lib/nakamoto/components/text_with_label/component_controller';
import Web3Controller from './web3_controller';
import debounce from 'debounce';
import { Web3Account } from '../web3/magnet/types';

export default class extends Web3Controller {
  static targets = [
    'chainSelect',
    'tokenTypeSelect',
    'tokenTypeSelectWrapper',
    'erc20View',
    'nonEvmView',
    'tokenAddressInput',
    'tokenAddressInputNonEvm',
    'tokenNameInput',
    'tokenSymbolInput',
    'tokenDecimalsInput',
    'tokenNameValue',
    'tokenSymbolValue',
    'tokenDecimalsValue',
    'wantedAmountInput',
    'submitButton',
    'textHelper',
    'tokenForm',
    'unitPriceForm',
    'tokenTab',
    'distributionTab',
    'unitPriceTab',
    'tokenPresent',
    'unitPricePresent',
    'tokenAmountInput',
    'nonEvmWarningText',
    'findTokenUrlField',
    'distributionMechanism',
    'noWalletConnectedWarning',
    'radioInput',
    'differentWalletConnectedWarning',
    'deckWarnings',
    'groupSection',
    'totalAfterFeeAmount',
    'generateBreakdownButton',
  ];

  declare readonly chainSelectTarget: HTMLSelectElement;
  declare readonly tokenTypeSelectTarget: HTMLSelectElement;
  declare readonly tokenTypeSelectWrapperTarget: HTMLElement;
  declare readonly erc20ViewTargets: [HTMLElement];
  declare readonly nonEvmViewTargets: [HTMLElement];
  declare readonly tokenAddressInputTarget: HTMLInputElement;
  declare readonly tokenAddressInputNonEvmTarget: HTMLInputElement;
  declare readonly tokenNameInputTarget: HTMLInputElement;
  declare readonly tokenSymbolInputTarget: HTMLInputElement;
  declare readonly tokenDecimalsInputTarget: HTMLInputElement;
  declare readonly tokenNameValueTarget: HTMLElement;
  declare readonly tokenSymbolValueTarget: HTMLElement;
  declare readonly tokenDecimalsValueTarget: HTMLElement;
  declare readonly wantedAmountInputTarget: HTMLInputElement;
  declare readonly submitButtonTarget: HTMLButtonElement;
  declare readonly textHelperTarget: HTMLElement;
  declare readonly tokenTabTarget: HTMLElement;
  declare readonly unitPriceTabTarget: HTMLInputElement;
  declare readonly distributionTabTarget: HTMLElement;
  declare readonly tokenAmountInputTarget: HTMLInputElement;
  declare readonly tokenPresentTarget: HTMLInputElement;
  declare readonly unitPricePresentTarget: HTMLInputElement;
  declare readonly unitPriceFormTarget: HTMLFormElement;
  declare readonly tokenFormTarget: HTMLFormElement;
  declare readonly hasSubmitButtonTarget: boolean;
  declare readonly hasWantedAmountInputTarget: boolean;
  declare readonly nonEvmWarningTextTarget: HTMLElement;
  declare readonly findTokenUrlFieldTarget: HTMLInputElement;
  declare readonly distributionMechanismTarget: HTMLElement;
  declare readonly noWalletConnectedWarningTarget: HTMLDivElement;
  declare readonly radioInputTargets: HTMLInputElement[];
  declare readonly differentWalletConnectedWarningTarget: HTMLDivElement;
  declare readonly hasDifferentWalletConnectedWarningTarget: boolean;
  declare readonly deckWarningsTarget: HTMLDivElement;
  declare readonly hasDeckWarningsTarget: boolean;
  declare readonly groupSectionTargets: HTMLDivElement[];
  declare readonly totalAfterFeeAmountTarget: HTMLSpanElement;
  declare readonly generateBreakdownButtonTarget: HTMLButtonElement;

  static values = {
    editTokenForm: Boolean,
    creatorAddress: String,
  };

  declare readonly editTokenFormValue: boolean;
  declare readonly hasEditTokenFormValue: boolean;
  declare readonly creatorAddressValue: string;

  declare selectedRadioMechanism: HTMLInputElement;

  initialize() {
    this.fetchTokenData = debounce(this.fetchTokenData.bind(this), 300);
    this.handleTokenAmountChange = debounce(
      this.handleTokenAmountChange.bind(this),
      300
    );
  }

  async connect() {
    await super.connect();
    this.chainSelectTarget.disabled = false;
    this.tokenTypeSelectTarget.disabled = false;
    this.tokenAddressInputTarget.disabled = false;
    void this.load();
    void this.loadTabs();
    this.handleChecked();
    document.addEventListener('modalClosed', this.handleModalClosed);
  }

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountConnected(account: Web3Account) {
    super.onAccountConnected(account);
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountConnecting(account: Web3Account) {
    super.onAccountConnecting(account);
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountDisconnected(account: Web3Account) {
    super.onAccountDisconnected(account);
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  disconnect() {
    document.removeEventListener('modalClosed', this.handleModalClosed);
  }

  warnSignerMismatch(currentAccount: any, creatorAddress: string) {
    if (!this.hasDifferentWalletConnectedWarningTarget) return;

    const address = currentAccount?.address?.toLowerCase();
    if (!address || !creatorAddress) return;

    if (address != creatorAddress) {
      this.differentWalletConnectedWarningTarget.classList.remove('hidden');
      this.differentWalletConnectedWarningTarget.innerText = `Watch out! You are connected with ${address} but there are still unclaimed tokens from
        the previous distribution associated to the ${creatorAddress} wallet.\n
        If you continue, tokens distributed in this batch can not be automatically aggregated to the locked ones,
        so the investors will have to claim them in a separate transaction.\n
        To avoid that, please connect with wallet ${creatorAddress} and try again.`;
    } else {
      this.differentWalletConnectedWarningTarget.classList.add('hidden');
    }
  }

  // TODO: rename this action so it tells what it does
  // TODO: use a Turbo visit instead of a hard location change
  handleModalClosed(event: Event): void {
    window.location.href = window.location.pathname;
  }

  error(message: string): void {
    if (message.length > 0) {
      this.textHelperTarget.innerHTML = message;
      this.textHelperTarget.classList.add('text-error-medium');
      this.textHelperTarget.classList.remove('hidden');
      this.tokenAddressInputTarget
        ?.closest('.n-text-field')
        ?.classList?.add('rails-field-error');
    } else {
      this.tokenAddressInputTarget
        ?.closest('.n-text-field')
        ?.classList?.remove('rails-field-error');
      this.textHelperTarget.classList.remove('text-error-medium');
      this.textHelperTarget.classList.add('hidden');
    }
  }

  // TODO: rename this action so it tells what it does
  onChainSelected(): void {
    const chainType: string | undefined =
      this.chainSelectTarget.selectedOptions[0].dataset.chainRulesetTypeName;
    if (chainType === undefined) {
      this.tokenTypeSelectWrapperTarget.classList.add('hidden');
      this.hideERC20Views();
      this.hideNonEvmViews();
    } else {
      const nativeOption =
        this.tokenTypeSelectTarget.querySelector('[value="native"]');
      if (nativeOption)
        nativeOption.textContent = `${this.chainSelectTarget.selectedOptions[0].dataset.nativeTokenTicker} (Native)`;
      this.tokenTypeSelectWrapperTarget.classList.remove('hidden');
      this.onTokenTypeSelected();
    }
  }

  // TODO: rename this action so it tells what it does
  onNonEVMChainSelected(): void {
    this.showNonEvmViews();
    this.hideERC20Views();
  }

  // TODO: rename this action so it tells what it does
  onTokenTypeSelected(): void {
    const tokenType = this.tokenTypeSelectTarget.value;
    const chainType: string | undefined =
      this.chainSelectTarget.selectedOptions[0].dataset.chainRulesetTypeName;
    if (chainType === 'EVM') {
      this.nonEvmWarningTextTarget.classList.add('hidden');
      this.hideNonEvmViews();
      if (tokenType === 'erc20') {
        this.showERC20Views();
      } else {
        this.hideERC20Views();
      }
      this.load();
    } else {
      this.hideERC20Views();
      this.nonEvmWarningTextTarget.classList.remove('hidden');
      if (tokenType === 'erc20') {
        this.onNonEVMChainSelected();
      } else if (tokenType === 'native') {
        this.hideNonEvmViews();
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = false;
        }
      } else {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = true;
        }
      }
    }
  }

  // TODO: Move this behaviour to CSS so elements are toggled depending on HTML state
  showERC20Views(): void {
    this.erc20ViewTargets.forEach((element) => {
      element.classList.remove('hidden');
    });
    this.tokenAddressInputTarget.disabled = false;
    this.tokenNameInputTarget.disabled = false;
    this.tokenSymbolInputTarget.disabled = false;
    this.tokenDecimalsInputTarget.disabled = false;
  }

  // TODO: Move this behaviour to CSS so elements are toggled depending on HTML state
  hideERC20Views(): void {
    this.erc20ViewTargets.forEach((element) => {
      element.classList.add('hidden');
    });
    this.tokenAddressInputTarget.disabled = true;
    this.tokenNameInputTarget.disabled = true;
    this.tokenSymbolInputTarget.disabled = true;
    this.tokenDecimalsInputTarget.disabled = true;
  }

  // TODO: Move this behaviour to CSS so elements are toggled depending on HTML state
  showNonEvmViews(): void {
    this.nonEvmViewTargets.forEach((element) => {
      element.classList.remove('hidden');
    });
    this.nonEvmWarningTextTarget.classList.remove('hidden');
    this.tokenAddressInputNonEvmTarget.disabled = false;

    const address = this.tokenAddressInputNonEvmTarget.value;
    this.validateTokenRegex(address);
  }

  // TODO: Move this behaviour to CSS so elements are toggled depending on HTML state
  hideNonEvmViews(): void {
    this.nonEvmViewTargets.forEach((element) => {
      element.classList.add('hidden');
    });
    this.tokenAddressInputNonEvmTarget.disabled = true;
    this.textHelperTarget.textContent = ``;
    this.textHelperTarget.classList.add('hidden');
  }

  // TODO: rename this action so it tells what it does
  handleTokenInput(
    name: string,
    symbol: string,
    decimals: number,
    startLoading: boolean
  ): void {
    [
      {
        inputTarget: this.tokenNameInputTarget,
        valueTarget: this.tokenNameValueTarget,
        newValue: name,
      },
      {
        inputTarget: this.tokenSymbolInputTarget,
        valueTarget: this.tokenSymbolValueTarget,
        newValue: symbol,
      },
      {
        inputTarget: this.tokenDecimalsInputTarget,
        valueTarget: this.tokenDecimalsValueTarget,
        newValue: decimals,
      },
    ].forEach(({ inputTarget, valueTarget, newValue }) => {
      inputTarget.value = newValue as string;
      const valueTargetController =
        this.application.getControllerForElementAndIdentifier(
          valueTarget,
          'text-with-label--component'
        ) as TextWithLabelComponentController;

      if (startLoading) {
        this.tokenAddressInputTarget.disabled = true;
        valueTargetController?.startLoading();
      } else {
        this.tokenAddressInputTarget.disabled = false;
        valueTargetController?.stopLoading(newValue);
      }
    });
  }

  async load(): Promise<void> {
    // Reset everything upon new input.
    this.error('');
    const tokenType = this.tokenTypeSelectTarget.value;
    if (tokenType === 'erc20') {
      this.handleTokenInput('-', '-', 0, false);
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = true;
      }

      const tokenAddress = this.tokenAddressInputTarget.value;
      if (tokenAddress.length === 0) {
        return;
      }

      if (!isAddress(tokenAddress)) {
        this.error('Please enter a valid EVM-based contract address.');
        return;
      }

      let tokenInfo: [string, string, number] = ['', '', 0];

      // We try to load the token info. If it fails, we show an error.
      // Otherwise, we clear the fields info fields and stop showing the load spinner
      try {
        const chainIdentifier: string | undefined =
          this.chainSelectTarget.selectedOptions[0].dataset.chainIdentifier;
        if (chainIdentifier === '' || chainIdentifier === undefined) return;

        const tokenContract = await this.magnet?.getToken({
          address: tokenAddress,
          chainId: parseInt(chainIdentifier),
        });
        this.handleTokenInput('', '', 0, true);

        tokenInfo = [
          tokenContract.name,
          tokenContract.symbol,
          tokenContract.decimals,
        ];

        // If we were able to find the token, fill fields, enable submit and stop loading.
        const [name, symbol, decimals] = tokenInfo;

        this.handleTokenInput(name, symbol, decimals, false);

        if (this.hasWantedAmountInputTarget) {
          this.wantedAmountInputTarget.disabled = false;
          this.wantedAmountInputTarget.focus();
        }

        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = false;
        }

        if (name === '' || symbol === '') {
          let missingItems: [String?] = [];
          if (name === '') missingItems.push('name');
          if (symbol === '') missingItems.push('symbol');
          const missingItemsMessage = missingItems.join(' and ');

          this.textHelperTarget.textContent = `Token is missing ${missingItemsMessage}. You can continue, but please double check that the network and token address are correct.`;
          this.textHelperTarget.classList.remove('hidden');
          this.textHelperTarget.classList.add('text-error-medium');
        }
      } catch (e) {
        this.error(
          'Failed to load, please double check the token address and chain and then try again.'
        );
      }
    } else if (tokenType === 'native') {
      this.handleTokenInput('-', '-', 0, false);
      this.submitButtonTarget.disabled = false;
    } else {
      this.handleTokenInput('-', '-', 0, false);
      this.submitButtonTarget.disabled = true;
    }
  }

  loadTabs(): void {
    if (this.hasEditTokenFormValue && this.editTokenFormValue) {
      this.tokenTabTarget.style.display = 'block';
      return;
    }

    this.tokenTabTarget.style.display = 'none';
    this.distributionTabTarget.style.display = 'none';
    this.unitPriceTabTarget.style.display = 'none';

    if (
      this.tokenPresentTarget.value === 'true' &&
      this.unitPricePresentTarget.value === 'true'
    ) {
      this.distributionTabTarget.style.display = 'block';
    } else if (this.tokenPresentTarget.value === 'true') {
      this.unitPriceTabTarget.style.display = 'block';
    } else {
      this.tokenTabTarget.style.display = 'block';
    }
  }

  submitEditTokenForm(event: Event): void {
    event.preventDefault();
    const form = this.tokenFormTarget;

    const userInput = prompt(
      "Warning! Are you sure you want to change the token contract? Do not do this if you intend on distributing another token other than the token originating from the same project. Only do this if you know what you’re doing. Contact Spring if you’re uncertain. \n\nType 'I understand' to proceed."
    );

    if (userInput === 'I understand') {
      form.submit(); // Submit the form if the user types the correct phrase
    }
  }

  submitUnitPriceForm(event: Event): void {
    event.preventDefault();
    const form = this.unitPriceFormTarget;

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.unitPriceTabTarget.style.display = 'none';
          this.distributionTabTarget.style.display = 'block';
          this.tokenAmountInputTarget.value = data.newTokenAmount;
        } else {
          console.error(data.errors);
        }
      });
  }

  // TODO: Rename this action so it tells what it does
  handleTokenAmountChange(_event: Event): void {
    const tokenAmount = parseFloat(this.tokenAmountInputTarget.value);

    // Do nothing if amount is zero or not a number
    if (isNaN(tokenAmount) || tokenAmount <= 0) {
      return;
    }

    let totalAfterFeeAmount = 0;

    this.groupSectionTargets.forEach((groupSection) => {
      const shareAmount = parseFloat(groupSection?.dataset?.shareAmount ?? '0');
      const tokenCommissionRate = parseFloat(
        groupSection?.dataset?.tokenCommissionRate ?? '0'
      );

      const groupAmount = tokenAmount * shareAmount;

      const groupAfterFeeAmount =
        groupAmount * (1 - tokenCommissionRate / 100.0);

      const groupAmountTarget = groupSection.querySelector('.group-amount');
      if (groupAmountTarget) {
        groupAmountTarget.textContent =
          this.formatNumberToSixDecimals(groupAmount);
      }

      const groupAfterFeeAmountTarget = groupSection.querySelector(
        '.group-after-fee-amount'
      );
      if (groupAfterFeeAmountTarget) {
        groupAfterFeeAmountTarget.textContent =
          this.formatNumberToSixDecimals(groupAfterFeeAmount);
      }

      totalAfterFeeAmount += groupAfterFeeAmount;
    });

    this.totalAfterFeeAmountTarget.textContent =
      this.formatNumberToSixDecimals(totalAfterFeeAmount);
  }

  // TODO: Rename this action so it tells what it does
  handleTokenForm(event: Event): void {
    event.preventDefault();
    const form = this.tokenFormTarget;

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: 'application/json', // Let the server know we expect JSON response
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.tokenTabTarget.style.display = 'none';
          if (data.unitPrice !== null) {
            this.distributionTabTarget.style.display = 'block';
          } else {
            this.unitPriceTabTarget.style.display = 'block';
          }
          if (data.tokenAllowsDeckDistribution) {
            this.distributionMechanismTarget.classList.remove('hidden');
          } else {
            this.distributionMechanismTarget.classList.add('hidden');
          }
        } else {
          console.error(data.errors);
          this.textHelperTarget.textContent = data.errors.join(',');
          this.textHelperTarget.classList.remove('hidden');
          this.textHelperTarget.classList.add('text-error-medium');
        }
      });
  }

  validateTokenRegex(address: string) {
    let regexValidated: boolean = false;
    const chainRulesetRegex: string | undefined =
      this.chainSelectTarget.selectedOptions[0].dataset.chainRulesetRegex;
    if (address != '' && chainRulesetRegex) {
      const regex = new RegExp(chainRulesetRegex);
      if (regex.test(address)) {
        regexValidated = true;
        this.textHelperTarget.textContent = ``;
        this.textHelperTarget.classList.add('hidden');
      } else {
        this.textHelperTarget.textContent = `Invalid token address format for this network.`;
        this.textHelperTarget.classList.remove('hidden');
        this.textHelperTarget.classList.add('text-error-medium');
      }

      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = !regexValidated;
      }
    }
    return regexValidated;
  }

  async fetchTokenData() {
    const address = this.tokenAddressInputNonEvmTarget.value;
    const chainId = this.chainSelectTarget.value;
    const baseUrl = this.findTokenUrlFieldTarget.value;
    if (!address || !chainId || !baseUrl) return;

    if (!this.validateTokenRegex(address)) return;
  }

  async appendSigningWallet(event: Event) {
    if (this.selectedRadioMechanism?.value != 'deck') return;

    event.preventDefault();
    let form: HTMLFormElement = event.target as HTMLFormElement;
    // Disable submit button
    this.generateBreakdownButtonTarget.disabled = true;
    if (this.magnet?.web3Account?.address) {
      let input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute(
        'name',
        'distribution[signing_wallet_address_identifier]'
      );
      input.setAttribute(
        'value',
        this.magnet?.web3Account?.address.toLowerCase()
      );
      form.appendChild(input);
      form.submit();
    } else {
      this.noWalletConnectedWarningTarget.classList.remove('hidden');
    }
  }

  // TODO: Rename this action so it tells what it does
  // TODO: Move this behaviour to CSS so elements are toggled depending on HTML state
  handleChecked() {
    this.radioInputTargets.forEach((radio) => {
      if (radio.checked) {
        radio.parentElement.classList.add(
          'outline',
          'outline-1',
          'outline-blue-400',
          'hover:outline-sky-medium'
        );
        this.selectedRadioMechanism = radio;
      } else {
        radio.parentElement.classList.remove(
          'outline',
          'outline-1',
          'outline-blue-400',
          'hover:outline-sky-medium'
        );
      }
    });

    if (this.selectedRadioMechanism?.value == 'deck') {
      this.deckWarningsTarget.classList.remove('hidden');
    } else {
      if (this.hasDeckWarningsTarget) {
        this.deckWarningsTarget.classList.add('hidden');
      }
    }
  }

  formatNumberToSixDecimals(num: number) {
    // Round the number to 6 decimal places and convert to string
    let str = num.toFixed(6);

    // Use a regular expression to remove trailing zeroes
    str = str.replace(/(\.0+|(\.\d*[1-9])0+)$/, '$2');

    return str;
  }
}
