class Autolinker {
  constructor(element) {
    this.INPUT = document.createElement("input")
    this.INPUT.type = "url"
    this.INPUT.required = true

    this.PATTERN = /(https?:\/\/\S+\.\S+)\s/ig

    this.element = element
    this.editor = element.editor
    this.autolink = this.autolink.bind(this)
    this.element.addEventListener("trix-render", this.autolink)
    this.autolink()
  }

  autolink() {
    this.getURLswithRanges().forEach((urlWithRanges) => {
      const { url, range } = urlWithRanges
      if (this.getHrefAtRange(range) === url) return

      const currentRange = this.editor.getSelectedRange()
      this.editor.setSelectedRange(range)
      if (this.editor.canActivateAttribute("href"))
        this.editor.activateAttribute("href", url)
      this.editor.setSelectedRange(currentRange)
    })
  }

  getDocument() {
    return this.editor.getDocument()
  }

  getDocumentString() {
    return this.getDocument().toString()
  }

  getHrefAtRange(range) {
    return this.getDocument().getCommonAttributesAtRange(range).href
  }

  getURLswithRanges() {
    let results = []
    let string = this.getDocumentString()
    let match
    while (match = this.PATTERN.exec(string)) {
      const url = match[1]
      if (this.isValidURL(url)) {
        const position = match.index
        const range = [position, position + url.length]
        results.push({ url, range })
      }
    }
    return results
  }

  isValidURL(value) {
    this.INPUT.value = value
    return this.INPUT.checkValidity()
  }
}

addEventListener("trix-initialize", (event) => {
  new Autolinker(event.target)
})
