import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    key: String,
    content: String,
  };

  updateURL() {
    const originalUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(originalUrl.search);

    if (this.contentValue) {
      searchParams.set(this.keyValue, this.contentValue);
    } else {
      searchParams.delete(this.keyValue);
    }

    originalUrl.search = searchParams.toString();

    window.history.pushState({}, '', originalUrl);
  }
}
