import { Controller } from 'stimulus';
import debounce from '../lib/debounce';

class NavigationController extends Controller {
  private debouncedUpdateNavbar!: (...args: any[]) => void;

  connect(): void {
    // Call the updateNavbar function when the controller connects to ensure the navbar is styled correctly
    this.updateNavbar();

    // Wrap the updateNavbar call in the debounce function for performance reasons
    const debouncedUpdateNavbar = debounce(() => this.updateNavbar(), 10);
    window.addEventListener("scroll", debouncedUpdateNavbar);
  }

  disconnect(): void {
    window.removeEventListener("scroll", this.debouncedUpdateNavbar);
  }

  private updateNavbar():void {
    const element = this.element as HTMLElement;
    if (window.scrollY > 0) {
      element.style.backgroundColor = "var(--card-background-hex)";
      element.style.backdropFilter = "blur(50px)";
    } else {
      element.style.background = "transparent";
      element.style.backdropFilter = "blur(0px)";
    }
  }
}

export default NavigationController;
