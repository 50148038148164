import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["player", "missingTemplate", "lottieContainer"]

  connect() {
    this.play = this.play.bind(this)
    this.stop = this.stop.bind(this)
    this.setupPlayer = this.setupPlayer.bind(this)
    this.checkLottieLoaded = this.checkLottieLoaded.bind(this)

    // Try to setup player and schedule fallback check
    this.setupPlayer()
    this.checkTimeout = setTimeout(this.checkLottieLoaded, 100)
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.play)
    this.element.removeEventListener('mouseleave', this.stop)
    if (this.checkTimeout) {
      clearTimeout(this.checkTimeout)
    }
  }

  setupPlayer() {
    if (!this.hasPlayerTarget) return

    // Re-create the Lottie player element
    const currentPlayer = this.playerTarget
    const newPlayer = document.createElement('lottie-player')

    // Copy all attributes
    Array.from(currentPlayer.attributes).forEach(attr => {
      newPlayer.setAttribute(attr.name, attr.value)
    })

    // Replace the old player
    currentPlayer.parentNode.replaceChild(newPlayer, currentPlayer)

    // Setup the new player
    newPlayer.addEventListener('ready', () => {
      newPlayer.seek(0)
      newPlayer.stop()

      this.element.addEventListener('mouseenter', this.play)
      this.element.addEventListener('mouseleave', this.stop)
    })
  }

  checkLottieLoaded() {
    if (!this.hasLottieContainerTarget) return

    // Check if container is empty or if Lottie player is not properly initialized
    const lottieElement = this.lottieContainerTarget.querySelector('lottie-player')
    if (!lottieElement || !lottieElement.shadowRoot || lottieElement.shadowRoot.children.length === 0) {
      // If we have a template, use it as fallback
      if (this.hasMissingTemplateTarget) {
        this.lottieContainerTarget.innerHTML = this.missingTemplateTarget.innerHTML
      }
    }
  }

  play() {
    if (this.hasPlayerTarget) {
      this.playerTarget.setDirection(1)
      this.playerTarget.play()
    }
  }

  stop() {
    if (this.hasPlayerTarget) {
      this.playerTarget.setDirection(-1)
      this.playerTarget.play()
    }
  }
}
