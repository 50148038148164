import { Controller } from 'stimulus';

export default class ListController extends Controller {
  static targets = ['listItem']

  declare readonly listItemTargets: HTMLElement[];

  select(listItemId: string) {
    this.listItemTargets.forEach((listItem) => {
      if (listItem.dataset.listItemId === listItemId) {
        listItem.classList.add('border-b-2')
        listItem.classList.add('border-sky-medium');
        listItem.classList.add('selected');
      } else {
        listItem.classList.remove('selected');
        listItem.classList.remove('border-b-2')
        listItem.classList.remove('border-sky-medium');
      }
    });
  }

  unselectAll(): void {
    this.listItemTargets.forEach((listItem) => {
      listItem.classList.remove('selected');
      listItem.classList.remove('border-b-2')
      listItem.classList.remove('border-sky-medium');
    });
  }

  getSelectedListItem(): HTMLElement | undefined {
    return this.listItemTargets.find((listItem) => {
      return listItem.classList.contains('selected');
    });
  }
}
