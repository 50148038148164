import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly dropdownTarget: HTMLDivElement;
  declare readonly buttonTarget: HTMLDivElement;

  static targets = ["dropdown", "button"];

  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
    this.buttonTarget.classList.toggle("n-overflow__menu-btn--active");
  }

  handleWindowClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.matches(".n-overflow__menu-btn")) {
      const dropdowns = document.getElementsByClassName("n-overflow__dropdown");

      let i: number;
      for (i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];

        if (!this.dropdownTarget.classList.contains("hidden")) {
          openDropdown.classList.add("hidden");
          this.buttonTarget.classList.remove("n-overflow__menu-btn--active");
        }
      }
    }
  }
}
