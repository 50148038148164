import { Controller } from 'stimulus';
import gsap from 'gsap';

export default class extends Controller {
  static targets = [
    'displayName',
    'roiExplainer',
  ];

  connect() {
    // Store the final height for proper animation
    gsap.set(this.roiExplainerTarget, { display: 'block', opacity: 0 });
    const finalHeight = this.roiExplainerTarget.offsetHeight;

    // Initialize GSAP timeline but pause it
    this.timeline = gsap.timeline({ paused: true });

    // Set initial state
    gsap.set(this.roiExplainerTarget, {
      height: 0,
      opacity: 0,
      position: 'absolute',
      bottom: '0',
      left: '0',
      clipPath: 'inset(100% 0 0 0)' // Start fully clipped from bottom
    });

    // Create a proxy object for the background animation
    this.backgroundProxy = { opacity: 0.03 };
    const element = this.element;

    // Create the animation sequence
    this.timeline
      .to(this.displayNameTarget, {
        duration: 0.3,
        scale: 0.8,
        transformOrigin: 'left bottom',
        y: -20,
        ease: 'power2.out'
      })
      .to(this.roiExplainerTarget, {
        duration: 0.3,
        height: finalHeight,
        opacity: 1,
        clipPath: 'inset(0% 0 0 0)', // Reveal from bottom up
        ease: 'power2.out'
      }, '<')
      .to(this.backgroundProxy, {
        opacity: 0.12,
        duration: 0.3,
        ease: 'power2.out',
        onUpdate: function() {
          element.style.background = `
            radial-gradient(178.03% 131.33% at 100% 0%, 
              rgba(16, 25, 26, 0.00) 0%, 
              rgba(16, 25, 26, 0.80) 87.47%
            ),
            radial-gradient(
              64.2% 72.81% at 50% 0%, 
              rgba(219, 242, 255, ${this.backgroundProxy.opacity}) 0%, 
              rgba(250, 224, 236, ${this.backgroundProxy.opacity}) 100%
            ),
            #10191A
          `;
        }.bind(this)
      }, '<');
  }

  mouseEnter() {
    this.timeline.play();
  }

  mouseLeave() {
    this.timeline.reverse();
  }
}
