import { Controller } from "stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-stream-render", this.handleTurboStream.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.handleTurboStream.bind(this));
  }

  handleTurboStream(event) {
    const action = event.target.getAttribute("action");
    const elementId = event.target.getAttribute("target");

    if (action !== "replace" || elementId !== 'distribution-calendar-upcoming-unlocks') {
      return;
    }

    // Prevent Turbo from immediately removing the element, this needs to be below the above if statement to not block other actions
    event.preventDefault();

    const element = document.getElementById(elementId);
    if (!element) {
      return console.error(`Element with id ${elementId} not found in upcoming_unlocks_controller.js`);
    }

    const newContent = event.target.querySelector("template").content.firstElementChild;

    const currentContainer = element.querySelector('[data-testid="upcoming-unlocks"]');
    const newContainer = newContent.querySelector('[data-testid="upcoming-unlocks"]');

    // Only animate if content is different from the current content
    if (currentContainer.innerHTML.trim() !== newContainer.innerHTML.trim()) {
      // Fade out the current children
      gsap.to(currentContainer.children, {
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          currentContainer.innerHTML = newContainer.innerHTML;

          gsap.fromTo(currentContainer.children, { opacity: 0 }, { opacity: 1, duration: 0.1 });
        }
      });
    } else {
      element.replaceWith(newContent);
    }
  }
}
