import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['themeToggle'];

  toggleTheme() {
    const theme = localStorage.getItem('theme')
    // There is probably a better way of doing this but applying a transition-none to the HTML element
    // does not seem to work. This is a workaround to prevent the transition from firing on theme change
    const buttons = document.querySelectorAll('button');
    const a = document.querySelectorAll('a');

    // Stop buttons from transitioning on theme change
    buttons.forEach(button => {
      button.style.transition = 'none';
    });

    a.forEach(link => {
      link.style.transition = 'none';
    })

    if (theme) {
      if (theme === 'light') {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      }
      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark');
      }
    }

    // Short timeout to re-enable transitions
    setTimeout(() => {
      buttons.forEach(button => {
        button.style.transition = ''; // Reset to stylesheet values
      });
      a.forEach(link => {
        link.style.transition = '';
      })
    }, 100);
  }
}
