import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['table', 'controlCheckboxes'];
  static values = { tableGuid: String, withMultiselect: Number };
  hiddenColumns: boolean[] = [];

  connect() {
    this.tableTarget.classList.remove("hidden");
    for (let i =0; i < this.controlCheckboxesTargets.length; i++) {
      if(this.controlCheckboxesTargets[i].checked === false) {
        this.setColumnHidden(i, true);
      }
    }
  }

  setColumnHidden(columnIndex: Number, hide: boolean) {
    const columnToApplyStyle = columnIndex + 1 + this.withMultiselectValue;
    if (hide) {
      const style = document.createElement('style');
      style.textContent = `
            table[id^="table-${this.tableGuidValue}"] th:nth-child(${columnToApplyStyle}),
            table[id^="table-${this.tableGuidValue}"] td:nth-child(${columnToApplyStyle}) 
            {
              display: none;
            }
          `;
      document.head.appendChild(style);
    } else {
      const style = document.createElement('style');
      style.textContent = `
            table[id^="table-${this.tableGuidValue}"] th:nth-child(${columnToApplyStyle}),
            table[id^="table-${this.tableGuidValue}"] td:nth-child(${columnToApplyStyle}) 
            {
              display: table-cell;
            }
          `;
      document.head.appendChild(style);
    }
    this.hiddenColumns[columnIndex] = hide;
  }

  toggleColumns(event: Event) {
    const selectedColumn = parseInt(event.target.getAttribute('data-param'));
    const table = this.tableTarget;
    table.querySelectorAll('th').forEach((th: HTMLElement) => {
      const columnId = parseInt(th.dataset.columnIndex);
      const isHidden = this.hiddenColumns[columnId];
      if (columnId == selectedColumn) {
        this.setColumnHidden(columnId, !isHidden);
      }
    });
  }
}
