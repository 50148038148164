import { Controller } from 'stimulus';
import TextFieldController from '../../../lib/nakamoto/components/forms/text_field_controller';
import debounce from 'debounce';

export default class InvestorFormsController extends Controller {
  static targets = ['amountInput', 'txHashInput'];

  declare readonly amountInputTarget: HTMLInputElement;
  declare readonly txHashInputTarget: HTMLInputElement;

  static values = { fetchTxUrl: String };
  declare readonly fetchTxUrlValue: string;

  initialize() {
    this.handleTxHashInput = debounce(this.handleTxHashInput.bind(this), 300);
  }

  connect() {
    super.connect();
  }

  isValidTxHash(txHash: string) {
    return /^0x([A-Fa-f0-9]{64})$/.test(txHash);
  }

  handleTxHashInput(event: Event): void {
    event.preventDefault();
    const txHash = this.txHashInputTarget.value;

    // As long as the user has inputted a tx hash, the amount input should be set to readonly
    // and the value should be cleared and finally set by the result of the fetched result
    if (txHash.length > 0) {
      this.amountInputTarget.readOnly = true;
      this.amountInputTarget.value = '';
    } else {
      this.amountInputTarget.readOnly = false;
    }
    if (this.isValidTxHash(txHash)) {
      fetch(`${this.fetchTxUrlValue}?tx_hash=${txHash}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.amountInputTarget.value = data.amount;

            // Call "keyup->forms--text-field#handleValidateInput" action from input target
            // const textFieldController =
            //   this.application.getControllerForElementAndIdentifier(
            //     this.amountInputTarget.parentElement as HTMLElement,
            //     'forms--text-field'
            //   ) as TextFieldController;
            // textFieldController?.handleFocusInput();
            // textFieldController?.handleValidateInput();
          } else {
            console.error(data.errors);
          }
        });
    }
  }
}
