import { Controller } from 'stimulus';

export default class TextWithLabelComponentController extends Controller {
  static targets = ['spinner', 'value'];

  declare readonly spinnerTarget: HTMLElement;
  declare readonly valueTarget: HTMLElement;

  startLoading() {
    this.valueTarget.classList.add('hidden');
    this.spinnerTarget.classList.remove('hidden');

    this.valueTarget.textContent = '-';
  }

  stopLoading(newValue: string|number) {
    this.spinnerTarget.classList.add('hidden');
    this.valueTarget.classList.remove('hidden');

    if (newValue) this.valueTarget.textContent = newValue as string;
  }
}
