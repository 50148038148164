import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/*
 * This function awaits for a promise to resolve within a certain timeout, or returns a fixed value.
 * If the promise resolves before the timeout, the resolved value is returned.
 * If the promise rejects before the timeout, the rejected value is returned.
 * If the promise does not resolve or reject before the timeout, the timeoutValue is returned.
 */
export async function resolvePromiseWithTimeout<T>(
  promise: Promise<T>,
  timeoutSeconds: number,
  timeoutValue: any = null
): Promise<T | unknown> {
  const timeoutPromise = new Promise((resolve) => {
    setTimeout(resolve, timeoutSeconds * 1000, timeoutValue);
  });
  return await Promise.race([timeoutPromise, promise]);
}
