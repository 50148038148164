import { ICompassConfirmRouter } from './presenter';
import { IPaymentIntent } from '../../model/payment_intent';
import { Application } from 'stimulus';
import ActionsController from '../../../actions_controller';

type CompassConfirmationRouterParams = {
  application: Application;
  compassBaseUrl: string;
  successUrl?: string;
};

export default class CompassConfirmationRouter
  implements ICompassConfirmRouter
{
  application: Application;
  // TODO: get the URLs injected by configuration
  compassBaseUrl: string;
  successUrl: string | undefined;

  constructor({
    application,
    compassBaseUrl,
    successUrl,
  }: CompassConfirmationRouterParams) {
    this.application = application;
    this.compassBaseUrl = compassBaseUrl;
    this.successUrl = successUrl;
  }

  goBackToCompassForm(paymentIntent: IPaymentIntent): void {
    const url = new URL(`${this.compassBaseUrl}/new`);
    url.searchParams.append('payable_guid', paymentIntent.payable.guid!);
    url.searchParams.append(
      'payable_model_name',
      paymentIntent.payable.modelName
    );
    const modalContent = document.getElementById(
      'modal_content'
    ) as HTMLIFrameElement;
    if (modalContent) modalContent.src = url.toString();
  }

  dismissModal() {
    const actionsController = this.actionsController;
    if (actionsController) {
      actionsController.closeModal();
    }
  }

  goToSuccess(): void {
    if (this.successUrl && this.successUrl !== '') {
      window.location.href = this.successUrl;
    } else {
      window.location.reload();
    }
  }

  private get actionsController(): ActionsController | undefined {
    const actionsControllerElement = document.querySelector(
      '[data-controller~=actions]'
    );
    if (actionsControllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        actionsControllerElement,
        'actions'
      ) as ActionsController;
    }
  }
}
