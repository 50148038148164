import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.actionsController.openModal(null, { showWelcomeModal: true, lockModal: true, hideCloseButton: true })
  }

  // Was having timing issues with outlets similar to this post https://github.com/hotwired/stimulus/issues/618
  // The actions outlet seemed to be empty when I tried to access it in the connect method so going to use this method
  get actionsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~=actions]'),
      'actions'
    );
  }
}
