import { Controller } from "stimulus";
import {enter, leave, toggle} from 'el-transition'

class SidePanelMobileController extends Controller {
  static targets = ['container', 'backdrop', 'menu', 'close', 'panel']

  connect() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);

    this.containerTarget.addEventListener('click', this.boundHandleClickOutside);
  }

  disconnect() {
    this.containerTarget.removeEventListener('click', this.boundHandleClickOutside);
  }

  handleClickOutside(event) {
    if (!this.panelTarget.contains(event.target)) {
      this.closeMenu();
    }
  }

  dialogClick = (e) => {
    if (this.menuTarget.contains(e.target)) {
      return;
    }

    this.closeMenu();
  };

  openMenu() {
    this.containerTarget.style.display = ''
    enter(this.backdropTarget)
    enter(this.menuTarget)
    enter(this.closeTarget)
  }

  async closeMenu() {
    // Wait for all animations to finish before toggling hidden on container
    Promise.all([
      leave(this.backdropTarget),
      leave(this.menuTarget),
      leave(this.closeTarget)]).then(() => {
      this.containerTarget.style.display = 'none'
    })
  }
}

export default SidePanelMobileController
