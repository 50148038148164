export function goToLink(event) {
  if (otherElementLink(event)) {
    return;
  }

  openURL(event.currentTarget.dataset.href, event.ctrlKey || event.metaKey);
}

export function openURL(url, newTab = false) {
  if (newTab) {
    window.open(url, '_blank');
  } else {
    window.location = url;
  }
}

export function otherElementLink(event) {
  if (event.target.type === 'submit') {
    return true;
  } else if (event.target.href !== undefined) {
    return true;
  } else {
    return false;
  }
}
