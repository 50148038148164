// text_animation_controller.js
import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static targets = ["title", "subtitle", "subtitle2"]

  connect() {
    document.fonts.ready.then(() => {
      // Remove invisible class instead of hidden
      this.titleTarget.classList.remove('invisible')
      this.subtitleTarget.classList.remove('invisible')
      this.subtitle2Target.classList.remove('invisible')

      this.animateSequence()
    })
  }

  animateSequence() {
    const timeline = gsap.timeline()
    const highlightedWord = this.titleTarget.querySelector(".highlight-word")
    console.log(highlightedWord)

    // Main title animation
    timeline
      .from(this.titleTarget, {
        opacity: 0,
        y: 50,
        duration: 0.8,
        ease: "power2.out"
      })
      .from(highlightedWord, {
        scale: 1.2,
        color: "#ffffff",
        duration: 0.6,
        ease: "power2.inOut"
      }, "-=0.4")

    // Hide the "anyone" text initially
    // const highlightedAnyone = this.subtitleTarget.querySelector(".text-white")
    // if (highlightedAnyone) {
    //   gsap.set(highlightedAnyone, { opacity: 0 })
    // }

    // Set initial state for first subtitle
    gsap.set(this.subtitleTarget, {
      opacity: 0,
      clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)"
    })

    // Set initial state for second subtitle
    gsap.set(this.subtitle2Target, {
      opacity: 0,
      clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)"
    })

    // Animate the first subtitle with reveal effect
    timeline
      .to(this.subtitleTarget, {
        opacity: 0.8,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        duration: 1,
        ease: "power2.inOut"
      }, "-=0.3")

    // Animate the "anyone" text after the wipe completes
    // if (highlightedAnyone) {
    //   timeline
    //     .to(highlightedAnyone, {
    //       opacity: 1,
    //       duration: 0.4,
    //       ease: "power2.inOut"
    //     })
    // }

    // Animate the second subtitle with the same wipe effect
    timeline
      .to(this.subtitle2Target, {
        opacity: 0.8,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        duration: 1,
        ease: "power2.inOut"
      })
  }
}
