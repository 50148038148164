import { Controller } from 'stimulus';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default class extends Controller {
  static targets = [
    'localizeDatetime',
    'localizeTimeSelector',
    'localizeUtcSelector',
  ];

  connect() {
    const date = new Date();

    if (this.hasLocalizeUtcSelectorTarget) {
      this.localizeUtcSelectorTarget.value = this.getUtcOffsetString(date);
    }

    if (this.hasLocalizeTimeSelectorTarget) {
      this.localizeTimeSelectorTarget.value = this.getLocalISOString(date);
    }

    for (let i = 0; i < this.localizeDatetimeTargets.length; i++) {
      const localizeDatetimeTarget = this.localizeDatetimeTargets[i];
      const value = localizeDatetimeTarget.getAttribute('value');
      const date = new Date(value * 1000.0);

      // Same format as STRFTIME_DATETIME_PRETTY_FORMAT
      const day = date.getDate();
      const monthName = monthNames[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const localizedDate = `${day}. ${monthName} ${year} ${hours}:${minutes}`;
      localizeDatetimeTarget.innerHTML = localizedDate;
    }
  }

  getUtcOffsetString(date) {
    const utcOffset = date.getTimezoneOffset();
    const hours = `${Math.floor(Math.abs(utcOffset) / 60)}`;
    const minutes = `${
      Math.abs(utcOffset) - Math.floor(Math.abs(utcOffset) / 60) * 60
    }`;
    const sign = Math.sign(utcOffset) > 0 ? '-' : '+';

    return `${sign}${hours.padStart(2, '0')}${minutes.padStart(2, '0')}`;
  }

  getLocalISOString(date) {
    const offsetDate = new Date(date - date.getTimezoneOffset() * 60000);
    return offsetDate.toISOString().substring(0, 16);
  }
}
