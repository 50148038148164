import { Controller } from 'stimulus';
const  focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default class extends Controller {
  static targets = ['title', 'modalSizer'];

  connect() {
    if (this.hasTitleTarget) {
      this.setModalTitle();
      this.actionsController.showHeader();
    } else {
      this.actionsController.hideHeader();
    }
    this.resizeModal();
    document.body.classList.add('modal-open');

    // Target the first focusable element in the modal
    this.firstFocusableElement = this.actionsController.modalTarget.querySelectorAll(focusableElements)[0]
    this.firstFocusableElement.focus()
  }

  disconnect() {
    document.body.classList.remove('modal-open');
  }

  setModalTitle() {
    this.actionsController.setTitle(this.titleTarget.innerHTML);
  }

  resizeModal() {
    if (this.hasModalSizerTarget) {
      this.actionsController.makeModalLarge();
    } else {
      this.actionsController.makeModalSmall();
    }
  }

  get actionsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~=actions]'),
      'actions'
    );
  }
}
