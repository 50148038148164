import { Controller } from 'stimulus';
import { estimateTokenValues, numberWithPrecision } from './utils';
import debounce from 'debounce';

export default class extends Controller {
  static targets = [
    'amountField',
    'priceAmountField',
    'tokenAmount',
    'tokenPrice',
    'tokenPriceX',
  ];

  declare readonly amountFieldTarget: HTMLInputElement;
  declare readonly priceAmountFieldTarget: HTMLInputElement;
  declare readonly tokenAmountTarget: HTMLSpanElement;
  declare readonly tokenPriceTarget: HTMLSpanElement;
  declare readonly tokenPriceXTarget: HTMLSpanElement;
  declare readonly hasAmountFieldTarget: boolean;
  declare readonly hasPriceAmountFieldTarget: boolean;
  declare readonly hasTokenAmountTarget: boolean;
  declare readonly hasTokenPriceTarget: boolean;
  declare readonly hasTokenPriceXTarget: boolean;

  static values = {
    totalAllocationAmount: Number,
    totalTokenAmount: Number,
    coingeckoUnitPrice: Number,
    tokenTicker: String,
    contributableTicker: String,
  };

  declare readonly totalAllocationAmountValue: number;
  declare readonly totalTokenAmountValue: number;
  declare readonly coingeckoUnitPriceValue: number;
  declare readonly tokenTickerValue: string;
  declare readonly contributableTickerValue: string;
  declare readonly hasTotalAllocationAmountValue: boolean;
  declare readonly hasTotalTokenAmountValue: boolean;
  declare readonly hasCoingeckoUnitPriceValue: boolean;

  initialize() {
    this.estimate = debounce(this.estimate.bind(this), 300);
  }

  async estimate(e: any) {
    e.preventDefault();

    this.setValues();
  }

  async setValues() {
    if (!this.hasTotalAllocationAmountValue || !this.hasTotalTokenAmountValue)
      return;

    const values = {
      totalAllocationAmount: this.totalAllocationAmountValue,
      totalTokenAmount: this.totalTokenAmountValue,
      allocationAmount: parseFloat(this.amountFieldTarget.value),
      priceAmount: parseFloat(this.priceAmountFieldTarget.value),
    };
    const { tokenAmount, tokenPrice } = estimateTokenValues(values);

    if (this.hasTokenAmountTarget && tokenAmount) {
      this.tokenAmountTarget.textContent = `${numberWithPrecision(
        tokenAmount
      )} ${this.tokenTickerValue}`;
    }
    if (this.hasTokenPriceTarget && tokenPrice) {
      this.tokenPriceTarget.textContent = `${
        this.contributableTickerValue
      } ${numberWithPrecision(tokenPrice)}`;
    }
  }
}
