import { Controller } from 'stimulus';

const isMobile = window.innerWidth < 768;

export default class extends Controller {
  static targets = ['modalLink', 'tableRow'];

  goToLink(event) {
    if (this.otherLinkInsideElementWasClicked(event)) {
      return;
    }

    this.openURL(
      event.currentTarget.dataset.href,
      event.ctrlKey || event.metaKey
    );
  }

  goToModalLink(event) {
    if (this.otherLinkInsideElementWasClicked(event)) {
      return;
    }

    this.modalLinkTarget.click();
  }

  openURL(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location = url;
    }
  }

  otherLinkInsideElementWasClicked(event) {
    if (
      event.target.type === 'submit' ||
      event.target.type === 'checkbox' ||
      event.target.closest("[data-links-noclick='true']")
    ) {
      return true;
    } else if (event.target.href !== undefined) {
      return true;
    } else {
      return false;
    }
  }
}
