import { Controller } from 'stimulus';

export default class TextOrSelectInputController extends Controller<HTMLFormElement> {
  static targets = [
    'selectInput',
    'textInput',
    'selectInputWrapper',
    'textInputWrapper',
  ];

  protected declare readonly selectInputTarget: HTMLSelectElement;
  protected declare readonly textInputTarget: HTMLInputElement;
  protected declare readonly selectInputWrapperTarget: HTMLElement;
  protected declare readonly textInputWrapperTarget: HTMLElement;

  showTextInput(event: Event): void {
    event.preventDefault();
    this.selectInputTarget.disabled = true;
    this.textInputTarget.disabled = false;
    this.textInputTarget.value = '';
    this.selectInputWrapperTarget.classList.add('hidden');
    this.textInputWrapperTarget.classList.remove('hidden');
  }

  showSelectInput(event: Event): void {
    event.preventDefault();
    this.selectInputTarget.disabled = false;
    this.textInputTarget.disabled = true;
    this.selectInputWrapperTarget.classList.remove('hidden');
    this.textInputWrapperTarget.classList.add('hidden');
  }
}
