import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['autoOpenLink'];

  declare autoOpenLinkTarget: HTMLAnchorElement;
  declare hasAutoOpenLinkTarget: boolean;

  connect() {
    if (this.hasAutoOpenLinkTarget) {
      this.autoOpenLinkTarget.click();
    }
  }
}
