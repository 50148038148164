import { Payable } from './payable';

export enum ErrorCode {
  // TODO: add real codes
  BackendApiError,
  UserRejectedTransactionError,
  WalletNotConnectedError,
  FromAddressMismatchError,
  InsufficientAllowanceError,
  InsufficientBalanceError,
  InsufficientBalanceForFeesError,
  SmartContractNotWhitelistedError,
  InvalidNonceError,
  UnsupportedChainError,
  BlindSigningDisabledError,
  ContractExecutionTakingTooLongError,
  UnkownRPCNoKeyringError,
  UnkownRPCTransactionUnderpricedError,
  UnkownRPCError,
  UnknownLedgerError,
  LedgerDeviceInvalidDataReceivedError,
  MetamaskHavingTroubleConnectingError,
  NonceTooLowError,
  NoCSRFTokenError,
  CannotSetDefaultChainError,
  GenericSwitchChainError,
  DeckDistributionInvalidated,
  DeckDistributionAlreadyClaimed,
  DeckDistributionForceNonExact,
}

type CompassErrorParams = {
  code: ErrorCode;
  message?: string;
  userFriendlyMessage?: string;
  payable?: Payable;
  canRetryIn?: Date;
};

export class CompassError extends Error {
  code: ErrorCode;
  userFriendlyMessage: string;
  payable?: Payable;
  canRetryIn?: Date;

  constructor({
    code,
    message,
    userFriendlyMessage,
    payable,
    canRetryIn,
  }: CompassErrorParams) {
    super(message);
    this.code = code;
    this.userFriendlyMessage = userFriendlyMessage || 'Something went wrong.';
    this.payable = payable;
    this.canRetryIn = canRetryIn;
  }
}
