import { Controller } from 'stimulus';
import Chart, { registerables } from 'chart.js/auto';

Chart.register(...registerables);

export default class extends Controller {
  static values = {
    data: Object,
    canvasId: String,
    title: String,
    unit: String,
  };

  updateChart(event) {
    const selectedValue = event.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('time_select', selectedValue);

    // Update the URL without reloading the page
    history.pushState({}, '', url.toString());

    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const turboStreamElements = doc.querySelectorAll('turbo-stream');

        turboStreamElements.forEach(turboStreamElement => {
          document.documentElement.appendChild(turboStreamElement);
        });
      })
      .catch(error => console.error('Error fetching Turbo Stream:', error));
  }

  connect() {
    this.initializeChart();
  }

  initializeChart() {
    const ctx = document.getElementById(this.canvasIdValue).getContext('2d');
    const chartData = this.dataValue;
    const labels = Object.keys(chartData);
    const presailPrices = labels.map((label) => chartData[label].presail_price);
    const marketPrices = labels.map((label) => chartData[label].market_price);
    const discountAmounts = labels.map(
      (label) => chartData[label].discount.in_amount
    );
    const discountPercentages = labels.map(
      (label) => chartData[label].discount.in_percentage
    );

    // Determine if there are any valid Market Price data points
    const hasMarketData = marketPrices.some(
      (price) => price !== null && price !== undefined
    );

    const presailBackgroundColor = '#164e63';
    const presailBorderColor = '#01b7d4';

    let presailGradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    presailGradient.addColorStop(0, 'rgba(207, 250, 254, 1)');
    presailGradient.addColorStop(1, presailBorderColor);

    const presailGradientBackgroundColor = ctx.createLinearGradient(
      0,
      0,
      0,
      300
    );
    presailGradientBackgroundColor.addColorStop(0, 'rgba(1, 183, 212, 0.2)');
    presailGradientBackgroundColor.addColorStop(0.5, 'rgba(1, 183, 212, 0.2)');
    presailGradientBackgroundColor.addColorStop(1, 'rgba(16, 22, 34, 0.2)');

    const marketBackgroundColor = '#064e3b';
    const marketBorderColor = '#11b981';

    const datasets = [
      {
        label: 'Spring',
        backgroundColor: presailGradientBackgroundColor, // Color for the legend (and fill if applicable)
        borderColor: presailGradient, // Apply gradient to line border
        data: presailPrices,
        type: 'line',
        fill: true,
        yAxisID: 'y-axis-price',
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 5,
      },
    ];

    if (hasMarketData) {
      let marketGradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      marketGradient.addColorStop(0, '#D1FAE5'); // Define a plugin to add drop shadow to the presail price line
      marketGradient.addColorStop(1, marketBorderColor);

      const marketGradientBackgroundColor = ctx.createLinearGradient(
        0,
        0,
        0,
        250
      );
      marketGradientBackgroundColor.addColorStop(0, 'rgba(17, 185, 129, 0.5)');
      marketGradientBackgroundColor.addColorStop(0.5, 'rgba(6, 78, 59, 0.2)');
      marketGradientBackgroundColor.addColorStop(1, 'rgba(16, 22, 34, 0.2)');

      datasets.push({
        label: 'Market',
        backgroundColor: marketGradientBackgroundColor, // Color for the legend (and fill if applicable)
        borderColor: marketGradient, // Apply gradient to line border
        data: marketPrices,
        type: 'line',
        fill: true,
        yAxisID: 'y-axis-price',
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 5,
      });
    }

    const maxPresailPrice = Math.max(...presailPrices);
    const maxMarketPrice = Math.max(...marketPrices);
    let maxDataValue = Math.max(maxPresailPrice, maxMarketPrice);

    const topPaddingFactor = 1.2;
    maxDataValue *= topPaddingFactor;

    const formatUnit = this.formatUnit.bind(this); // Make the format unit function available to the chart options
    new Chart(ctx, {
      type: 'bar', // Chart container type
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.15, //Add small amount of rounding to the line
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawOnChartArea: false,
            },
            ticks: {
              align: 'inner',
              padding: 0,
              color: '#94A3B8', // Set color for Y axis labels
              callback: function (_value, index) {
                const value = labels[index]; // Cause it doesnt want to give me the value for some reason
                if (index % 4 === 0) {
                  const date = new Date(value);
                  const day = date.getDate();
                  const month = date.toLocaleString('default', {
                    month: 'short',
                  });
                  return `${day} ${month}`;
                }
                return '';
              },
              minTicksLimit: 8,
              maxTicksLimit: 8,
              maxRotation: 0,
              minRotation: 0,
            },
          },
          'y-axis-price': {
            grid: {
              color: '#1e2431',
              drawTicks: false,
            },
            border: {
              display: false,
            },
            beginAtZero: true, // Consider this to avoid starting at 0, might not hide 0 if it's within data range
            suggestedMax: maxDataValue,
            ticks: {
              padding: 10,
              count: 8,
              minTicksLimit: 8,
              maxTicksLimit: 8,
              autoSkip: true,
              color: '#94A3B8', // Set color for Y axis labels
              callback: function (value, index, values) {
                if (value === 0 || index % 2 === 0) {
                  // Check for the 0 value
                  return ''; // Don't return anything for 0, effectively skipping it
                }
                return formatUnit(value.toFixed(2));
              },
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 16, // Smaller width for the legend boxes
              boxHeight: 8, // Smaller height for the legend boxes, adjust as needed
              color: '#CBD5E1', // Set the legend's text color
            },
            position: 'top',
            align: 'end',
            reverse: 'true', // Set market to appear first in the legend
          },
          tooltip: {
            backgroundColor: 'rgba(30, 41, 59, 0.75)',
            mode: 'index',
            usePointStyle: true,
            intersect: false,
            titleFont: {
              weight: '400',
            },
            caretSize: 0,
            // Show the market price first in the tooltip
            itemSort: function (a, b) {
              return b.datasetIndex - a.datasetIndex;
            },
            titleMarginBottom: 8,
            boxHeight: 7,
            boxWidth: 7,
            boxPadding: 3,
            padding: 12,
            callbacks: {
              title: function (tooltipItems) {
                if (tooltipItems.length > 0) {
                  const date = new Date(tooltipItems[0].label);
                  const day = date.getDate();
                  const month = date.toLocaleString('default', {
                    month: 'short',
                  });
                  const year = date.getFullYear();
                  return `${day} ${month} ${year}`;
                }
                return '';
              },
              label: function (context) {
                // Determine the dataset via context.datasetIndex and apply custom formatting
                let label = '';
                switch (context.datasetIndex) {
                  case 0: // Spring Price
                  case 1: // Market Price
                    label += formatUnit(context.parsed.y);
                    break;
                }
                return label;
              },
              labelColor: function (context) {
                // Need to set background color to a solid color to avoid the gradient as this causes a white background
                let backgroundColor;
                switch (context.datasetIndex) {
                  case 0:
                    backgroundColor = presailBackgroundColor;
                    break;
                  case 1:
                    backgroundColor = marketBackgroundColor;
                    break;
                }
                return {
                  backgroundColor: backgroundColor,
                  borderColor: context.dataset.borderColor,
                };
              },
              afterLabel: function () {
                return ''; // Return a blank string for added spacing after each label
              },
              afterBody: function (tooltipItems) {
                let index = tooltipItems[0].dataIndex;
                // Check if "market price" data is set for the current index
                const marketPrice = tooltipItems.find(
                  (item) => item.dataset.label === 'Market'
                )?.parsed.y;
                if (marketPrice !== undefined && marketPrice !== null) {
                  // If "market price" is set, return discount data
                  return [
                    '',
                    `Discount: -${discountPercentages[index]}% ($${discountAmounts[index]})`,
                  ];
                }
                // If "market price" is not set, omit discount data
                return [];
              },
            },
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
      },
    });
  }

  formatUnit(value) {
    if (this.unitValue === 'dollar') {
      return `$ ${value}`;
    } else if (this.unitValue === 'multiplier') {
      return `${value}x`;
    } else {
      return value;
    }
  }
}
