import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'confirmation', 'copyElement'];

  async copyInput() {
    this.copyElementTarget.classList.remove('hidden');
    await navigator.clipboard.writeText(this.sourceTarget.value);
    this.showCopiedButton();
  }

  async copy() {
    for (let target of this.copyElementTargets) {
      target.classList.remove('hidden');
    }

    await navigator.clipboard.writeText(
      this.sourceTarget.innerHTML || this.sourceTarget.value
    );
    this.showCopiedButton();
  }

  showCopiedButton() {
    setTimeout(() => {
      this.copyElementTarget.classList.add('hidden');
    }, 1000);
  }
}
