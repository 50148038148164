import TradingTableController from './trading_table_controller';

export default class extends TradingTableController {
  getNewOrderPattern() {
    return /otc_order_table_rows_market_sell_deal_platform_\d+_offer_\d+/;
  }

  getRemoveOrderPattern() {
    return /table_market_sell_deal_row_otc_order_\d+$/;
  }

  processStream(streamElement, defaultAction) {
    const newRow = streamElement.children[0].content;
    const newRowUnitPrice = parseFloat(
      newRow.querySelector('[data-unit-price]').getAttribute('data-unit-price')
    );
    const unitPricesAndRows = this.findUnitPricesAndRows();
    const visibleRowCount = unitPricesAndRows.length;

    // When there are no existing rows just continue the normal stream
    if (visibleRowCount === 0) {
      defaultAction(streamElement);
    } else {
      // Extract the unit prices from the list
      const unitPrices = unitPricesAndRows.map((item) => item[0]);

      // Find the largest unit price
      const largestUnitPrice = Math.max(...unitPrices);

      if (newRowUnitPrice <= largestUnitPrice) {
        let rowToInsertBefore = null;
        for (const [unitPrice, row] of unitPricesAndRows.toReversed()) {
          if (newRowUnitPrice <= unitPrice) {
            break;
          } else {
            rowToInsertBefore = row;
          }
        }

        if (!rowToInsertBefore) {
          const referenceNode = unitPricesAndRows[visibleRowCount - 1][1];

          referenceNode.parentNode.insertBefore(
            newRow,
            referenceNode.nextSibling
          );
        } else {
          rowToInsertBefore.parentNode.insertBefore(newRow, rowToInsertBefore);
        }
        this.findAndSetBest();
      } else if (this.canPlaceRowAtTheEndOfTable(visibleRowCount)) {
        defaultAction(streamElement);
      }
    }
  }

  findAndSetBest() {
    const unitPricesAndRows = this.findUnitPricesAndRows();

    // Extract the unit prices from the list
    const unitPrices = unitPricesAndRows.map((item) => item[0]);

    // Find the lowest unit price
    const lowestUnitPrice = Math.min(...unitPrices);

    // Find the row with the lowest unit price
    const match = unitPricesAndRows
      .reverse()
      .find((item) => item[0] === lowestUnitPrice);

    const rowWithLowestUnitPrice = match && match[1];

    if (rowWithLowestUnitPrice) {
      // Find elements that contains span element with class "best-star" among the unitPricesAndRows list
      const bestStarElements = unitPricesAndRows.map((item) =>
        item[1].querySelector('.best-star')
      );

      // Add class "!hidden" from best-star element if it's not in the row with the largest unit price
      bestStarElements.forEach((element) => {
        if (element.closest('tr') !== rowWithLowestUnitPrice) {
          element.classList.add('!hidden');
        }
      });

      // Find elements that contains div element with class "best-space" among the unitPricesAndRows list
      const bestSpaceElements = unitPricesAndRows.map((item) =>
        item[1].querySelector('.best-space')
      );

      // Add class "!hidden" from best-space element if it's not in the row with the largest unit price
      bestSpaceElements.forEach((element) => {
        if (element.closest('tr') !== rowWithLowestUnitPrice) {
          element.classList.add('!hidden');
        }
      });

      // For the row with the largest unit price, remove class "!hidden" from best-star element and add class "!hidden" to best-space element
      rowWithLowestUnitPrice
        .querySelector('.best-star')
        .classList.remove('!hidden');
      rowWithLowestUnitPrice
        .querySelector('.best-space')
        .classList.add('!hidden');
    }
  }
}
