import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'hiddenSubmitButton',
    'sortByInput',
    'sortDirectionInput',
    'filterInput',
    'filterBadge',
    'filterCount',
    'searchTermInput',
    'row',
    'perPageInput',
    'table',
  ];
  static values = { userId: Number, maxRowCount: Number };

  update({ sortBy, sortDirection, searchTerm, filterUpdate }) {
    this.showLoader();
    this.updateContentParams(sortBy, sortDirection, searchTerm, filterUpdate);
    this.submitForm();
  }

  showLoader() {
    this.rowTargets.forEach((row) => {
      row.classList.add('opacity-50');
    });
  }

  loadAllRecords() {
    this.perPageInputTarget.value = this.maxRowCountValue;
    this.submitForm();
  }

  submitForm() {
    if (this.formTarget.requestSubmit) {
      this.formTarget.requestSubmit();
    } else {
      this.hiddenSubmitButtonTarget.click();
    }
  }

  allRecordsAreLoaded() {
    return this.rowTargets.length === this.maxRowCountValue;
  }

  async ensureAllRecordsAreLoaded() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.allRecordsAreLoaded()) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  }

  updateContentParams(sortBy, sortDirection, searchTerm, filterUpdate) {
    if (sortBy !== undefined) {
      this.sortByInputTarget.value = sortBy;
    }

    if (sortDirection !== undefined) {
      this.sortDirectionInputTarget.value = sortDirection;
    }

    if (searchTerm !== undefined) {
      this.searchTermInputTarget.value = searchTerm;
    }

    if (filterUpdate !== undefined) {
      const filterInput = this.filterInputTargets.find(
        (input) => input.name === filterUpdate.name
      );
      filterInput.value = filterUpdate.value;
    }
  }
}
