import { Controller } from 'stimulus';
import {animate, getHiddenHeight} from "@/helpers/animations_helpers";

/**
 * @property {boolean} closeTabsOnToggleValue
 * @property {boolean} animationsValue
 * @property {HTMLElement[]} contentTargets
 * @property {HTMLElement[]} itemTargets
 */

class AccordionController extends Controller {
  static targets = ['item', 'trigger', 'content'];
  static values = {
    closeTabsOnToggle: { type: Boolean, default: true },
    animations: { type: Boolean, default: false }
  }

  connect() {
    // Calculate the height of each content target and set it as a css variable
    this.contentTargets.forEach((content) => {
      content.style.setProperty('--accordion-content-height',
        content.dataset.state === 'open' ? `${content.offsetHeight}px` : `${getHiddenHeight(content)}px`
      );
    });
  }

  /**
   * Toggles the accordion item open or closed
   * We close all other items other than the one that was clicked
   * An accordion is made up of a trigger and content, so we need to find the trigger and content
   * of the item that was clicked. We then set the appropriate data state attributes of each element and
   * also set aria and hidden state attributes of the content element
   * @param target {HTMLElement} - The accordion item that was clicked
   */
  async toggle({ currentTarget: target}) {
    const triggerParent = target.closest('[data-accordion-target="item"]');
    const targetTrigger = triggerParent.querySelector('[data-accordion-target="trigger"]');
    const targetContent = triggerParent.querySelector('[data-accordion-target="content"]');

    // Close all other items other than the one that was clicked
    if(this.closeTabsOnToggleValue) {
      this.itemTargets.forEach((item) => {
        if(item === target) return;
        if (item.dataset.state === 'open') {
          item.dataset.state = 'closed';
        }
      });
      this.triggerTargets.forEach((trigger) => {
        if(trigger === targetTrigger) return;
        if (trigger.dataset.state === 'open') {
          trigger.dataset.state = 'closed';
        }
      })
      this.contentTargets.forEach((content) => {
        if(content === targetContent) return;
        if (content.dataset.state === 'open') {
          content.dataset.state = 'closed';
          animate(content, 'leave', 'accordion', 'height')
        }
      })
    }

    triggerParent.dataset.state === 'closed' ? triggerParent.dataset.state = 'open' : triggerParent.dataset.state = 'closed';
    targetTrigger.dataset.state === 'closed' ? targetTrigger.dataset.state = 'open' : targetTrigger.dataset.state = 'closed';
    if (targetContent.dataset.state === 'closed') {
      targetContent.dataset.state = 'open'
      this.animationsValue ? await animate(targetContent, 'enter', 'accordion', 'height')
        : targetContent.classList.remove('hidden')
    } else {
      targetContent.dataset.state = 'closed';
      this.animationsValue ? await animate(targetContent, 'leave', 'accordion', 'height')
        : targetContent.classList.add('hidden')
    }
  }
}

export default AccordionController;
