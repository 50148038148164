export const abi = [
    {
      inputs: [
        {
          internalType: 'address payable[]',
          name: 'recipients',
          type: 'address[]',
        },
        { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
      ],
      name: 'presailDistribute',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'contract IERC20', name: 'token', type: 'address' },
        { internalType: 'address[]', name: 'recipients', type: 'address[]' },
        { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
      ],
      name: 'presailDistributeToken',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'contract IERC20', name: 'token', type: 'address' },
        { internalType: 'address[]', name: 'recipients', type: 'address[]' },
        { internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
      ],
      name: 'presailDistributeTokenSimple',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];
  