import { Controller } from "stimulus";

/**
 * This controller is used to toggle the side panel from outside of the parent div
 * I.e. if the button is in the header and the side panel is in the main content
 * We do this with Stimulus Outlets https://stimulus.hotwired.dev/reference/outlets
 */
class SidePanelMobileToggleController extends Controller {
  static outlets = ['side-panel-mobile']

  openMenu() {
    this.sidePanelMobileOutlet.openMenu()
  }

  closeMenu() {
    this.sidePanelMobileOutlet.closeMenu()
  }
}

export default SidePanelMobileToggleController
