import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { flash } from '../lib/flashes';

export default class extends Controller {
  static values = { updateUrl: String };
  declare updateUrlValue: string;

  static targets = ['list'];

  declare readonly listTarget: HTMLElement;

  connect() {
    new Sortable(this.listTarget, {
      // TODO: Glen, make this beautiful :)
      animation: 150,
      easing: 'cubic-bezier(1, 0, 0, 1)',
      onEnd: (event: any) => {
        const item = event.item;
        const positionData = Array.from(this.listTarget.children).map(
          (el: HTMLElement, index: number) => ({
            id: el.dataset.id,
            position: index,
          })
        );
        this.#updatePositions(positionData);
      },
    });
  }

  #updatePositions = (positionData: any) => {
    fetch(this.updateUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.#getCSRFToken(),
      },
      body: JSON.stringify({ position_data: positionData }),
    }).then((response) => {
      if (response.ok) {
        flash('Positions updated successfully', 1000);
      } else {
        flash('Error. Please retry');
      }
    });
  };

  #getCSRFToken(): string {
    const csrfTokenElement = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;
    return csrfTokenElement.content;
  }
}
