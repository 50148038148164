'use strict';

import Web3Controller from './web3_controller';
import { Web3Account } from '../web3/magnet/types';

export default class extends Web3Controller {
  onAccountConnected(account: Web3Account) {
    const isConnectModal = document.getElementById('connect-modal');
    if (isConnectModal) {
      this.actionsController?.closeModal();
    }
    super.onAccountConnected(account);
  }

  get actionsController(): any {
    const actionsControllerElement = document.querySelector(
      '[data-controller~=actions]'
    );
    if (actionsControllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        actionsControllerElement,
        'actions'
      );
    }
  }
}
