import { Controller } from "stimulus";
import {useTransition} from "stimulus-use";
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ["container", "indicator"];
  static debounces = ["updateIndicator"];

  connect() {
    if(!this.hasContainerTarget && !this.hasIndicatorTarget)
      return console.error('Missing targets: container, indicator, scroll indicator controller')
    useTransition(this, {
      element: this.indicatorTarget,
      enterActive: "transition duration-300 ease-in-out",
      enterFrom: "transform opacity-0 scale-50",
      enterTo: "transform opacity-100 scale-100",
      leaveActive: "transition duration-300 ease-in-out",
      leaveFrom: "transform opacity-100 scale-100",
      leaveTo: "transform opacity-0 scale-50",
    });
    this.debouncedUpdateIndicator = debounce(() => this.updateIndicator(), 200);
    this.containerTarget.addEventListener('scroll', this.debouncedUpdateIndicator);
    this.updateIndicator(); // Initial call to set the correct state
  }

  disconnect() {
    this.containerTarget.removeEventListener('scroll', this.debouncedUpdateIndicator);
  }


  updateIndicator() {
    const container = this.containerTarget;
    const isScrollable = container.scrollWidth > container.clientWidth;
    const isScrolledToRight = container.scrollLeft >= (container.scrollWidth - container.clientWidth);

    if (!isScrollable || isScrolledToRight) {
      this.hideIndicator();
    } else {
      this.showIndicator();
    }
  }

  showIndicator() {
    this.enter();
  }

  hideIndicator() {
    this.leave();
  }

  scrollRight() {
    this.containerTarget.scrollBy({left: 100, behavior: 'smooth'});
  }
}
