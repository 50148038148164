import Web3Controller from '../../javascript/controllers/web3_controller';
import {
  CompassFormPresenter,
  SecondaryButtonAction,
} from '../../javascript/controllers/compass/payments/form/presenter';
import CompassFormRouter from '../../javascript/controllers/compass/payments/form/router';
import { Payable } from '../../javascript/controllers/compass/model/payable';
import { ICompassFormViewInput } from '../../javascript/controllers/compass/payments/form/view_input';
import { ICompassFormViewOutput } from '../../javascript/controllers/compass/payments/form/view_output';
import { ICompassStatusViewInput } from '../../javascript/controllers/compass/payments/status/view_input';
import { ICompassStatusViewOutput } from '../../javascript/controllers/compass/payments/status/view_output';
import { Token } from '../../javascript/controllers/compass/model/token';
import { Web3Account } from '../../javascript/web3/magnet/types';
import { PresailMagnetWrapper } from '../../javascript/web3/presail_magnet_wrapper';

export default class FormController
  extends Web3Controller
  implements ICompassFormViewInput
{
  static targets = [
    'chainAndTokenSelector',
    'unsupportedChainIndicator',
    'selectedChainLogo',
    'selectedTokenName',
    'selectedTokenBalance',
    'selectTokenButton',
    'selectTokenButtonBalance',

    'form',
    'actionButton',
    'backButton',
    'cancelButton',
    'taxedTokenView',
  ];

  declare readonly chainAndTokenSelectorTarget: HTMLElement;
  declare readonly unsupportedChainIndicatorTarget: HTMLElement;
  declare readonly selectedChainLogoTargets: HTMLElement[];
  declare readonly selectedTokenNameTargets: HTMLElement[];
  declare readonly selectedTokenBalanceTarget: HTMLSpanElement;
  declare readonly selectTokenButtonTargets: HTMLElement[];
  declare readonly selectTokenButtonBalanceTargets: HTMLElement[];

  declare readonly formTarget: HTMLFormElement;
  declare readonly actionButtonTarget: HTMLButtonElement;
  declare readonly backButtonTarget: HTMLButtonElement;
  declare readonly cancelButtonTarget: HTMLButtonElement;
  declare readonly taxedTokenViewTarget: HTMLDivElement;

  static values = {
    payable: Object,
    compassBaseUrl: String,
    backUrl: String,
  };

  declare readonly payableValue: Payable;
  declare readonly compassBaseUrlValue: string;
  declare readonly backUrlValue: string;

  static outlets = ['compass--status'];
  protected declare readonly compassStatusOutlet: ICompassStatusViewInput;

  compassPresenter?: ICompassFormViewOutput & ICompassStatusViewOutput;

  async connect() {
    /*
     Need to wait for the next tick to have compassStatusOutlet instantiated.
     See https://stimulus.hotwired.dev/reference/lifecycle-callbacks#order-and-timing and
     https://github.com/hotwired/stimulus/issues/201
     */
    Promise.resolve().then(async () => {
      this.compassPresenter ||= new CompassFormPresenter({
        compassBaseUrl: this.compassBaseUrlValue,
        payable: this.payableValue,
        view: this,
        statusView: this.compassStatusOutlet,
        secondaryButtonAction:
          this.backUrlValue !== undefined && this.backUrlValue !== ''
            ? SecondaryButtonAction.Back
            : SecondaryButtonAction.Close,
        router: new CompassFormRouter({
          application: this.application,
          compassBaseUrl: this.compassBaseUrlValue,
          backUrl: this.backUrlValue,
        }),
      });
      await super.connect();
    });
  }

  compassStatusOutletConnected(
    outlet: ICompassStatusViewInput,
    element: HTMLElement
  ) {
    this.compassPresenter?.onCompassStatusViewInputUpdated(outlet);
  }

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    await this.compassPresenter?.onWeb3Connect(
      new PresailMagnetWrapper(this.magnet!)
    );
  }

  onAccountConnected(account: Web3Account) {
    this.compassPresenter?.onWeb3ChainChange({
      id: this.magnet!.web3Account!.chain.id,
      name: this.magnet!.web3Account!.chain.name,
    });
    super.onAccountConnected(account);
  }

  onAccountDisconnected(account: Web3Account) {
    this.disableActionButton();
    super.onAccountDisconnected(account);
  }

  onSelectedTokenChange(event: Event): void {
    const selectedTokenInput = event.currentTarget as HTMLElement;

    this.compassPresenter?.onSelectedTokenChange(
      selectedTokenInput.dataset.tokenId
    );
  }

  onActionButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onActionButtonClick();
  }

  onTaxedTokenActionButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onTaxedTokenActionButtonClick();
  }

  onBackButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onBackButtonClick();
  }

  /*
   * IViewInput implementation
   */
  setActionButtonText(text: string): void {
    this.actionButtonTarget.innerHTML = text;
  }

  disableActionButton(): void {
    this.actionButtonTarget.disabled = true;
  }

  enableForm(): void {
    this.formTarget
      .querySelectorAll(
        'input, button, [data-action="click->dialog--dialog#open"]'
      )
      .forEach((input: HTMLElement) => {
        if (
          input === this.backButtonTarget ||
          input === this.cancelButtonTarget
        )
          return;
        input.removeAttribute('disabled');
        input.removeAttribute('aria-disabled');
        input.removeAttribute('readOnly');
      });
  }

  disableForm(): void {
    this.formTarget
      .querySelectorAll(
        'input, button, [data-action="click->dialog--dialog#open"]'
      )
      .forEach((input: HTMLElement) => {
        if (
          input === this.backButtonTarget ||
          input === this.cancelButtonTarget
        )
          return;
        input.setAttribute('disabled', true);
        input.setAttribute('aria-disabled', true);
        input.setAttribute('readOnly', true);
      });
  }

  showSelectedToken(token: Token): void {
    this.unsupportedChainIndicatorTarget.classList.add('hidden');
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      if (
        selectTokenButton.dataset.tokenId.toString() === token.id.toString()
      ) {
        this.selectedTokenNameTargets.forEach(
          (selectedTokenNameTarget) =>
            (selectedTokenNameTarget.textContent = token.ticker)
        );
        this.selectedTokenBalanceTarget.textContent = `Balance: ${token.displayBalance!}`;
        selectTokenButton.classList.add('selected');
      } else {
        selectTokenButton.classList.remove('selected');
      }
    });
    this.selectedChainLogoTargets.forEach((selectedChainLogo) => {
      if (
        selectedChainLogo.dataset.chainId.toString() ===
        token.chain.id.toString()
      ) {
        selectedChainLogo.classList.remove('hidden');
      } else {
        selectedChainLogo.classList.add('hidden');
      }
    });
  }

  showTokenBalances(tokens: Array<Token>): void {
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      const tokenBalance = tokens.find(
        (token) =>
          token.id.toString() === selectTokenButton.dataset.tokenId.toString()
      );
      if (tokenBalance) {
        selectTokenButton.setAttribute('data-balance', tokenBalance.balance);
      }
    });

    this.selectTokenButtonBalanceTargets.forEach(
      (selectTokenButtonBalanceTarget) => {
        const tokenBalance = tokens.find(
          (token) =>
            token.id.toString() ===
            selectTokenButtonBalanceTarget.dataset.tokenId.toString()
        );
        if (tokenBalance) {
          selectTokenButtonBalanceTarget.textContent =
            tokenBalance.displayBalance;
        }
      }
    );
  }

  showTokenSelector(): void {
    this.chainAndTokenSelectorTarget.classList.remove('hidden');
  }

  hideTokenSelector(): void {
    this.chainAndTokenSelectorTarget.classList.add('hidden');
  }

  showConnectedToUnsupportedChain(): void {
    this.selectedChainLogoTargets.forEach((selectedChainLogo) => {
      selectedChainLogo.classList.add('hidden');
    });
    this.unsupportedChainIndicatorTarget.classList.remove('hidden');
    this.selectedTokenBalanceTarget.textContent =
      'Change to a different network';
    this.selectedTokenNameTarget.textContent = 'Unsupported';
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      selectTokenButton.classList.remove('selected');
    });
  }

  showBackButton(): void {
    this.backButtonTarget.classList.remove('hidden');
  }

  hideBackButton(): void {
    this.backButtonTarget.classList.add('hidden');
  }

  showCancelButton(): void {
    this.cancelButtonTarget.classList.remove('hidden');
  }

  hideCancelButton(): void {
    this.cancelButtonTarget.classList.add('hidden');
  }

  showTaxedTokenView(): void {
    this.taxedTokenViewTarget.classList.remove('hidden');
  }

  hideTaxedTokenView(): void {
    this.taxedTokenViewTarget.classList.add('hidden');
  }
}
