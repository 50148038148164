import { Controller } from 'stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  static targets = ['message', 'progressBar']

  connect() {
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;

    if (target === 'flash_trade_messages' && action === 'append') {
      event.preventDefault();
      this.handleTurboAppend(event.detail.newStream);
    }
  }

  handleTurboAppend(newStream) {
    const templateContent = newStream.querySelector('template').content;
    const messageElement = Array.from(templateContent.childNodes).find(
      node => node.nodeType === Node.ELEMENT_NODE && node.classList.contains('pointer-events-auto')
    );

    if (!messageElement) {
      console.error('Could not find message element in template');
      return;
    }

    const messageClone = messageElement.cloneNode(true);

    // Add data attributes for Stimulus
    messageClone.setAttribute('data-flash-trade-target', 'message');
    messageClone.querySelector('button').setAttribute('data-action', 'click->flash-trade#close');

    // Add hover listeners
    messageClone.setAttribute('data-action', 'mouseenter->flash-trade#pauseTimer mouseleave->flash-trade#resumeTimer');

    // Initially set the starting position
    gsap.set(messageClone, {
      opacity: 0,
      y: 20,
      x: window.innerWidth >= 640 ? 20 : 0
    });

    // Append the message to the DOM
    this.element.querySelector('#flash_trade_messages').appendChild(messageClone);

    // Store references for the timer
    const progressBar = messageClone.querySelector('[data-flash-trade-target="progressBar"]');
    messageClone.dismissTimer = {
      startTime: Date.now(),
      duration: 4000,
      remaining: 4000,
      paused: false,
      progressBar: progressBar
    };

    // Animate in
    gsap.to(messageClone, {
      opacity: 1,
      y: 0,
      x: 0,
      duration: 0.3,
      ease: 'power2.out',
      onComplete: () => {
        this.startTimer(messageClone);
      }
    });

    // Start progress bar animation
    gsap.to(progressBar, {
      width: '0%',
      duration: 4,
      ease: 'none'
    });
  }

  startTimer(messageElement) {
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.intervalId = setInterval(() => {
      if (!timer.paused) {
        const elapsed = Date.now() - timer.startTime;
        timer.remaining = Math.max(0, timer.duration - elapsed);

        if (timer.remaining <= 0) {
          this.removeMessage(messageElement);
        }
      }
    }, 100);
  }

  pauseTimer(event) {
    const messageElement = event.currentTarget;
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.paused = true;
    const elapsed = Date.now() - timer.startTime;
    timer.remaining = Math.max(0, timer.duration - elapsed);

    // Pause progress bar animation
    gsap.getTweensOf(timer.progressBar)[0]?.pause();
  }

  resumeTimer(event) {
    const messageElement = event.currentTarget;
    const timer = messageElement.dismissTimer;
    if (!timer) return;

    timer.paused = false;
    timer.startTime = Date.now() - (timer.duration - timer.remaining);

    // Resume progress bar animation
    const progressTween = gsap.getTweensOf(timer.progressBar)[0];
    if (progressTween) {
      progressTween.duration(timer.remaining / 1000);
      progressTween.play();
    }
  }

  close(event) {
    const messageElement = event.currentTarget.closest('[data-flash-trade-target="message"]');
    this.removeMessage(messageElement);
  }

  removeMessage(messageElement) {
    // Clear the interval if it exists
    if (messageElement.dismissTimer?.intervalId) {
      clearInterval(messageElement.dismissTimer.intervalId);
    }

    gsap.to(messageElement, {
      opacity: 0,
      duration: 0.3,
      ease: 'power2.in',
      onComplete: () => {
        messageElement.remove();
      }
    });
  }
}
