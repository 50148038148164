import { Controller } from 'stimulus';
import DromoUploader from 'dromo-uploader-js';
import { flash } from '../lib/flashes';

export default class extends Controller {
  dromo;

  static values = {
    apiKey: String,
    currentUserId: String,
    developmentMode: Boolean,
    callbackUrl: String,
  };

  connect() {
    this.setupDromo();
  }

  setupDromo() {
    const fields = [
      {
        label: 'Investor wallet',
        key: 'address_identifier',
        validators: [
          {
            validate: 'required',
          },
        ],
        alternateMatches: [
          'Investor wallet',
          'Investor address',
          'Wallet address',
          'Sent from',
          'From',
        ],
      },
      {
        label: 'Pool',
        key: 'pool_name',
        alternateMatches: ['Pool name', 'Tier', 'Tier name'],
      },
      {
        label: 'Deductive fee %',
        key: 'deductive_fee',
        alternateMatches: [
          'Deductive fees',
          'Fee (deductive)',
          'Fees (deductive)',
        ],
      },
      {
        label: 'Additive fee %',
        key: 'additive_fee',
        alternateMatches: [
          'Additive fees',
          'Fee (additive)',
          'Fees (additive)',
        ],
      },
      {
        label: 'Fee amount',
        key: 'fee_amount',
        alternateMatches: ['Fee amount', 'Fees'],
      },
      {
        label: 'Invested amount (before fee)',
        key: 'before_fee_amount',
        validators: [
          {
            validate: 'require_with',
            fields: ['total_tokens'],
          },
          {
            validate: 'require_without',
            fields: ['after_fee_amount'],
          },
        ],
        alternateMatches: [
          'Amount',
          'Amount before fee',
          'Before fee amount',
          'Allocation',
          'Gross amount',
        ],
      },
      {
        label: 'Invested amount (after fee)',
        key: 'after_fee_amount',
        validators: [
          {
            validate: 'require_without',
            fields: ['before_fee_amount'],
          },
        ],
        alternateMatches: [
          'Amount after fee',
          'After fee amount',
          'Net amount',
        ],
      },
      {
        label: 'Total tokens',
        key: 'total_tokens',
        alternateMatches: ['Tokens received', 'Total tokens received'],
      },
      {
        label: 'TX hash',
        key: 'tx_identifier',
        alternateMatches: [
          'Transaction hash',
          'Transaction',
          'TX',
          'TX identifier',
          'Transaction identifier',
        ],
      },
      {
        label: 'Email',
        key: 'email',
        type: 'email',
      },
      {
        label: 'Telegram',
        key: 'telegram',
      },
      {
        label: 'Discord',
        key: 'discord',
      },
    ];

    const settings = {
      importIdentifier: 'contributions',
      developmentMode: this.developmentModeValue,
      uploadStep: {
        helpText:
          'The sheet <strong>must</strong> include the investors wallet addresses and, <strong>at least' +
          '</strong>, one of the following:<br>' +
          '<ul>' +
          '<li>TX hashes</li>' +
          '<li>Invested amount (before fee) <strong>OR</strong> invested amount (after fee)</li>' +
          '</ul>' +
          'Also, please note the following:' +
          '<ul>' +
          '<li>If a pool name is specified and it already exists, its fees will be preserved and any fees ' +
          'specified in the file will be ignored.</li>' +
          '<li>Otherwise, and if no fees are specified a deductive fee of 0% is assumed.</li>' +
          '<li>If Invested amount (before fee) and Total tokens are specified, the offer <strong>must have the token price set</strong>.</li>' +
          '</ul>' +
          "Don't worry about the column header names in the file; you will have the opportunity to map them to the specific " +
          'values in the next steps.',
      },
      matchingStep: {
        helpText:
          'Please make especially sure you select the correct mapping for the following values:<br/>' +
          '<ul>' +
          '<li>The invested amounts, either before or after fee (or both)</li>' +
          '<li>The fees, either deductive or additive</li>' +
          '</ul>',
      },
      reviewStep: {
        helpText:
          'Please double check the following preview to ensure that all values are mapped out as expected.',
      },
    };

    const user = {
      id: this.currentUserIdValue,
    };

    this.dromo = new DromoUploader(this.apiKeyValue, fields, settings, user);

    this.dromo.onResults((response) => {
      this.createCapTableImport(response);
    });
  }

  openDromo(event) {
    event.preventDefault();
    this.dromo.open();
  }

  async createCapTableImport(data) {
    // try {
    let response = await fetch(this.callbackUrlValue, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
          .content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        import: data,
      }),
    });
    const body = await response.json();
    if (undefined !== body.location) {
      window.location = body.location;
    } else {
      flash(body.message, 5000);
    }
  }
}
