import { BackendJSON, DropletJSON, IPaymentIntent } from '../payment_intent';
import { PreparedTransaction } from '../prepared_transaction';
import { PaymentMethod } from '../payment_method';
import { Payable } from '../payable';

type DropletPaymentIntentParams = {
  guid?: string;
  payable: Payable;
  amount?: number;
  paymentMethod: PaymentMethod;
  preparedTransaction?: PreparedTransaction;
};
export class DropletPaymentIntent implements IPaymentIntent {
  guid?: string;
  payable: Payable;
  amount?: number;
  paymentMethod: PaymentMethod;
  preparedTransaction?: PreparedTransaction;

  public constructor({
    guid,
    payable,
    amount,
    paymentMethod,
    preparedTransaction,
  }: DropletPaymentIntentParams) {
    this.guid = guid;
    this.payable = payable;
    this.amount = amount;
    this.paymentMethod = paymentMethod;
    this.preparedTransaction = preparedTransaction;
  }

  toJson(): DropletJSON {
    return {
      dropletAddressIdentifier: this.contractAddressIdentifier,
      tokenAddressIdentifier: this.paymentMethod.token.addressIdentifier,
      amount: this.amount || '0',
      transfers: this.payable.transfers,
      tokenDecimals: this.paymentMethod.token.decimals,
    } as DropletJSON;
  }

  toBackendJson(): BackendJSON {
    return {
      from_address_identifier: this.paymentMethod.addressIdentifier,
      to_address_identifier: this.contractAddressIdentifier,
      via_address_identifier: this.paymentMethod.token.addressIdentifier,
      token_id: this.paymentMethod.token.id,
      amount: this.amount?.toString(),
      gas: this.preparedTransaction?.gasLimit?.toString(),
      data: this.preparedTransaction?.data?.toString(),
    } as BackendJSON;
  }

  get contractAddressIdentifier(): string {
    return this.paymentMethod.token.chain.dropletAddressIdentifier!;
  }
}
