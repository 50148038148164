import { Controller } from 'stimulus';
import { Magnet } from '../web3/magnet/magnet';
import { Web3Account, Web3Connector } from '../web3/magnet/types';

export default class Web3Controller extends Controller {
  static targets = [
    'connectingView',
    'connectedView',
    'disconnectedView',
    'injectedButtonsContainer',
    'injectedButtonTemplate',
  ];
  declare connectingViewTargets: HTMLElement[];
  declare connectedViewTargets: HTMLElement[];
  declare disconnectedViewTargets: HTMLElement[];
  declare injectedButtonsContainerTarget: HTMLElement;
  declare injectedButtonTemplateTarget: HTMLTemplateElement;
  declare hasConnectingViewTarget: boolean;
  declare hasConnectedViewTarget: boolean;
  declare hasDisconnectedViewTarget: boolean;
  declare hasInjectedButtonsContainerTarget: boolean;

  protected magnet: Magnet | undefined;
  protected injectedConnectorButtons: Array<HTMLButtonElement> = [];

  async connect() {
    this.onWeb3Initialized = this.onWeb3Initialized.bind(this);
    this.onConnectorsAdded = this.onConnectorsAdded.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);

    // If this.magnet is not set but window.magnet is, we're in a Turbo visit. Magnet has been already initialized,
    // so it won't dispatch any event. We trigger them manually
    if (this.magnet === undefined && window.magnet !== undefined) {
      await this.onWeb3Initialized();
      await this.onAccountChange();
    }
    document.addEventListener('Web3Initialized', this.onWeb3Initialized);
    document.addEventListener('Web3AccountChange', this.onAccountChange);
    document.addEventListener('Web3ConnectorsAdded', this.onConnectorsAdded);
  }

  /*
  Override in subclasses if needed, but remember to call super.onWeb3Initialized() first to have this.magnet available
   */
  async onWeb3Initialized() {
    this.magnet = (window as any).magnet as Magnet;
    this.#addInjectedConnectorsButtons(this.magnet.connectors());
  }

  /*
  event: {
    detail: {
      connectors: Web3Connector[],
      prevConnectors: Web3Connector[]
    }
  }
   */
  async onConnectorsAdded(event: CustomEvent) {
    const newConnectors = event.detail.connectors.filter(
      (connector: Web3Connector) =>
        !event.detail.prevConnectors.includes(connector)
    );
    this.#addInjectedConnectorsButtons(newConnectors);
  }

  /*
  Although allowed, not intended to be overridden. Override the onAccountConnected, onAccountConnecting and
  onAccountDisconnected methods instead.
   */
  async onAccountChange() {
    const account = this.magnet?.web3Account;
    if (account === undefined) {
      return;
    }
    switch (account.status) {
      case 'connected':
        // Workaround for Ronin wallet, when rejecting connection the account comes as connected but with no address
        if (account.address === undefined) {
          this.onAccountDisconnected(account);
          return;
        }
        this.onAccountConnected(account);
        break;
      case 'connecting':
        this.onAccountConnecting(account);
        break;
      case 'reconnecting':
        this.onAccountConnecting(account);
        break;
      case 'disconnected':
        this.onAccountDisconnected(account);
        break;
      default:
        break;
    }
  }

  onAccountConnected(account: Web3Account) {
    if (this.hasConnectingViewTarget) {
      this.connectingViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasDisconnectedViewTarget) {
      this.disconnectedViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasConnectedViewTarget) {
      this.connectedViewTargets.forEach((target) =>
        target.classList.remove('hidden')
      );
    }
  }

  onAccountConnecting(account: Web3Account) {
    if (this.hasDisconnectedViewTarget) {
      this.disconnectedViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasConnectedViewTarget) {
      this.connectedViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasConnectingViewTarget) {
      this.connectingViewTargets.forEach((target) =>
        target.classList.remove('hidden')
      );
    }
  }

  onAccountDisconnected(account: Web3Account) {
    if (this.hasConnectingViewTarget) {
      this.connectingViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasConnectedViewTarget) {
      this.connectedViewTargets.forEach((target) =>
        target.classList.add('hidden')
      );
    }
    if (this.hasDisconnectedViewTarget) {
      this.disconnectedViewTargets.forEach((target) =>
        target.classList.remove('hidden')
      );
    }
  }

  #addWalletButton(connector: Web3Connector) {
    const clone = this.injectedButtonTemplateTarget.content.cloneNode(
      true
    ) as HTMLElement;
    // Get the button element from the clone
    const button = clone.querySelector('button')!;
    button.id = connector.id!;
    const buttonText = button.querySelector('span')!;
    buttonText.textContent = connector.name!;
    const buttonImage = button.querySelector('img')! as HTMLImageElement;
    buttonImage.src = connector.icon || '';

    // Append the button to the container
    this.injectedButtonsContainerTarget.appendChild(button);
    button.addEventListener('click', async () => {
      await this.magnet!.connect(connector);
    });
    this.injectedConnectorButtons.push(button);
  }

  #hasInjectedButtonForConnector(connector: Web3Connector): boolean {
    return this.injectedConnectorButtons.some(
      (button) => button.id === connector.id
    );
  }

  #addInjectedConnectorsButtons(connectors: Array<Web3Connector>) {
    if (this.hasInjectedButtonsContainerTarget) {
      const injectedConnectors = connectors.filter(
        (connector: Web3Connector) => {
          return connector.type === 'injected' && connector.name !== 'Injected';
        }
      );
      injectedConnectors.forEach((connector: Web3Connector) => {
        if (!this.#hasInjectedButtonForConnector(connector)) {
          this.#addWalletButton(connector);
        }
      });
    }
  }

  async onWalletConnectButtonClick(_event: Event) {
    await this.magnet!.openWeb3Modal();
  }
}
