import { Controller } from 'stimulus';
import TextFieldController from '../../../lib/nakamoto/components/forms/text_field_controller';
import debounce from 'debounce';

export default class FormComponentController extends Controller {
  static targets = [
    'poolSelect',
    'categorySelect',
    'amountInput',
    'txHashInput',
  ];

  declare readonly poolSelectTarget: HTMLSelectElement;
  declare readonly categorySelectTarget: HTMLSelectElement;
  declare readonly amountInputTarget: HTMLInputElement;
  declare readonly txHashInputTarget: HTMLInputElement;

  static values = { poolsAndAmounts: Object, fetchTxUrl: String };
  declare readonly poolsAndAmountsValue: { [key: string]: number };
  declare readonly fetchTxUrlValue: string;

  static outlets = ['forms--new-text-field'];
  declare readonly formsNewTextFieldOutlet: TextFieldController;

  initialize() {
    this.handleTxHashInput = debounce(this.handleTxHashInput.bind(this), 300);
  }

  connect() {
    super.connect();
    this.configureAmountInput();
  }

  poolSelectChanged(event: Event): void {
    this.configureAmountInput();
    const select = event.target as HTMLSelectElement;
    const maxAmount = select.options[select.selectedIndex].dataset.maxAmount;
    if (maxAmount) {
      this.formsNewTextFieldOutlet.setAddOnValue(maxAmount);
    }
  }

  categorySelectChanged(): void {
    this.configureAmountInput();
  }

  isValidTxHash(txHash: string) {
    return /^0x([A-Fa-f0-9]{64})$/.test(txHash);
  }

  configureAmountInput() {
    const selectedPool = this.poolSelectTarget.value;
    const selectedCategory = this.categorySelectTarget.value;
    if (selectedCategory === 'contribution') {
      this.amountInputTarget.placeholder = 'Enter amount to contribute';
      this.amountInputTarget.max = '';
      this.formsTextFieldOutlet.hideAddOn();
    } else if (selectedCategory === 'other_addition') {
      this.amountInputTarget.placeholder = 'Enter amount to add';
      this.amountInputTarget.max = '';
      this.formsTextFieldOutlet.hideAddOn();
    } else if (selectedCategory === 'refund') {
      this.amountInputTarget.placeholder = 'Enter amount to refund';
      this.amountInputTarget.max =
        this.poolsAndAmountsValue[selectedPool]?.toString() || '';
      this.formsTextFieldOutlet.showAddOn();
    } else if (selectedCategory === 'other_deduction') {
      this.amountInputTarget.placeholder = 'Enter amount to deduct';
      this.amountInputTarget.max =
        this.poolsAndAmountsValue[selectedPool]?.toString() || '';
      this.formsTextFieldOutlet.showAddOn();
    } else {
      this.amountInputTarget.placeholder = 'Enter amount';
      this.amountInputTarget.max =
        this.poolsAndAmountsValue[selectedPool]?.toString() || '';
      this.formsTextFieldOutlet.hideAddOn();
    }
  }

  handleTxHashInput(event: Event): void {
    event.preventDefault();
    const txHash = this.txHashInputTarget.value;

    // As long as the user has inputted a tx hash, the amount input should be set to readonly
    // and the value should be cleared and finally set by the result of the fetched result
    if (txHash.length > 0) {
      this.amountInputTarget.readOnly = true;
      this.amountInputTarget.value = '';
    } else {
      this.amountInputTarget.readOnly = false;
    }
    if (this.isValidTxHash(txHash)) {
      fetch(`${this.fetchTxUrlValue}?tx_hash=${txHash}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.amountInputTarget.value = data.amount;

            // Call "keyup->forms--text-field#handleValidateInput" action from input target
            const textFieldController =
              this.application.getControllerForElementAndIdentifier(
                this.amountInputTarget.parentElement as HTMLElement,
                'forms--text-field'
              ) as TextFieldController;
            textFieldController?.handleFocusInput();
            textFieldController?.handleValidateInput();
          } else {
            console.error(data.errors);
          }
        });
    }
  }
}
