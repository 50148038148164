import {
  connect,
  reconnect,
  disconnect,
  watchAccount,
  GetAccountReturnType,
  Connector,
  Connection,
  sendTransaction,
  estimateGas,
  simulateContract,
  switchChain,
  ChainNotConfiguredError,
  getBalance,
  readContract,
  writeContract,
  waitForTransactionReceipt,
  signMessage,
  ConnectorAlreadyConnectedError,
  readContracts,
  watchConnectors,
} from '@wagmi/core';
import { createWeb3Modal, Web3Modal } from '@web3modal/wagmi';
import {
  Address,
  Chain,
  ChainContract,
  TransactionReceiptNotFoundError,
  UserRejectedRequestError,
  WaitForTransactionReceiptTimeoutError,
} from 'viem';
import { abiForToken, chainName } from './utils';
import { createWagmiConfig } from './config';
import {
  ChainConfig,
  mapConnector,
  Web3Account,
  Web3Chain,
  Web3Connector,
  Web3ErrorLogger,
  Web3Token,
} from './types';
import {
  extractWagmiErrorDetails,
  MagnetError,
  MagnetUserRejectedRequestError,
} from './errors';
import * as Contracts from './abis/abis';

interface SignMessageParams {
  message: string;
}
interface GetTokenParams {
  address: Address;
  chainId: Number;
}

interface WritePreparedTransactionParams {
  preparedRequest: any;
  confirmations?: number;
  onTransactionSubmitted?: (hash: Address) => void;
  onTransactionConfirmed?: (
    hash: Address,
    currentConfirmations: number
  ) => void;
  onTransactionReplaced?: (params: OnTransactionReplacedParams) => void;
  onTransactionCancelled?: (hash: Address) => void;
  onTransactionRepriced?: (hash: Address) => void;
}
interface OnTransactionReplacedParams {
  hash: Address;
  replacedTransaction: any;
  transaction: any;
  transactionReceipt: any;
}
interface WaitForTransactionParams {
  hash: Address;
  confirmations?: number;
  retryOnTransactionReceiptNotFoundError?: boolean;
  onTransactionConfirmed?: (
    hash: Address,
    currentConfirmations: number
  ) => void;
  onTransactionReplaced?: (params: OnTransactionReplacedParams) => void;
  onTransactionCancelled?: (hash: Address) => void;
  onTransactionRepriced?: (hash: Address) => void;
}
interface BalanceParams {
  tokenAddress?: Address;
  chainId?: number;
}
interface AllowanceParams {
  tokenAddress: Address;
  spenderAddress: Address;
}
interface PrepareApproveAllowanceParams {
  tokenAddress: Address;
  spenderAddress: Address;
  amount: bigint;
}
interface PrepareTransferParams {
  tokenAddress: Address;
  recipientAddress: Address;
  amount: bigint;
}

interface EstimateGasParams {
  recipientAddress: Address;
  value: bigint;
}
interface SendTransactionParams {
  gas?: bigint;
  recipientAddress: Address;
  value: bigint;
  confirmations?: number;
  onTransactionSubmitted?: (hash: Address) => void;
  onTransactionConfirmed?: (
    hash: Address,
    currentConfirmations: number
  ) => void;
}

interface PrepareDropletTokenDistributionParams {
  tokenAddress: Address;
  recipientsAddresses: Address[];
  amounts: bigint[];
  taxed?: boolean;
}

interface PrepareDropletNativeDistributionParams {
  value: bigint;
  recipientsAddresses: Address[];
  amounts: bigint[];
}

interface PrepareDeckLockTokensParams {
  tokenAddress: Address;
  amount: bigint;
  merkleRoot: string;
  distributionIdToReplace?: number;
  taxed?: boolean;
}

interface DeckGetDistributionParams {
  distributionId: number;
}

interface DeckGetClaimedDLIsParams {
  distributionId: number;
  distributionIdIndexes: Array<number>;
}

interface PrepareDeckClaimTokensParams {
  distributionId: number;
  merkleIndex: number;
  amount: bigint;
  merkleProof: string;
}

interface PrepareDeckInvalidateDistributionForReplacementParams {
  distributionId: number;
}

interface PrepareDeckCancelInvalidateDistributionForReplacementParams {
  distributionId: number;
}

interface PrepareDeckReclaimTokensParams {
  distributionId: number;
}

interface MagnetParams {
  chainsConfig?: ChainConfig[];
  errorLogger?: Web3ErrorLogger;
  walletConnectProjectId?: string;
  maxRoninReconnectAttempts?: number;
  roninReconnectDelayMs?: number;
}

class Magnet {
  DEFAULT_MAX_RONIN_RECONNECT_ATTEMPTS = 30;
  DEFAULT_RONIN_RECONNECT_DELAY_MS = 5;
  #wagmiConfig: any;
  #web3Modal: Web3Modal | undefined;
  #account?: GetAccountReturnType;
  #errorLogger?: Web3ErrorLogger;
  #reconnectingToRoninAttemptCount = 0;
  #roninWasConnected = false;
  web3Account?: Web3Account;

  constructor(params?: MagnetParams) {
    const {
      chainsConfig,
      errorLogger,
      walletConnectProjectId,
      maxRoninReconnectAttempts,
      roninReconnectDelayMs,
    } = params || {};
    this.#errorLogger = errorLogger;

    this.#wagmiConfig = createWagmiConfig(
      chainsConfig || this.#getChainsConfig()
    );

    if (walletConnectProjectId !== undefined) {
      this.#web3Modal = createWeb3Modal({
        wagmiConfig: this.#wagmiConfig,
        projectId: walletConnectProjectId,
      });
    }

    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('Web3Initialized', {
          detail: {
            connectors: this.connectors(),
          },
        })
      );

      watchAccount(this.#wagmiConfig, {
        onChange: (account) => {
          // Note: Ronin wallet (at least) sometimes returns the account as "connected", but with
          // no address, when rejecting the connection in the wallet.
          this.#account = account;
          this.web3Account = {
            status: account.status,
            address: account.address,
            chain: this.#web3Chain(account),
            connector: mapConnector(account.connector),
          };

          if (account.status === 'connected') {
            localStorage.setItem('magnet.connectorId', account.connector.id);
            if (account.connector.id === 'com.roninchain.wallet') {
              this.#roninWasConnected = true;
            }
          } else if (account.status === 'disconnected') {
            // Workaround for Ronin: it sometimes takes some time to initialize, and it never comes as connected unless
            // we force reconnecting after a certain time. This retries several times before giving up.
            // Only retry if Ronin was the last wallet used and it's the first attempt to connect to it in this session,
            // to avoid trying after explicitly disconnected.
            if (
              !this.#roninWasConnected &&
              localStorage.getItem('magnet.connectorId') ===
                'com.roninchain.wallet'
            ) {
              if (
                this.#reconnectingToRoninAttemptCount <
                (maxRoninReconnectAttempts ||
                  this.DEFAULT_MAX_RONIN_RECONNECT_ATTEMPTS)
              ) {
                this.#reconnectingToRoninAttemptCount++;
                setTimeout(() => {
                  reconnect(this.#wagmiConfig);
                }, roninReconnectDelayMs || this.DEFAULT_RONIN_RECONNECT_DELAY_MS);
                return;
              } else {
                this.#roninWasConnected = false;
                localStorage.removeItem('magnet.connectorId');
              }
            } else {
              this.#roninWasConnected = false;
              localStorage.removeItem('magnet.connectorId');
            }
          }

          document.dispatchEvent(
            new CustomEvent('Web3AccountChange', {
              detail: {
                account: this.web3Account,
              },
            })
          );
        },
      });

      watchConnectors(this.#wagmiConfig, {
        onChange: (connections, prevConnectors) => {
          document.dispatchEvent(
            new CustomEvent('Web3ConnectorsAdded', {
              detail: {
                connectors: connections.map((connector) =>
                  mapConnector(connector)
                ),
                prevConnectors: prevConnectors.map((connector) =>
                  mapConnector(connector)
                ),
              },
            })
          );
        },
      });

      reconnect(this.#wagmiConfig);
    }, 0);
  }

  /*
      Gets the JSON chains configuration from the HTML.
  */
  #getChainsConfig() {
    const configElement = document.querySelector(
      '[data-magnet-chains]'
    ) as HTMLElement;
    var chains = [];
    if (configElement?.dataset.magnetChains) {
      chains = JSON.parse(configElement.dataset.magnetChains);
    }
    return chains;
  }

  #web3Chain(account: GetAccountReturnType) {
    const chain = this.#wagmiConfig.chains.find(
      (chain: Chain) => chain.id === account.chainId
    );
    if (chain) {
      return {
        id: chain.id,
        name: chain.name,
        supported: true,
      };
    } else {
      return {
        id: account.chainId,
        name: chainName(account.chainId),
        supported: false,
      };
    }
  }

  connectors() {
    return this.#wagmiConfig.connectors.map((connector: Connector) => {
      return mapConnector(connector);
    });
  }

  /*
      CHAINS MANAGEMENT
  */

  /*
      Checks if Magnet is configured for a given chain.
  */
  isChainSupported(chainId: number) {
    return this.#wagmiConfig.chains.some(
      (chain: Chain) => chain.id === chainId
    );
  }

  /*
      Returns the list of chains configured in the Magnet instance.
  */
  supportedChains(): Array<Web3Chain> {
    return this.#wagmiConfig.chains.map((chain: Chain) => {
      return {
        id: chain.id,
        name: chain.name,
        supported: true,
      };
    });
  }

  /*
      Asks the wallet to switch to a different chain.
  */
  async switchChain(chainId: number) {
    try {
      return await switchChain(this.#wagmiConfig, {
        chainId: chainId,
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      COMMON
  */

  #chainHasDropletEnabled() {
    return (
      (this.#account?.chain?.contracts?.droplet as ChainContract)?.address !==
      undefined
    );
  }

  #chainHasDeckEnabled() {
    return (
      (this.#account?.chain?.contracts?.deck as ChainContract)?.address !==
      undefined
    );
  }
  /*
      Waits for a transaction to be confirmed a certain number of times, invoking a callback on each confirmation.
  */
  async #waitForTransaction(params: WaitForTransactionParams) {
    const {
      hash,
      confirmations = 1,
      retryOnTransactionReceiptNotFoundError = true,
      onTransactionConfirmed,
      onTransactionReplaced,
      onTransactionCancelled,
      onTransactionRepriced,
    } = params;
    try {
      let currentConfirmations: number = 0;
      let result;
      while (currentConfirmations < confirmations) {
        result = await waitForTransactionReceipt(this.#wagmiConfig, {
          hash,
          confirmations: currentConfirmations + 1,
          onReplaced: async (data) => {
            switch (data.reason) {
              case 'cancelled':
                if (onTransactionCancelled)
                  onTransactionCancelled(data.transaction.hash);
                break;
              case 'replaced':
                if (onTransactionReplaced)
                  onTransactionReplaced({
                    hash: data.transaction.hash,
                    replacedTransaction: data.replacedTransaction,
                    transaction: data.transaction,
                    transactionReceipt: data.transactionReceipt,
                  });
                break;
              case 'repriced':
                if (onTransactionRepriced)
                  onTransactionRepriced(data.transaction.hash);
                break;
            }
          },
        });
        if (result === undefined) {
          if (onTransactionCancelled) onTransactionCancelled(hash);
          break;
        }
        if (result.transactionHash === hash) {
          currentConfirmations++;
          if (onTransactionConfirmed) {
            onTransactionConfirmed(hash, currentConfirmations);
          }
        } else {
          if (onTransactionCancelled) onTransactionCancelled(hash);
          break;
        }
      }
      // Only return the receipt if we reached the confirmations, otherwise something went wrong
      if (currentConfirmations === confirmations) {
        return result;
      }
    } catch (error: any) {
      if (
        error instanceof TransactionReceiptNotFoundError &&
        retryOnTransactionReceiptNotFoundError
      ) {
        // Some RPC nodes load balance and cache so aggressively that we fail to get the receipt on the first attempt.
        // In those cases, a simple retry usually solves the issue.
        // See https://github.com/wevm/viem/issues/1056
        return await this.#waitForTransaction({
          ...params,
          retryOnTransactionReceiptNotFoundError: true,
        });
      }
      this.#handleError(error);
    }
  }

  async getToken(params: GetTokenParams): Promise<Web3Token | undefined> {
    try {
      const { address, chainId } = params;
      const abi = abiForToken(address, chainId);
      const result = await readContracts(this.#wagmiConfig, {
        allowFailure: true,
        contracts: [
          {
            abi,
            address: address,
            chainId: chainId,
            functionName: 'name',
          },
          {
            abi,
            address: address,
            chainId: chainId,
            functionName: 'symbol',
          },
          {
            abi,
            address: address,
            chainId: chainId,
            functionName: 'decimals',
          },
        ],
      });
      return {
        name: result[0].result,
        symbol: result[1].result,
        decimals: result[2].result,
      };
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      ERROR HANDLING
  */
  #handleError(error: any): void {
    if (error instanceof UserRejectedRequestError) {
      throw new MagnetUserRejectedRequestError();
    }
    if (/rejected/.test(error.message)) {
      throw new MagnetUserRejectedRequestError();
    }
    this.#errorLogger?.logError(error, this.#metadata());
    if (error instanceof ChainNotConfiguredError) {
      throw new MagnetError('Chain not configured');
    }
    if (error instanceof WaitForTransactionReceiptTimeoutError) {
      throw new MagnetError(
        'Timed out while waiting for transaction to be confirmed'
      );
    }
    throw new MagnetError(`Error: ${extractWagmiErrorDetails(error.message)}`);
  }

  #metadata(): any {
    return {
      account: this.#account,
      connectors: this.connectors(),
    };
  }

  /*
      Writes the given prepared transaction to the contract. The transaction must have been prepared with
      simulateContract().
  */
  async writePreparedTransaction(
    params: WritePreparedTransactionParams
  ): Promise<any> {
    const {
      preparedRequest,
      onTransactionSubmitted,
      confirmations,
      onTransactionConfirmed,
      onTransactionReplaced,
      onTransactionCancelled,
      onTransactionRepriced,
    } = params;
    const hash = await writeContract(this.#wagmiConfig, preparedRequest);

    if (onTransactionSubmitted) {
      onTransactionSubmitted(hash);
    }

    return this.#waitForTransaction({
      hash: hash,
      confirmations: confirmations,
      onTransactionConfirmed: onTransactionConfirmed,
      onTransactionReplaced: onTransactionReplaced,
      onTransactionCancelled: onTransactionCancelled,
      onTransactionRepriced: onTransactionRepriced,
    });
  }

  async signMessage(params: SignMessageParams): Promise<any> {
    const { message } = params;
    try {
      return await signMessage(this.#wagmiConfig, {
        message: message,
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      ERC20 METHODS
  */

  async getBalance(params: BalanceParams): Promise<any> {
    const { tokenAddress, chainId } = params;
    const address = this.web3Account!.address as Address;
    try {
      return await getBalance(this.#wagmiConfig, {
        address: address,
        token: tokenAddress,
        chainId: chainId,
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async allowance(params: AllowanceParams): Promise<any> {
    try {
      const { tokenAddress, spenderAddress } = params;
      const address = this.web3Account!.address as Address;
      return await readContract(this.#wagmiConfig, {
        abi: abiForToken(tokenAddress, this.#account!.chainId),
        address: tokenAddress,
        functionName: 'allowance',
        args: [address, spenderAddress],
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareApproveAllowance(
    params: PrepareApproveAllowanceParams
  ): Promise<any> {
    try {
      const { tokenAddress, spenderAddress, amount } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: abiForToken(tokenAddress, this.#account!.chainId),
        address: tokenAddress,
        functionName: 'approve',
        args: [spenderAddress, amount],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      Prepares for transferring ERC20 tokens.
  */
  async prepareTransfer(params: PrepareTransferParams): Promise<any> {
    try {
      const { tokenAddress, recipientAddress, amount } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: abiForToken(tokenAddress, this.#account!.chainId),
        address: tokenAddress,
        functionName: 'transfer',
        args: [recipientAddress, amount],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      NATIVE
  */

  /*
      Estimates the gas for a native transaction.
      Use this to get the gas beforehand, to be passed to sendTransaction()
  */
  async estimateGas(params: EstimateGasParams): Promise<any> {
    try {
      const { recipientAddress, value } = params;
      const gas = await estimateGas(this.#wagmiConfig, {
        to: recipientAddress,
        value: value,
      });
      return gas;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      Transfers native token to a recipient.
  */
  async sendTransaction(params: SendTransactionParams): Promise<any> {
    try {
      const {
        gas,
        recipientAddress,
        value,
        onTransactionSubmitted,
        confirmations,
        onTransactionConfirmed,
      } = params;
      const hash = await sendTransaction(this.#wagmiConfig, {
        gas: gas,
        to: recipientAddress,
        value: value,
      });

      if (onTransactionSubmitted) {
        onTransactionSubmitted(hash);
      }

      return this.#waitForTransaction({
        hash: hash,
        confirmations: confirmations,
        onTransactionConfirmed: onTransactionConfirmed,
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      DROPLET
  */

  /*
      Prepares for distributing (untaxed) ERC20 tokens.
  */
  async prepareDropletTokenDistribution(
    params: PrepareDropletTokenDistributionParams
  ): Promise<any> {
    if (!this.#chainHasDropletEnabled()) {
      throw new MagnetError('Droplet not configured for this chain');
    }
    try {
      const {
        tokenAddress,
        recipientsAddresses,
        amounts,
        taxed = false,
      } = params;
      const functionName = taxed
        ? 'presailDistributeTokenSimple'
        : 'presailDistributeToken';
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.dropletAbi,
        address: (this.#account?.chain?.contracts?.droplet as ChainContract)
          ?.address,
        functionName: functionName,
        args: [tokenAddress, recipientsAddresses, amounts],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDropletNativeDistribution(
    params: PrepareDropletNativeDistributionParams
  ): Promise<any> {
    if (!this.#chainHasDropletEnabled()) {
      throw new MagnetError('Droplet not configured for this chain');
    }
    try {
      const { value, recipientsAddresses, amounts } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.dropletAbi,
        address: (this.#account?.chain?.contracts?.droplet as ChainContract)
          ?.address,
        value: value,
        functionName: 'presailDistribute',
        args: [recipientsAddresses, amounts],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      DECK
  */
  async deckGetDistribution(params: DeckGetDistributionParams): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId } = params;
      return await readContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'distributions',
        args: [distributionId],
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async deckGetClaimedDLIs(params: DeckGetClaimedDLIsParams): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId, distributionIdIndexes } = params;
      return await readContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'areClaimed',
        args: [distributionId, distributionIdIndexes],
      });
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDeckLockTokens(
    params: PrepareDeckLockTokensParams
  ): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const {
        tokenAddress,
        amount,
        merkleRoot,
        distributionIdToReplace,
        taxed = false,
      } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'lockTokens',
        args: [
          tokenAddress,
          amount,
          merkleRoot,
          !!distributionIdToReplace,
          distributionIdToReplace,
          !taxed,
        ],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDeckClaimTokens(
    params: PrepareDeckClaimTokensParams
  ): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId, merkleIndex, amount, merkleProof } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'claimTokens',
        args: [distributionId, merkleIndex, amount, merkleProof],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDeckInvalidateDistributionForReplacement(
    params: PrepareDeckInvalidateDistributionForReplacementParams
  ): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'invalidateDistributionForReplacement',
        args: [distributionId],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDeckCancelInvalidateDistributionForReplacement(
    params: PrepareDeckCancelInvalidateDistributionForReplacementParams
  ): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'cancelInvalidateDistributionForReplacement',
        args: [distributionId],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  async prepareDeckReclaimTokens(
    params: PrepareDeckReclaimTokensParams
  ): Promise<any> {
    if (!this.#chainHasDeckEnabled()) {
      throw new MagnetError('Deck not configured for this chain');
    }
    try {
      const { distributionId } = params;
      const { request } = await simulateContract(this.#wagmiConfig, {
        abi: Contracts.deckAbi,
        address: (this.#account?.chain?.contracts?.deck as ChainContract)
          ?.address,
        functionName: 'reclaimTokensAndInvalidateDistribution',
        args: [distributionId],
      });
      return request;
    } catch (error: any) {
      this.#handleError(error);
    }
  }

  /*
      CONNECTION MANAGEMENT
  */
  async openWeb3Modal() {
    if (this.#web3Modal === undefined) return;

    this.#web3Modal.subscribeState((newState) => {
      // This triggers when closing the modal, but the account hangs in "connecting" state
      if (!newState.open && newState.selectedNetworkId === undefined) {
        this.disconnect();
      }
    });

    await this.#web3Modal.open();
  }

  async connect(web3Connector: Web3Connector) {
    const connector = this.#wagmiConfig.connectors.find(
      (connector: Connector) => connector.id === web3Connector.id
    );
    try {
      await connect(this.#wagmiConfig, {
        connector: connector,
      });
    } catch (error) {
      // Workaround for Ronin Wallet. Sometimes, after rejecting the connection, it gets stuck as "connected" but with
      // no address, and this method throws ConnectorAlreadyConnectedError but no connection is actually available
      if (error instanceof ConnectorAlreadyConnectedError) {
        await reconnect(this.#wagmiConfig); // Resets the connection
        await this.connect(web3Connector); // Triggers connection again
        return;
      }
      this.#handleError(error);
    }
  }

  disconnect() {
    this.#wagmiConfig.connectors.forEach(async (connection: Connection) => {
      await disconnect(this.#wagmiConfig, {
        connector: connection.connector,
      });
    });
    this.#wagmiConfig.state.connections.clear();
  }
}

export { Magnet };
