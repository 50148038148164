import { IPaymentIntent } from '../../model/payment_intent';
import { ICompassFormRouter } from './presenter';
import ActionsController from '../../../actions_controller';
import { Application } from 'stimulus';

type CompassFormRouterParams = {
  application: Application;
  compassBaseUrl: string;
  backUrl?: string;
};

export default class CompassFormRouter implements ICompassFormRouter {
  application: Application;
  // TODO: get the URLs injected by configuration
  compassBaseUrl: string;
  backUrl: string | undefined;

  constructor({
    application,
    compassBaseUrl,
    backUrl,
  }: CompassFormRouterParams) {
    this.application = application;
    this.compassBaseUrl = compassBaseUrl;
    this.backUrl = backUrl;
  }

  public async goToPaymentIntentConfirmation(
    paymentIntent: IPaymentIntent
  ): Promise<void> {
    return new Promise((resolve, _) => {
      this.ensureModalIsOpen();
      const modalContent = document.getElementById(
        'modal_content'
      ) as HTMLIFrameElement;
      modalContent.addEventListener(
        'turbo:frame-render',
        function () {
          resolve();
        },
        { once: true }
      );
      const url = new URL(`${this.compassBaseUrl}/${paymentIntent.guid}`);
      if (modalContent) modalContent.src = url.toString();
    });
  }

  public goBack(): void {
    if (this.backUrl) {
      this.ensureModalIsOpen();
      const modalContent = document.getElementById(
        'modal_content'
      ) as HTMLIFrameElement;
      if (modalContent) modalContent.src = this.backUrl;
    }
  }

  private ensureModalIsOpen(): void {
    if (!this.actionsController?.isShown()) {
      this.actionsController?.openModal(null, {
        lockModal: true,
        hideCloseButton: true,
      });
    }
  }

  private get actionsController(): ActionsController | undefined {
    const actionsControllerElement = document.querySelector(
      '[data-controller~=actions]'
    );
    if (actionsControllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        actionsControllerElement,
        'actions'
      ) as ActionsController;
    }
  }
}
