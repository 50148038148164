import { Controller } from "stimulus";
import {toggleVisibility} from "@/helpers/animations_helpers";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = { class: String };

  toggle() {
    if (this.classValue === 'hidden') {
      return toggleVisibility(this.toggleableTarget)
    }
    this.toggleableTarget.classList.toggle(this.classValue);
  }
}
