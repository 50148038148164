import { Controller } from 'stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = ['mainSelect', 'dependentSelect', 'dependentSelectAddOn'];

  static values = {
    placeholderText: String,
  };

  dependentSlimSelect;

  connect() {
    this.dependentSlimSelect = new SlimSelect({
      select: this.dependentSelectTarget,
      settings: {
        placeholderText: this.placeholderTextValue,
        allowDeselect: true,
        closeOnSelect: false,
        showSearch: false,
        minSelected: this.dependentSelectTarget.dataset.minSelected || 0,
      },
    });
    this.updateDependentSelect();
  }

  // Ensures element does not appear twice on page when using browser back-button
  disconnect() {
    this.dependentSlimSelect.destroy();
  }

  updateDependentSelect() {
    const dependentOptions =
      this.mainSelectTarget.selectedOptions[0].dataset.dependentOptions;
    if (dependentOptions === undefined) return;

    const dependentSelectOptions = JSON.parse(dependentOptions);
    this.dependentSlimSelect.setData(dependentSelectOptions);
    if (this.dependentSelectTarget.disabled) {
      this.dependentSlimSelect.disable();
    }
    if (
      dependentSelectOptions.length === 1 &&
      dependentSelectOptions[0].options === undefined
    ) {
      this.dependentSlimSelect.setSelected(dependentSelectOptions[0].value);
      this.hideDependentSelect();
    } else {
      this.showDependentSelect();
    }
  }

  showDependentSelect() {
    this.dependentSelectTarget.classList.remove('hidden');
    this.dependentSelectAddOnTargets.forEach((target) =>
      target.classList.remove('hidden')
    );
  }

  hideDependentSelect() {
    this.dependentSelectTarget.classList.add('hidden');
    this.dependentSelectAddOnTargets.forEach((target) =>
      target.classList.add('hidden')
    );
  }
}
