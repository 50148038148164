import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.onclick = (event) => {
      if (
        !this.element.contains(event.target) &&
        this.element.parentNode !== null
      ) {
        this.element.parentNode.removeChild(this.element);
      }
    };
  }
}
