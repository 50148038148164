import React, {useEffect, useState} from 'react';
import ProgressProvider from "./ProgressProvider";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import './styles/ProgressBar.css'
import AnimatedProgressProvider from "./ProgressProvider";
import { easeQuadInOut } from "d3-ease";

const AnimatedProgressBar = ({ percentage = 0, index = 0, stroke = "#55b685" }: { percentage: number, index: number, stroke: string }) => {
  const roundedPercentage = Math.round(percentage);
  const [isMounted, setIsMounted] = useState(false);
  const hasTransitionedIn = useMountTransition(isMounted, 1000);

  useEffect(() => {
    const loader = document.querySelectorAll(`.progress-bar-component`);
    for (const loaderElement of loader) {
      loaderElement.classList.add('hidden')
    }
  }, []);

  return (
    <>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={roundedPercentage}
        duration={1}
        easingFunction={easeQuadInOut}
      >
        {(value: number) => {
          const roundedValue = Math.round(value);
          return (
            <CircularProgressbar
              value={value}
              text={`${roundedValue}%`}
              /* This is important to include, because if you're fully managing the
        animation yourself, you'll want to disable the CSS animation. */
              styles={buildStyles({ pathTransition: "none", pathColor: stroke })}
            />
          );
        }}
      </AnimatedProgressProvider>
    </>
  )
}

const useMountTransition = (isMounted: any, unmountDelay: any) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId: any;

    if (isMounted && !hasTransitionedIn) {
      setHasTransitionedIn(true);
    } else if (!isMounted && hasTransitionedIn) {
      timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, hasTransitionedIn]);

  return hasTransitionedIn;
};

export default AnimatedProgressBar
