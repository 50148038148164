/*
 Yoroi wallet injects an <input> element as a marker right before the <head> tag. this causes the DOMParser to not parse
 the <head> tag properly and, ultimately, the CSRF meta tags are removed from the page when a Turbo-enabled link with
 action="advance" is clicked.
 This hack moves the <input> marker to the end of the <html> tag, so that the DOMParser parses the <head> tag properly.
 */
export {};

const yoroiNode = document.getElementById('__yoroi_connector_api_injected_type');

if (yoroiNode) {
  const htmlNode = document.getElementsByTagName('html')[0];
  htmlNode.removeChild(yoroiNode);
  htmlNode.appendChild(yoroiNode);
}
