import { Controller } from "@hotwired/stimulus"

export default class EventController extends Controller {
  preventDefault(event) {
    event.preventDefault()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
