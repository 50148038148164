import { Controller } from "stimulus";

export default class extends Controller {
  static values = { scrolled: Boolean }

  connect() {
    this.scrolledValue = false;
    this.scrollHandler = () => this.scrolledValue = true;
    window.addEventListener('containerScrolled', this.scrollHandler);
  }

  click(event) {
    if (this.scrolledValue) {
      event.preventDefault();
      this.scrolledValue = false;
    }
  }

  disconnect() {
    window.removeEventListener('containerScrolled', this.scrollHandler);
  }
}
