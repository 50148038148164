import { Controller } from 'stimulus';

// Delete once new_text_field_component replaces all instances of text_field_component
export default class TextFieldController extends Controller {
  static targets = [
    'label',
    'inputEl',
    'errorIcon',
    'trailingIcon',
    'leadingIcon',
    'addOnValue',
  ];

  declare readonly labelTarget: HTMLLabelElement;
  declare readonly inputElTarget: HTMLInputElement;
  declare readonly errorIconTarget: HTMLInputElement;
  declare readonly trailingIconTarget: HTMLInputElement;
  declare readonly addOnValueTarget: HTMLInputElement;
  declare readonly hasTrailingIconTarget: boolean;

  static values = { addOnValue: String };
  declare addOnValueValue: string;

  handleFocusInput() {
    this.labelTarget.childNodes.forEach((node) => {
      if (node instanceof HTMLInputElement) {
        node.focus();
      }
    });
  }

  handleValidateInput() {
    const pattern = this.inputElTarget.getAttribute('pattern');
    const notEmpty = this.inputElTarget.value.length > 0;

    if (pattern && notEmpty) {
      const regex = new RegExp(pattern);
      if (!regex.test(this.inputElTarget.value)) {
        this.labelTarget.classList.remove('n-text-input--completed');

        // We hide the trailing icon in favour of the error
        // icon when the input does not match the pattern
        if (this.hasTrailingIconTarget)
          this.trailingIconTarget.classList.add('hidden');

        this.errorIconTarget.classList.remove('hidden');
      } else {
        this.labelTarget.classList.add('n-text-input--completed');

        // Renables the dynamic icon when the pattern is met
        if (this.hasTrailingIconTarget)
          this.trailingIconTarget.classList.remove('hidden');

        this.errorIconTarget.classList.add('hidden');
      }
    } else if (notEmpty) {
      this.labelTarget.classList.add('n-text-input--completed');
    } else {
      this.labelTarget.classList.remove('n-text-input--completed');
      this.errorIconTarget.classList.add('hidden');
    }
  }

  onAddOnClick(event: Event) {
    event.preventDefault();
    this.inputElTarget.value = this.addOnValueValue;
  }

  setAddOnValue(value: string) {
    this.addOnValueValue = value;
  }

  showAddOn() {
    this.addOnValueTarget.classList.remove('hidden');
  }

  hideAddOn() {
    this.addOnValueTarget.classList.add('hidden');
  }

  blurScrollWheel() {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  }

  connect() {
    this.handleValidateInput();

    this.boundBlurScrollWheel = this.blurScrollWheel.bind(this);
    document.addEventListener('wheel', this.boundBlurScrollWheel);
  }

  disconnect() {
    document.removeEventListener('wheel', this.boundBlurScrollWheel);
  }
}
