import { Controller } from "stimulus";
import debounce from "debounce";

const subdomainRealtimeValidationStartLength = 5;

export default class DomainController extends Controller {
    static targets = [ "name", "domain", "availableDomainIndicator", "notAvailableDomainIndicator", "invalidDomainIndicator" ];
    static values = {
        validateDomainUrl: String,
    };

    declare private readonly domainTarget: HTMLInputElement;
    declare private readonly availableDomainIndicatorTarget: HTMLInputElement;
    declare private readonly notAvailableDomainIndicatorTarget: HTMLInputElement;
    declare private readonly invalidDomainIndicatorTarget: HTMLInputElement;
    declare private readonly validateDomainUrlValue: string;

    validateDomain = debounce(this.validateDomainImpl, 200);

    connect() {
        super.connect();
        this.validateDomain = this.validateDomain.bind(this);
    }

    private async validateDomainImpl() {
        this.domainTarget.value = this.domainTarget.value.toLowerCase();
        try {
            let response = await fetch(`${this.validateDomainUrlValue}?domain_name=${this.domainTarget.value}`, {
                method: 'GET',
            });
            let body = await response.json();

            this.availableDomainIndicatorTarget.classList.add("hidden");
            this.notAvailableDomainIndicatorTarget.classList.add("hidden");
            this.invalidDomainIndicatorTarget.classList.add("hidden");

            if (body.valid_domain) {
                if (body.available_domain)
                    this.availableDomainIndicatorTarget.classList.remove("hidden");
                else
                    this.notAvailableDomainIndicatorTarget.classList.remove("hidden");
            } else {
                if (this.domainTarget.value.length >= subdomainRealtimeValidationStartLength)
                    this.invalidDomainIndicatorTarget.classList.remove("hidden");
            }
        } catch (error) {
            throw error;
        }
    }
}
