import * as Chains from '@wagmi/core/chains';
import * as CustomChains from './chains/chains';
import { createConfig, http, Transport } from '@wagmi/core';
import { injected, walletConnect } from '@wagmi/connectors';
import { defineChain } from 'viem';
import { ChainConfig } from './types';

const injectedConnector = injected({
  shimDisconnect: false,
});

const walletConnectConnector = walletConnect({
  projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
  showQrModal: false,
});

/*
    Builds a Viem chain object (Chains.Chain) from a ChainConfig object.
    This is called for any chain passed in the configuration that Viem does not have a default
    definition for and no custom definition is found.
    This allows to add new chains dynamically to the system without adding any definition to 
    the chains/definitions/ folder, although both methods have the same effect.
*/
const buildChain = function (chainConfig: any): Chains.Chain {
  return defineChain({
    id: chainConfig.id,
    network: chainConfig.name,
    name: chainConfig.name,
    nativeCurrency: chainConfig.nativeCurrency,
    rpcUrls: {
      default: {
        http: [chainConfig.chainNodes[0].url],
      },
      public: {
        http: [chainConfig.chainNodes[0].url],
      },
    },
    blockExplorers: {
      default: {
        name: '',
        url: '',
      },
    },
    contracts: {
      droplet: {
        address: chainConfig.dropletAddress,
      },
      deck: {
        address: chainConfig.deckAddress,
      },
    },
    testnet: chainConfig.testnet,
  });
};

/* 
    Returns an array of Chains, as expected by Wagmi's createConfig(), created from the config passed via injected data- attributes.

    It will get the chain definition by searching in the tfollowing order:
    - In all chains definitions that come packaged in Viem
    - In './chains/definitions/'
    
    If no definition is found, it will create one dynamically with buildChain().
    
    To add a new chain, add its definition file to './chains/definitions/' and a new export to './chains/chains.ts'
    (see waterfall-network for reference).
*/
const chainsForChainsConfig = function (
  chainsConfig: ChainConfig[]
): Chains.Chain[] {
  const supportedChains = Object.entries({ ...Chains, ...CustomChains });
  const chains = chainsConfig.map((chainConfig) => {
    const supportedChain = supportedChains.find(
      ([_, chain]) => chain.id === chainConfig.id
    );
    let chain: Chains.Chain;
    if (supportedChain) {
      chain = supportedChain[1] as Chains.Chain;
      if (!!chainConfig.chainNodes[0]?.url) {
        chain.rpcUrls = {
          default: {
            http: [chainConfig.chainNodes[0].url],
          },
          public: {
            http: [chainConfig.chainNodes[0].url],
          },
        };
      }
    } else {
      chain = buildChain(chainConfig);
    }
    chain.contracts ||= {};
    chain.contracts.droplet ||= {
      address: chainConfig.dropletAddress,
    };
    chain.contracts.deck ||= {
      address: chainConfig.deckAddress,
    };
    return chain;
  });
  if (chains.length === 0) {
    return [Chains.mainnet];
  }
  return chains as Chains.Chain[];
};

/*
    Returns the default http() transports for all the configured chains
*/
const transports = function (chains: Chains.Chain[]) {
  const transports: { [key: number]: Transport } = {};
  chains.forEach((chain) => (transports[chain.id] = http()));
  return transports;
};

const createWagmiConfig = function (chainsConfig: ChainConfig[]) {
  const chains = chainsForChainsConfig(chainsConfig);
  return createConfig({
    chains: chains as [Chains.Chain, ...Chains.Chain[]],
    transports: transports(chains),
    connectors: [injectedConnector, walletConnectConnector],
  });
};

export { createWagmiConfig };
