import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  declare readonly linkTarget: HTMLElement;
  declare readonly hasLinkTarget: boolean;

  static values = { paramToDelete: String };

  declare readonly paramToDeleteValue: string;

  connect() {
    if (this.hasLinkTarget) {
      this.linkTarget.click();

      if (this.paramToDeleteValue) {
        // Delete param from current URL
        const url = new URL(window.location.href);
        url.searchParams.delete(this.paramToDeleteValue);
        window.history.replaceState({}, '', url.toString());
      }
    }
  }
}
