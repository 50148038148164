import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['navbar'];

  initialize() {
    this.checkStoredSidebar();
  }

  toggleNavigation() {
    const sidebar = this.navbarTarget;
    sidebar.classList.toggle('collapsed');
    const isCollapsed = document.querySelectorAll('.p-navbar.collapsed');

    if (isCollapsed.length === 1) {
      localStorage.setItem('expanded', 'true');
      // this.btnTextTarget.innerHTML = 'Open';
    } else {
      localStorage.removeItem('expanded');
      // this.btnTextTarget.innerHTML = 'Collapse Menu';
    }
  }

  checkStoredSidebar() {
    if (localStorage.getItem('expanded') === 'true') {
      this.navbarTarget.classList.add('collapsed');
      // this.btnTextTarget.innerHTML = 'Open';
    } else {
      this.navbarTarget.classList.remove('collapsed');
      // this.btnTextTarget.innerHTML = 'Collapse Menu';
    }
  }
}
