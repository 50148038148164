import { Controller } from 'stimulus';
import { hideElement, showElement } from '@/helpers/animations_helpers';
import Decimal from 'decimal.js';

export default class extends Controller {
  static targets = [
    'dateField',
    'nameField',
    'submitButton',
    'percentageField',
    'associationsAmount',
    'addedPercentageField',
    'existingPercentageField',
    'modifiablePercentageField',
    'unmodifiablePercentageField',
    'newBatchInterval',
    'newBatchDate',
    'addNewBatchesForm',
    'formWarning',
    'formWarningMessage',
    'distributePendingPercentagesButton',
  ];

  static values = {
    preventOvershooting: Boolean,
  };

  firstInNewBatch = true;

  connect() {
    super.connect();
    this.firstInNewBatch = true;
    this.validateAll(null);
  }

  handleAssociationAdded() {
    this.setNewBatchName();
    this.setNewBatchDate();
    this.validateAtLeastOneBatch();
  }

  updateTGE() {
    if (this.hasAddNewBatchesFormTarget) {
      showElement(this.addNewBatchesFormTarget);
    }
    this.calculateNewBatchPercentages();
  }

  handleSingleBatchAdded() {
    let currentPercentageSum = 0;
    for (const field of this.existingPercentageFieldTargets) {
      currentPercentageSum += parseFloat(field.value) || 0;
    }
    for (const field of this.addedPercentageFieldTargets) {
      currentPercentageSum += parseFloat(field.value) || 0;
    }
    this.addedPercentageFieldTargets[
      this.addedPercentageFieldTargets.length - 1
    ].value = Math.max(100 - currentPercentageSum, 0);
    this.validatePercentageSum();
  }

  handleFinishAssociationAddedSequence() {
    this.calculateNewBatchPercentages();
    this.validatePercentageSum();
    this.firstInNewBatch = true;
  }

  validateDate(event) {
    let anyErrors = false;
    for (let i = 1; i < this.dateFieldTargets.length; i++) {
      const currentField = this.dateFieldTargets[i];
      const previousField = this.dateFieldTargets[i - 1];
      const currentDate = new Date(currentField.value);
      const previousDate = new Date(previousField.value);

      const parentDiv = this.dateFieldTargets[i].closest(
        '[data-controller="forms--new-text-field"]'
      );
      const parentController =
        this.newTextFieldControllerFromElement(parentDiv);

      if (currentDate < previousDate) {
        if (parentController) {
          parentController.addError('Date must be after previous batch');
        }
        anyErrors = true;
      } else {
        if (parentController) {
          parentController.removeError();
        }
      }
    }

    if (anyErrors && event) event.preventDefault();
  }

  validatePercentage(event) {
    let anyErrors = false;
    for (const currentField of this.percentageFieldTargets) {
      const parentDiv = currentField.closest(
        '[data-controller="forms--new-text-field"]'
      );
      const parentController =
        this.newTextFieldControllerFromElement(parentDiv);
      const value = parseFloat(currentField.value);
      if (isNaN(value) || value < 0 || value > 100) {
        if (parentController) {
          parentController.addError('Must be between 0 and 100');
        }
        anyErrors = true;
      } else {
        if (parentController) {
          parentController.removeError();
        }
      }
    }

    const sumValid = this.validatePercentageSum();
    anyErrors = anyErrors || !sumValid;

    if (anyErrors) {
      this.disableSubmitButton();
      event?.preventDefault();
    } else {
      this.enableSubmitButton();
    }
  }

  validateName(event) {
    let anyErrors = false;
    for (const currentField of this.nameFieldTargets) {
      const parentDiv = currentField.closest(
        '[data-controller="forms--new-text-field"]'
      );
      const parentController =
        this.newTextFieldControllerFromElement(parentDiv);
      if (currentField.value.trim().length === 0) {
        if (parentController) {
          parentController.addError('Cannot be empty.');
        }
        anyErrors = true;
      } else {
        if (parentController) {
          parentController.removeError();
        }
      }
    }

    if (anyErrors && event) event.preventDefault();
  }

  validateAll(event) {
    this.validateDate(event);
    this.validateName(event);
    this.validatePercentage(event);
    this.validateAtLeastOneBatch();
  }

  validatePercentageSum() {
    const totalPercentage = this.percentageFieldTargets.reduce(
      (sum, target) => sum + (parseFloat(target.value) || 0),
      0
    );

    // accept diffs from 100% that are lower than 0.000001
    const threshold = 0.000001;
    if (totalPercentage > 0 && totalPercentage <= 100 - threshold) {
      this.showMessage(
        `Warning: Batches add up to less than 100% (${totalPercentage}%)`
      );
    } else if (totalPercentage > 100 + threshold) {
      if (this.preventOvershootingValue) {
        this.showMessage(
          `Warning: Batches can't add up to more than 100% (${totalPercentage}%)`
        );
        return false;
      } else {
        this.showMessage(
          `Warning: Batches add up to more than 100% (${totalPercentage}%)`
        );
      }
    } else {
      this.hideMessage();
    }
    return true;
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove('n-button--disabled');
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add('n-button--disabled');
  }

  showMessage(message) {
    this.formWarningMessageTarget.innerHTML = message;
    showElement(this.formWarningTarget);
  }

  hideMessage() {
    this.formWarningMessageTarget.innerHTML = null;
    this.formWarningTarget.classList.add('hidden');
  }

  markForRemoval(event) {
    const batchId = event.currentTarget.getAttribute('data-index');
    const inputName = `offer[batches_attributes][${batchId}][percentage]`;
    const inputIndex = this.percentageFieldTargets.findIndex(
      (input) => input.getAttribute('name') === inputName
    );

    if (inputIndex !== -1) {
      this.nameFieldTargets.splice(inputIndex, 1)[0].remove();
      this.dateFieldTargets.splice(inputIndex, 1)[0].remove();
      this.percentageFieldTargets.splice(inputIndex, 1)[0].remove();
    }

    this.calculateNewBatchPercentages();
    this.validateAtLeastOneBatch();
    this.validatePercentageSum();
  }

  setNewBatchName() {
    if (this.nameFieldTargets.length === 1) {
      this.nameFieldTargets[0].value = 'TGE';
    } else {
      const newBatchNumber = this.nameFieldTargets.length - 1;
      this.nameFieldTargets[newBatchNumber].value = `Batch ${newBatchNumber}`;
    }
  }

  calculateNewBatchPercentages() {
    let sum = 0;
    for (const field of this.existingPercentageFieldTargets) {
      const value = parseFloat(field.value) || 0;
      sum += value;
    }

    for (const field of this.addedPercentageFieldTargets) {
      const calculatedValue =
        parseFloat(100 - sum) /
        parseFloat(this.addedPercentageFieldTargets.length);
      const flooredValue = Math.min(100, Math.max(0, calculatedValue));
      // Max 12 decimals, remove trailing zeroes
      field.value = flooredValue;
    }
  }

  distributePendingPercentages(event) {
    event.preventDefault();
    let sum = 0;
    for (const field of this.unmodifiablePercentageFieldTargets) {
      const value = parseFloat(field.value) || 0;
      sum += value;
    }

    for (const field of this.modifiablePercentageFieldTargets) {
      const calculatedValue =
        parseFloat(100 - sum) /
        parseFloat(this.modifiablePercentageFieldTargets.length);
      const flooredValue = Math.min(100, Math.max(0, calculatedValue));
      // Max 12 decimals, remove trailing zeroes
      field.value = flooredValue;
    }
    this.validateAll();
  }

  setNewBatchDate() {
    if (!this.hasNewBatchDateTarget) return;

    const newBatchDate = this.newBatchDateTarget.value;
    const newBatchNumber = this.dateFieldTargets.length - 1;
    const previousField = this.dateFieldTargets[newBatchNumber - 1];
    const currentField = this.dateFieldTargets[newBatchNumber];
    let futureDate;

    if (this.firstInNewBatch && newBatchDate !== '') {
      futureDate = new Date(newBatchDate);
      futureDate.setUTCHours(12, 0, 0, 0);
      this.firstInNewBatch = false;
    } else {
      if (previousField !== undefined && previousField.value !== '') {
        futureDate = new Date(previousField.value);
      } else {
        futureDate = new Date();
      }

      futureDate.setUTCHours(12, 0, 0, 0);

      switch (this.newBatchIntervalTarget.value) {
        case 'weekly':
          futureDate = new Date(futureDate.getTime() + 7 * 24 * 60 * 60 * 1000);
          break;
        case 'biweekly':
          futureDate = new Date(
            futureDate.getTime() + 14 * 24 * 60 * 60 * 1000
          );
          break;
        case 'monthly':
          futureDate.setMonth(futureDate.getMonth() + 1);
          break;
        case 'bimonthly':
          futureDate.setMonth(futureDate.getMonth() + 2);
          break;
        case 'quarterly':
          futureDate.setMonth(futureDate.getMonth() + 3);
          break;
        case 'semiannually':
          futureDate.setMonth(futureDate.getMonth() + 6);
          break;
      }
    }

    currentField.value = futureDate.toISOString().slice(0, 10);
  }

  validateAtLeastOneBatch() {
    if (this.nameFieldTargets.length === 0) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  newTextFieldControllerFromElement(element) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      'forms--new-text-field'
    );
  }
}
