import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'idField',
    'chainRulesetIdField',
    'nameField',
    'searchField',
    'searchResultItem',
    'searchResultList',
    'customChainOption',
  ];

  search() {
    const searchTerm = this.searchFieldTarget.value;
    this.filterSearchResults(searchTerm);

    if (searchTerm.length > 1) {
      this.setupCustomChainOption(searchTerm);
    } else {
      this.hideCustomChainOption();
      return;
    }
  }

  // Pressing Enter selects a custom chain option, unless the name matches a custom chain
  handleEnterClick(event) {
    if (event.key === 'Enter') {
      this.select(event);
      event.preventDefault();
      event.currentTarget.blur();
    }
  }

  hideCustomChainOption() {
    this.customChainOptionTarget.classList.add('hidden');
  }

  setupCustomChainOption() {
    const noMatchingSearchResults = !this.searchResultItemTargets.find(
      (item) =>
        this.searchFieldTarget.value.toLowerCase() ==
        item.dataset.name.toLowerCase()
    );

    if (noMatchingSearchResults) {
      this.customChainOptionTarget.classList.remove('hidden');
      this.customChainOptionTarget.textContent = `Add new blockchain "${this.searchFieldTarget.value}"`;
      this.customChainOptionTarget.dataset.name = this.searchFieldTarget.value;
    } else {
      this.customChainOptionTarget.classList.add('hidden');
    }
  }

  select(event) {
    let selectedOption;

    if (event.type === 'click') {
      selectedOption = event.currentTarget;
    } else {
      if (this.customChainOptionTarget.classList.contains('hidden')) {
        selectedOption = this.searchResultItemTargets.find(
          (item) => !item.classList.contains('hidden')
        );
      } else {
        selectedOption = this.customChainOptionTarget;
      }
    }
    this.setSelectedOption(selectedOption);
    this.hideSearchResults();
  }

  // If the user clicks outside the search results, hide them
  dismiss() {
    let selectedOption;
    if (event.type === 'click') {
      selectedOption = event.currentTarget;
    } else {
      selectedOption = this.findMatchingCuratedChainOrMakeCustom();
    }
    this.setSelectedOption(selectedOption);
  }

  findMatchingCuratedChainOrMakeCustom() {
    const curatedChain = this.searchResultItemTargets.find(
      (item) => !item.classList.contains('hidden')
    );

    if (curatedChain === undefined) {
      return this.customChainOptionTarget;
    } else {
      return curatedChain;
    }
  }

  // Temporarily force the search results to be hidden, in order to circumvent the :hover CSS selector
  hideSearchResults() {
    this.searchResultListTarget.style.display = 'none';
    setTimeout(() => (this.searchResultListTarget.style.display = ''), 50);
  }

  // Only show options that match the search term
  filterSearchResults(searchTerm) {
    this.searchResultItemTargets.forEach((item) => {
      if (item.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        item.classList.remove('hidden');
      } else {
        item.classList.add('hidden');
      }
    });
  }

  setSelectedOption(selectedOption) {
    const chainRulesetName = selectedOption.dataset.name;
    this.searchFieldTarget.value = chainRulesetName;

    if (isNaN(selectedOption.dataset.id)) {
      this.nameFieldTarget.value = chainRulesetName;
    } else {
      this.nameFieldTarget.value = chainRulesetName;
    }

    this.idFieldTarget.value = selectedOption.dataset.id;
    this.chainRulesetIdFieldTarget.value = selectedOption.dataset.id;
  }
}
