import { Controller } from 'stimulus';
import TextFieldController from '../../../lib/nakamoto/components/forms/text_field_controller';

export default class FormComponentController extends Controller {
  static targets = [
    'groupSelect',
    'categorySelect',
    'amountInput',
    'deductAllButtonWrapper',
    'deductAllButton',
  ];

  declare readonly groupSelectTarget: HTMLSelectElement;
  declare readonly categorySelectTarget: HTMLSelectElement;
  declare readonly amountInputTarget: HTMLInputElement;
  declare readonly deductAllButtonWrapperTarget: HTMLDivElement;
  declare readonly deductAllButtonTarget: HTMLInputElement;

  static values = { groupsAndAmounts: Object, maxAmount: Number, forceDisabled: Boolean };
  declare readonly groupsAndAmountsValue: { [key: string]: number };
  declare readonly maxAmountValue: number;
  declare readonly forceDisabledValue: boolean;

  // Need to grab a reference to the TextFieldController to call handleFocusInput and allow the label to animate when
  // the Deduct all is clicked
  static outlets = ['forms--new-text-field'];
  declare readonly formsNewTextFieldOutlet: TextFieldController;

  connect() {
    this.configureAmountInput();
    super.connect();
  }

  groupSelectChanged(): void {
    this.categorySelectTarget.value = 'addition'; // Reset category to 'addition' when group changes
    this.configureAmountInput();
  }

  categorySelectChanged(): void {
    this.configureAmountInput();
  }

  configureAmountInput() {
    const selectedCategory = this.categorySelectTarget.value;
    this.deductAllButtonTarget.checked = false;
    this.amountInputTarget.value = '';
    if (selectedCategory === 'addition') {
      this.amountInputTarget.placeholder = 'Enter tokens to add';
      this.amountInputTarget.max = '';
      if (!this.forceDisabledValue) {
        this.amountInputTarget.disabled = false;
        this.amountInputTarget.readOnly = false;
      }
      this.deductAllButtonWrapperTarget.classList.add('hidden');
    } else {
      const selectedGroup = this.groupSelectTarget.value;
      // Including 'deduction'
      this.amountInputTarget.placeholder = 'Enter amount to deduct';
      this.amountInputTarget.max =
        this.groupsAndAmountsValue[selectedGroup]?.toString() || '';
      this.deductAllButtonWrapperTarget.classList.remove('hidden');
    }
  }

  onDeductAllClick(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      const selectedGroup = this.groupSelectTarget.value;
      this.formsNewTextFieldOutlet.handleFocusInput();
      this.amountInputTarget.value =
        this.groupsAndAmountsValue[selectedGroup]?.toString() || '';
      this.amountInputTarget.disabled = true;
      this.amountInputTarget.readOnly = true;
    } else if (!this.forceDisabledValue) {
      this.amountInputTarget.disabled = false;
      this.amountInputTarget.readOnly = false;
    }
  }
}
