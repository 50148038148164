import { Controller } from 'stimulus';

/*
Sets the focus on the element with the id specified in the data-focus-target of the element that triggered the event.

Usage:
<div data-controller="focus">
  <div data-action="click->focus#focus" data-focus-target="focusable-element">Set focus</div>
  <input type="text" id="focusable-element" />
</div
 */
export default class extends Controller {
  focus(event?: Event): void {
    const focusTargetId = (event?.currentTarget as HTMLElement).dataset
      .focusTarget;
    if (focusTargetId) {
      const focusTarget = document.getElementById(focusTargetId);
      if (focusTarget) {
        focusTarget.focus();
      }
    }
  }
}
