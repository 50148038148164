import { Controller } from 'stimulus';
import Decimal from 'decimal.js';

export default class extends Controller {
  static targets = ['showArchivedToggle', 'unitPriceField'];

  static values = {
    offerId: String,
    unitPriceUrl: String,
    hasBatches: Boolean,
  };

  async connect() {
    await this.getUnitPrice();
  }

  async getUnitPrice() {
    const response = await fetch(
      this.unitPriceUrlValue + `?offer_id=${this.offerIdValue}&lele=lala`,
      {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }
    );
    const data = await response.json();
    this.unitPrice = data.unitPrice;
  }

  async updateLocalUnitPrice() {
    await this.getUnitPrice();
  }

  // Listener for the "Show N archived deals" toggle in the Deals list.
  showArchivedToggleChanged() {
    this.element.requestSubmit();
  }

  async handleSubmit(event) {
    const unitPrice = new Decimal(this.unitPrice || 0);
    const formValue = new Decimal(this.unitPriceFieldTarget.value || 0);
    if (
      this.hasBatchesValue &&
      !unitPrice.equals(Decimal(0)) &&
      !unitPrice.equals(formValue)
    ) {
      if (
        !window.confirm(
          'Changing the token price will recalculate the vesting schedule amounts. Proceed?'
        )
      ) {
        event.preventDefault();
      }
    }
  }
}
