import { Controller } from 'stimulus';
import { delay } from '../helpers/timing_helpers';

export default class extends Controller {
  static targets = ['hiddenSubmitButton', 'searchField', 'form'];
  static values = { lastSearchIdentifier: String, lastSearchTerm: String };

  SEARCH_COOLDOWN_TIME = 250; // ms
  MINIMUM_CHARACTER_LIMIT = 3;

  async handleNewSearchTerm() {
    let searchTerm = this.searchFieldTarget.value;
    if (
      this.searchTermIsCorrectLength(searchTerm) &&
      this.searchTermWasChanged(searchTerm)
    ) {
      this.search(searchTerm);
    }
  }

  searchTermIsCorrectLength(searchTerm) {
    return searchTerm.length >= this.MINIMUM_CHARACTER_LIMIT;
  }

  searchTermWasChanged(searchTerm) {
    return searchTerm != this.lastSearchSearchTerm;
  }

  async search(searchTerm) {
    if (await this.searchWasCancelled()) {
      return;
    }
    this.lastSearchSearchTerm = searchTerm;

    if (this.formTarget.requestSubmit) {
      this.formTarget.requestSubmit();
    } else {
      this.hiddenSubmitButtonTarget.click();
    }
  }

  async searchWasCancelled() {
    let currentSearchIdentifier = Math.random().toString().substr(2, 14);
    this.lastSearchIdentifierValue = currentSearchIdentifier;
    await delay(this.SEARCH_COOLDOWN_TIME);
    return this.lastSearchIdentifierValue != currentSearchIdentifier;
  }
}
