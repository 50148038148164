import { Token } from './token';

export enum FunctionName {
  Transfer = 'transfer',
  PresailDistribute = 'presailDistribute',
  PresailDistributeToken = 'presailDistributeToken',
  PresailDistributeTokenSimple = 'presailDistributeTokenSimple',
  PresailDeckLockTokens = 'lockTokens',
  PresailDeckClaimTokens = 'claimTokens',
}

export type PaymentMethod = {
  addressIdentifier: string;
  functionName: string;
  // TODO: Token ID should defined in the context of Compass domain (independent of Spring's domain model)
  token: Token;
};
