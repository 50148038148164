type EstimateTokenValues = {
  totalAllocationAmount: number;
  totalTokenAmount: number;
  allocationAmount?: number;
  priceAmount?: number;
};

type EstimateTokenValuesResult = {
  tokenAmount?: number;
  tokenPrice?: number;
};

export const estimateTokenValues = ({
  totalAllocationAmount,
  totalTokenAmount,
  allocationAmount,
  priceAmount,
}: EstimateTokenValues): EstimateTokenValuesResult => {
  const estimations: EstimateTokenValuesResult = {};
  if (allocationAmount) {
    estimations.tokenAmount =
      (allocationAmount / totalAllocationAmount) * totalTokenAmount;
  }
  if (priceAmount && estimations.tokenAmount) {
    estimations.tokenPrice = priceAmount / estimations.tokenAmount;
  }
  return estimations;
};

type EstimateTokenPriceValues = {
  coingeckoUnitPrice?: number;
  tokenAmount?: number;
  priceAmount?: number;
  tokenPriceAmount?: number;
};

type EstimateTokenPriceValuesResult = {
  tokenAmount?: number;
  tokenPriceAmount?: number;
  priceAmount?: number;
  tokenPriceX?: number;
};

export const estimateTokenPriceValues = ({
  coingeckoUnitPrice,
  tokenAmount,
  priceAmount,
  tokenPriceAmount,
}: EstimateTokenPriceValues): EstimateTokenPriceValuesResult => {
  const estimations: EstimateTokenPriceValuesResult = {};
  if (priceAmount && tokenAmount && !tokenPriceAmount) {
    estimations.tokenPriceAmount = priceAmount / tokenAmount;
  } else if (tokenPriceAmount) {
    estimations.tokenPriceAmount = tokenPriceAmount;
  }
  if (tokenPriceAmount && tokenAmount) {
    estimations.priceAmount = tokenPriceAmount * tokenAmount;
  } else if (priceAmount) {
    estimations.priceAmount = priceAmount;
  }
  if (!tokenAmount && estimations.priceAmount && estimations.tokenPriceAmount) {
    estimations.tokenAmount =
      estimations.priceAmount / estimations.tokenPriceAmount;
  }
  if (estimations.tokenPriceAmount && coingeckoUnitPrice) {
    estimations.tokenPriceX = Math.round(
      ((estimations.tokenPriceAmount - coingeckoUnitPrice) /
        coingeckoUnitPrice) *
        100.0
    );
  }
  return estimations;
};

export const numberToCurrency = (number: number): string => number.toFixed(2);

const calculatePrecision = (num: number): number => {
  if (num >= 100) return 0;
  else if (num >= 10) return 1;
  else if (num >= 1) return 2;
  else if (num >= 0.1) return 3;
  else if (num >= 0.01) return 4;
  else if (num >= 0.001) return 5;
  else if (num >= 0.0001) return 6;
  else if (num >= 0.00001) return 7;
  else return 8;
};

export const numberWithPrecision = (
  num: number,
  stripZeros: boolean = true
): string => {
  // Calculate the precision of the number
  const precision = calculatePrecision(num);

  // Calculate the factor to multiply the number by to get the desired precision
  const factor = Math.pow(10, precision);

  // Round the number to the desired precision
  const roundedNum = Math.round(num * factor) / factor;

  // Convert the rounded number to a string
  let formattedNum = roundedNum.toFixed(8).toString();

  // Strip insignificant zeros if requested
  if (formattedNum.includes('.') && stripZeros) {
    formattedNum = formattedNum.replace(/\.?0+$/, '');
  }

  return formattedNum;
};
