import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['daysLeft', 'hoursLeft', 'minutesLeft', 'secondsLeft'];

  static values = {
    launchDateString: String,
    objectId: String, // can be outside the controller
  };

  launchDate;
  continueCountDown;

  connect() {
    if (!this.hasLaunchDateStringValue) {
      return;
    }

    this.launchDate = Date.parse(this.launchDateStringValue.replace(/-/g, '/'));
    if (this.launchDate === undefined) {
      return;
    }

    this.continueCountDown = true;

    this.updateLabels();
    setInterval(this.updateLabels.bind(this), 1000);
  }

  updateLabels() {
    if (!this.continueCountDown) {
      return;
    }

    const secondsToLaunchDate = this.secondsToLaunchDate();
    if (secondsToLaunchDate < 0) {
      this.continueCountDown = false;

      // We should use real Stimulus targets here
      const countdownCompleteViews = document.querySelectorAll(
        `[data-countdown-complete="${this.objectIdValue}"]`
      );
      const countdownOngoingViews = document.querySelectorAll(
        `[data-countdown-ongoing="${this.objectIdValue}"]`
      );

      console.log('countdownOngoingViews', countdownOngoingViews);
      console.log('countdownCompleteViews', countdownCompleteViews);

      if (countdownCompleteViews.length > 0) {
        for (let view of countdownCompleteViews) {
          view.classList.remove('hidden');
        }

        for (let view of countdownOngoingViews) {
          view.classList.add('hidden');
        }
      }
      return;
    }
    const timeToLaunchDateComponents =
      this.timeToLaunchDateComponents(secondsToLaunchDate);
    this.daysLeftTarget.innerHTML = this.formatInt(
      timeToLaunchDateComponents.days
    );
    this.hoursLeftTarget.innerHTML = this.formatInt(
      timeToLaunchDateComponents.hours
    );
    this.minutesLeftTarget.innerHTML = this.formatInt(
      timeToLaunchDateComponents.minutes
    );
    this.secondsLeftTarget.innerHTML = this.formatInt(
      timeToLaunchDateComponents.seconds
    );
  }

  secondsToLaunchDate() {
    return (this.launchDate - Date.now()) / 1000;
  }

  timeToLaunchDateComponents(secondsToLaunchDate) {
    return {
      days: Math.floor(secondsToLaunchDate / (60 * 60 * 24)),
      hours: Math.floor(secondsToLaunchDate / (60 * 60)) % 24,
      minutes: Math.floor(secondsToLaunchDate / 60) % 60,
      seconds: Math.floor(secondsToLaunchDate) % 60,
    };
  }

  formatInt(value) {
    return value.toString().padStart(2, '0');
  }
}
