import { Controller } from 'stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  next(event: CustomEvent) {
    const fetchResponse = event.detail.fetchResponse;

    if (fetchResponse.response.redirected) {
      event.preventDefault();

      history.pushState(
        { turbo_frame_history: true },
        '',
        fetchResponse.response.url
      );
      Turbo.visit(fetchResponse.response.url);
    }
  }
}
