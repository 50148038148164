import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["elaborateField"];

  toggleElaborate(event) {
    if (event.currentTarget.value == "other") {
      this.elaborateFieldTarget.classList.remove("hidden");
    } else {
      this.elaborateFieldTarget.classList.add("hidden");
    }
  }
}
