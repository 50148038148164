import { Controller } from 'stimulus';

interface ActionsControllerI extends Controller {
  openModal: (event?: Event) => void;
}

export default class extends Controller {
  static values = {
    modalUrl: String,
  };

  declare readonly modalUrlValue: string;

  connect() {
    if (!!this.modalUrlValue) {
      (document.getElementById('modal_content') as HTMLIFrameElement).src =
        this.modalUrlValue;
      this.actionsController?.openModal();
    }
  }

  get actionsController(): ActionsControllerI | undefined {
    const actionsControllerElement =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller~=actions]') as HTMLElement,
        'actions'
      );

    if (actionsControllerElement) {
      return actionsControllerElement as ActionsControllerI;
    }
  }
}
