import { Controller } from "stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["item", "underline"];

  connect() {
    // Move and unhide the underline after setting the correct position
    this.setInitialUnderline();
  }

  change(event) {
    event.preventDefault();
    const target = event.currentTarget;
    this.updateUnderline(target);

    // Remove active classes from all items and add to the clicked one
    this.itemTargets.forEach((item) => {
      item.classList.remove("active", "border-foreground", "text-foreground")
      item.classList.add("border-transparent", "text-gray-300");
    });
    target.classList.add("active", "text-foreground", "font-medium");

    history.pushState({}, '', target.getAttribute("href"));

    // Fetch the content as a Turbo Stream
    fetch(target.getAttribute("href"), {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => console.error('Error fetching Turbo Stream:', error));
  }

  setInitialUnderline() {
    // Find the active item based on the 'active' class
    const activeItem = this.itemTargets.find((item) => item.classList.contains("active"));

    // If there's an active item, position the underline correctly
    if (activeItem) {
      // Set position and size of the underline
      this.updateUnderline(activeItem, () => {
        // Unhide the underline once positioned
        gsap.to(this.underlineTarget, { opacity: 1, duration: 0.1, delay: 0.3 });
      });
    }
  }

  updateUnderline(target = null, onComplete = null) {
    const activeItem = target || this.element.querySelector(".nav-item.active");

    if (activeItem) {
      const offset = activeItem.offsetLeft;
      const width = activeItem.offsetWidth;

      // Animate the underline position and size, then call onComplete if provided
      gsap.to(this.underlineTarget, {
        x: offset,
        width: width,
        duration: 0.1,
        onComplete: onComplete // Call the provided callback after the animation
      });
    }
  }
}
