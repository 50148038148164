import { Controller } from 'stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  static targets = ["body", "buttonContainer", "pageIndicator", "prevButton", "nextButton"];

  connect() {
    this.index = 0;
    this.pageSize = 5;
    this.fullData = [];
    this.grayColors = ['#A6A9AA', '#5e6465', '#3b4143', '#7D7D7D', '#9E9E9E'];
    this.updating = false; // Flag to track if update is in progress
    this.updatePaginationControls();
  }

  updateData(newData) {
    if (Array.isArray(newData)) {
      this.fullData = newData; // Store the full data for pagination
      this.updateView();
    } else {
      console.error('Invalid data format provided to updateData.');
    }
  }

  updateView() {
    if (this.updating) return; // Prevent updates if one is already in progress
    this.updating = true;

    const start = this.index * this.pageSize;
    const end = start + this.pageSize;
    const newItems = this.fullData.slice(start, end);

    const fragment = document.createDocumentFragment();

    // Determine number of placeholder rows needed
    const placeholderCount = Math.max(0, this.pageSize - newItems.length);

    // Add new rows or placeholder rows
    if (newItems.length === 0 && this.fullData.length === 0) {
      // If there are no items, disable buttons and hide page indicator
      this.updatePaginationControls();
      this.updating = false;
      return;
    }

    if (newItems.length === 0) {
      // Add only placeholder rows if there are no items for the current page
      for (let i = 0; i < this.pageSize; i++) {
        const placeholderRow = this.createPlaceholderRow(i);
        fragment.appendChild(placeholderRow);
      }
    } else {
      // Add actual data rows
      newItems.forEach(item => {
        const row = this.createDataRow(item);
        fragment.appendChild(row);
      });

      // Add placeholder rows if there are fewer items than the page size
      for (let i = 0; i < placeholderCount; i++) {
        const placeholderRow = this.createPlaceholderRow(newItems.length + i);
        fragment.appendChild(placeholderRow);
      }
    }

    // Animate out old rows and replace them with new rows
    const oldRows = Array.from(this.bodyTarget.querySelectorAll('tr'));
    gsap.to(oldRows, {
      opacity: 0,
      duration: 0.2,
      onComplete: () => {
        oldRows.forEach(row => row.remove());

        this.bodyTarget.appendChild(fragment);

        const newRows = Array.from(this.bodyTarget.querySelectorAll('tr'));
        gsap.fromTo(newRows, { opacity: 0 }, { opacity: 1, duration: 0.2 });

        this.updating = false; // Reset the flag after update
        this.updatePaginationControls();
      }
    });

    // Disable buttons during animation
    this.prevButtonTarget.disabled = true;
    this.nextButtonTarget.disabled = true;
  }

  createPlaceholderRow(index) {
    const row = document.createElement('tr');
    row.classList.add('border-b-[1px]', 'border-gray-600', 'hover:bg-gray-600/20', 'cursor-pointer');
    row.dataset.tablePaginationTarget = 'item';

    const color = this.grayColors[index % this.grayColors.length];

    row.innerHTML = `
      <td class="py-4 px-[1.67rem]">
        <div class="w-full h-4 rounded-full" style="background-color: ${color}"></div>
      </td>
      <td class="py-4 px-[1.67rem]">
        <div class="w-full h-4 rounded-full" style="background-color: #1C2A2D"></div>
      </td>
      <td class="py-4 px-[1.67rem] hidden sm:table-cell">
        <div class="w-full h-4 rounded-full" style="background-color: #1C2A2D"></div>
      </td>
      <td class="py-4 px-[1.67rem] text-right hidden sm:table-cell">
        <div class="w-full h-4 rounded-full" style="background-color: #1C2A2D"></div>
      </td>
    `;

    return row;
  }

  createDataRow(item) {
    const row = document.createElement('tr');
    row.classList.add('border-b-[1px]', 'border-gray-600', 'hover:bg-gray-600/20', 'cursor-pointer');
    row.dataset.tablePaginationTarget = 'item';
    row.dataset.controller = 'links';
    row.dataset.action = 'click->links#goToLink';
    row.dataset.href = item.link;

    const formattedInvestedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(item.invested_amount);

    row.innerHTML = `
    <td class="py-4 px-[1.67rem]">
      <div class="flex items-center gap-4">
        <div class="w-4 h-4 rounded shrink-0" style="background-color: ${item.background_color}"></div>
        <span class="max-w-[160px] sm:max-w-[300px] overflow-hidden whitespace-nowrap text-ellipsis">${item.name}</span>
      </div>
    </td>
    <td class="py-4 px-[1.67rem]">
      ${item.active ? `<div class="flex items-center justify-center bg-[#0C5580] rounded-[0.3125rem] py-1 px-3 w-[5.685rem] h-6 text-sm text-[#DBF2FF]">Active</div>` :
      `<div class="flex items-center justify-center bg-[#1C2A2D] rounded-[0.3125rem] py-1 px-3 w-[5.685rem] h-6 text-sm text-[#A6A9AA]">Closed</div>`}
    </td>
    <td class="py-4 px-[1.67rem] hidden sm:table-cell">${item.share}%</td>
    <td class="py-4 px-[1.67rem] text-right hidden sm:table-cell"><p>${formattedInvestedAmount}</p></td>
  `;

    return row;
  }

  updatePaginationControls() {
    const totalItems = this.fullData.length;
    const totalPages = Math.ceil(totalItems / this.pageSize);

    if (totalPages === 0) {
      // Hide page indicator and disable buttons if no pages
      this.pageIndicatorTarget.classList.add('hidden');
      this.prevButtonTarget.disabled = true;
      this.nextButtonTarget.disabled = true;
    } else {
      this.pageIndicatorTarget.classList.remove('hidden');
      this.pageIndicatorTarget.textContent = totalPages === 1 ? '' : `${this.index + 1} of ${totalPages}`;
      this.prevButtonTarget.disabled = this.index === 0;
      this.nextButtonTarget.disabled = (this.index + 1) * this.pageSize >= totalItems;
    }
  }

  previous() {
    if (this.index > 0) {
      this.index--;
      this.updateView();
    }
  }

  next() {
    const totalItems = this.fullData.length;
    if ((this.index + 1) * this.pageSize < totalItems) {
      this.index++;
      this.updateView();
    }
  }
}
