import gsap from 'gsap';

// When a new animation is added in tailwind.config.js, it must be added here as well.
type ANIMATION_TYPES = 'fade' | 'modal' | 'accordion';
type style = 'hidden' | 'height';

// Blog explanation on how this works if interested https://sebastiandedeyne.com/javascript-framework-diet-enter-leave-transitions
export async function animate(
  element: HTMLElement,
  type: 'enter' | 'leave',
  transition: ANIMATION_TYPES,
  style: style = 'hidden'
) {
  if (type === 'enter') {
    element.classList.remove('hidden');
    if (style === 'height') {
      element.classList.remove('h-0');
    }
  }

  element.classList.add(`${transition}-${type}`);
  element.classList.add(`${transition}-${type}-start`);

  await nextFrame();

  element.classList.remove(`${transition}-${type}-start`);
  element.classList.add(`${transition}-${type}-active`);

  await afterTransition(element);

  element.classList.remove(`${transition}-${type}-active`);
  element.classList.remove(`${transition}-${type}`);

  if (type === 'leave') {
    element.classList.add('hidden');
    if (style === 'height') {
      element.classList.add('h-0');
    }
  }
}

function nextFrame() {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve);
  });
}

function afterTransition(element: HTMLElement) {
  return new Promise((resolve) => {
    const computedStyle = getComputedStyle(element);
    const transitionDuration = computedStyle.transitionDuration;
    const durationValue = parseFloat(transitionDuration);
    const durationUnit = transitionDuration.replace(
      durationValue.toString(),
      ''
    );
    const durationInMs =
      durationUnit === 'ms' ? durationValue : durationValue * 1000;

    setTimeout(() => {
      resolve(true);
    }, durationInMs);
  });
}

export function getHiddenHeight(el: any) {
  if (!el?.cloneNode) {
    return null;
  }

  const clone = el.cloneNode(true);

  Object.assign(clone.style, {
    overflow: 'visible',
    height: 'auto',
    maxHeight: 'none',
    opacity: '0',
    visibility: 'hidden',
    display: 'block',
  });

  el.after(clone);
  const height = clone.offsetHeight;

  clone.remove();

  return height;
}
/**
 * Helper function to show an element that you are unsure if it is hidden or not
 * @param element
 */
export async function showElement(element: HTMLElement): Promise<void> {
  if (element.classList.contains('hidden')) {
    await toggleVisibility(element);
  }
}

/**
 * Helper function to hide an element that you are unsure if it is hidden or not
 * @param element
 */
export async function hideElement(element: HTMLElement): Promise<void> {
  if (!element.classList.contains('hidden')) {
    await toggleVisibility(element);
  }
}

/**
 * Helper function to toggle visibility of an element that takes into consideration styles
 * and correctly animates the element in and out of the dom without layout shifts
 * @param element
 */
export async function toggleVisibility(element: HTMLElement): Promise<void> {
  return new Promise((resolve) => {
    // Check if we need to initialize properties storage
    // There can be a layout shift bug if we don't set margin and padding to 0 when hiding
    if (!element.dataset.originalStyles) {
      // Store original padding and margin values
      element.dataset.originalStyles = JSON.stringify({
        paddingTop: element.style.paddingTop,
        paddingBottom: element.style.paddingBottom,
        marginTop: element.style.marginTop,
        marginBottom: element.style.marginBottom,
      });
    }

    // Check if the element is currently hidden
    if (element.classList.contains('hidden')) {
      // Prepare the element for animation
      element.classList.remove('hidden');
      element.style.visibility = 'visible';
      element.style.overflow = 'hidden'; // Ensure content does not overflow during animation

      // Retrieve original padding and margin
      const originalStyles: {
        paddingTop: string;
        paddingBottom: string;
        marginTop: string;
        marginBottom: string;
      } = JSON.parse(element.dataset.originalStyles!);

      // Temporarily set height to auto to calculate the natural height
      element.style.height = 'auto';
      const autoHeight = element.offsetHeight; // Capture natural height
      element.style.height = '0'; // Reset height to animate from 0

      // Animate to visible
      gsap.to(element, {
        duration: 0.3,
        height: autoHeight,
        paddingTop: originalStyles.paddingTop,
        paddingBottom: originalStyles.paddingBottom,
        marginTop: originalStyles.marginTop,
        marginBottom: originalStyles.marginBottom,
        opacity: 1,
        ease: 'power2.out',
        onComplete: () => {
          element.style.height = 'auto'; // Set height to auto after animation completes
          element.style.overflow = 'visible'; // Allow overflow after animation
          resolve();
        },
      });
    } else {
      // Element is currently shown, prepare to hide
      // Start by setting overflow hidden to avoid content spillover
      element.style.overflow = 'hidden';

      // Animate to hidden
      gsap.to(element, {
        duration: 0.3,
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        ease: 'power2.in',
        onComplete: () => {
          element.style.visibility = 'hidden';
          element.classList.add('hidden');
          resolve();
        },
      });
    }
  });
}
