export enum StatusType {
  loading = 0,
  info,
  success,
  warning,
  error
}

/*
 * Represents a status as something that can be shown to the user.
 * It does not necessarily correspond to the payment or payment intent status, but rather to an "abstract" status of
 * the operation as relevant to the user.
 */
export type Status = {
  title: string;
  statusType: StatusType;
  message?: string;
  linkText?: string;
  linkUrl?: URL
}
