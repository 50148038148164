import { Controller } from "stimulus";
import gsap from "gsap";

export default class extends Controller {
  static targets = ["content"];
  static values = {
    ellipsis: { type: Boolean, default: false },
    hoisted: { type: Boolean, default: false },
    hoistedId: { type: String, default: "" },
  }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
    this._currentTooltip = this.contentTarget;
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
    if (this._clonedTooltip) {
      this._clonedTooltip.remove();
    }
  }

  show(event) {
    if (this.ellipsisValue && !this.isEllipsisActive(this.element.firstElementChild)) {
      return;
    }
    if (this.hoistedValue) {
      this._cloneTooltip();
    } else {
      this._currentTooltip = this.contentTarget;
    }
    this.setPosition();
    this._currentTooltip.classList.remove('hidden');
    this.setupAnimation();
    this.animation.play();
  }

  hide(event) {
    if (this.animation) {
      this.animation.reverse();
      this.animation.eventCallback("onReverseComplete", () => {
        this._currentTooltip.classList.add('hidden');
        if (this.hoistedValue && this._clonedTooltip) {
          this._clonedTooltip.remove();
          this._clonedTooltip = null;
          this._currentTooltip = this.contentTarget;
        }
      });
    }
  }

  toggle(event) {
    event.stopPropagation();
    if (this._currentTooltip.classList.contains('hidden')) {
      this.show();
    } else {
      this.hide();
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target) && !this._currentTooltip.classList.contains('hidden')) {
      this.hide();
    }
  }

  setPosition() {
    let position = this.element.dataset.tailwindTooltipPosition;
    if (!position) position = 'above';

    this._currentTooltip.classList.remove('top-full', 'bottom-full', 'mt-2', 'mb-2', 'left-1/2', '-translate-x-1/2', 'right-full', 'mr-2', 'left-full', 'ml-2', '-translate-y-1/2', 'top-1/2');

    switch (position) {
      case 'above':
        this._currentTooltip.classList.add('bottom-full', 'mb-2', 'left-1/2', '-translate-x-1/2');
        break;
      case 'below':
        this._currentTooltip.classList.add('top-full', 'mt-2', 'left-1/2', '-translate-x-1/2');
        break;
      case 'left':
        this._currentTooltip.classList.add('right-full', 'mr-2', 'top-1/2', '-translate-y-1/2');
        break;
      case 'right':
        this._currentTooltip.classList.add('left-full', 'ml-2', 'top-1/2', '-translate-y-1/2');
        break;
    }
  }

  setupAnimation() {
    let position = this.element.dataset.tailwindTooltipPosition;
    let fromVars, toVars;

    switch (position) {
      case 'above':
        fromVars = { opacity: 0, y: -10 };
        toVars = { opacity: 1, y: 0 };
        break;
      case 'below':
        fromVars = { opacity: 0, y: 10 };
        toVars = { opacity: 1, y: 0 };
        break;
      case 'left':
        fromVars = { opacity: 0, x: -10 };
        toVars = { opacity: 1, x: 0 };
        break;
      case 'right':
        fromVars = { opacity: 0, x: 10 };
        toVars = { opacity: 1, x: 0 };
        break;
      default:
        fromVars = { opacity: 0, y: -10 };
        toVars = { opacity: 1, y: 0 };
        break;
    }

    this.animation = gsap.timeline({ paused: true })
      .fromTo(this._currentTooltip, fromVars, { ...toVars, duration: 0.3, ease: "power1.out" });
  }

  isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  _cloneTooltip() {
    this._clonedTooltip = this.contentTarget.cloneNode(true);
    let hoistTarget = document.getElementById(this.hoistedIdValue) || document.body;
    hoistTarget.appendChild(this._clonedTooltip);
    this._setTooltipPosition(this._clonedTooltip);
    this._currentTooltip = this._clonedTooltip;
  }

  _setTooltipPosition(tooltip) {
    const rect = this.element.getBoundingClientRect();
    const contentRect = tooltip.getBoundingClientRect();

    tooltip.style.position = 'absolute';

    switch (this.element.dataset.tailwindTooltipPosition) {
      case 'above':
        tooltip.style.top = `${rect.top - contentRect.height}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
      case 'below':
        tooltip.style.top = `${rect.bottom}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
      case 'left':
        tooltip.style.top = `${rect.top + rect.height / 2 - contentRect.height / 2}px`;
        tooltip.style.left = `${rect.left - contentRect.width}px`;
        break;
      case 'right':
        tooltip.style.top = `${rect.top + rect.height / 2 - contentRect.height / 2}px`;
        tooltip.style.left = `${rect.right}px`;
        break;
      default:
        tooltip.style.top = `${rect.top - contentRect.height}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - contentRect.width / 2}px`;
        break;
    }
  }
}
