import { Controller } from 'stimulus';
import EmblaCarousel from 'embla-carousel'
import AutoScroll from 'embla-carousel-auto-scroll'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

class EmblaCarouselController extends Controller {
  // Info for each option that can be passed https://www.embla-carousel.com/api/options/#constructor-options
  static values = {
    autoplay: {type: Boolean, default: false}, // This is a plugin and not found in default options
    autoplayDelay: {type: Number, default: 3000}, // This is for autoplay plugin and not found in default options
    autoplayPlayOnInit: {type: Boolean, default: true}, // This is for autoplay plugin and not found in default options
    stopOnHover: {type: Boolean, default: false},
    active: {type: Boolean, default: true},
    align: {type: String, default: 'start'},
    axis: {type: String, default: 'x'},
    containScroll: {type: String, default: 'trimSnaps'},
    direction: {type: String, default: 'ltr'},
    dragFree: {type: Boolean, default: false},
    dragThreshold: {type: Number, default: 10},
    duration: {type: Number, default: 25},
    inViewThreshold: {type: Number, default: 0},
    loop: {type: Boolean, default: false },
    skipSnaps: {type: Boolean, default: false},
    slidesToScroll: {type: String, default: 'auto'},
    startIndex: {type: Number, default: 0},
    watchDrag: {type: Boolean, default: true},
    watchResize: {type: Boolean, default: true},
    watchSlides: {type: Boolean, default: true},
  }
  static targets = ['carousel', 'viewport', 'container', 'slides'];

  connect() {
    if(!this.hasCarouselTarget || !this.hasViewportTarget || !this.hasContainerTarget) return console.error('Carousel is missing stimulus targets requires (carousel, viewport, container)');

    //Enable plugins
    const plugins = []
    if(this.autoplayValue) plugins.push(AutoScroll({ playOnInit: true, stopOnInteraction: false, stopOnMouseEnter: true, startDelay: 500, speed: 1 }));
    plugins.push(WheelGesturesPlugin())

    this.emblaApi = EmblaCarousel(this.viewportTarget, {
      loop: this.loopValue,
      dragFree: this.dragFreeValue,
      align: this.alignValue,
      containScroll: this.containScrollValue,
    }, plugins);
  }
}

export default EmblaCarouselController;
