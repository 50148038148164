import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'currentNumber',
    'currentCapAmount',
    'fundraisingStat',
    'currentPercentage',
    'projectedPercentage',
    'currentBar',
    'projectedBar',
    'percentageDisplay',
    'capAmount',
    'realTimeWrapper',
  ];

  connect() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((_mutation) => {
        this.updateFundraisingProgress();
      });
    });

    this.observer.observe(this.realTimeWrapperTarget, { childList: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  updateFundraisingProgress() {
    const fundraisingStat = this.currentNumberTarget.innerHTML;
    const currentCapAmount = this.currentCapAmountTarget.innerHTML;
    const currentPercentage = this.currentPercentageTarget.innerHTML;
    const projectedPercentage = this.projectedPercentageTarget.innerHTML;

    this.fundraisingStatTarget.innerHTML = fundraisingStat;

    if (this.hasPercentageDisplayTarget) {
      this.percentageDisplayTarget.innerHTML = currentPercentage;
    }

    if (this.hasCapAmountTarget) {
      this.capAmountTarget.innerHTML = currentCapAmount;
    }

    if (this.hasCurrentBarTarget) {
      this.currentBarTarget.style.width = currentPercentage;
    }

    if (this.hasProjectedBarTarget) {
      this.projectedBarTarget.style.width = `calc(16px + ${projectedPercentage})`;
    }
  }
}
