import { Controller } from 'stimulus';
import { delay } from '../helpers/timing_helpers';

export default class extends Controller {
  static values = { waitSeconds: Number };

  async connect() {
    await delay(this.waitSecondsValue * 1000);
    this.appear();
  }

  appear() {
    this.element.classList.remove('hidden');
  }
}
