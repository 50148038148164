import { IPaymentIntent } from '../model/payment_intent';
import { Payable } from '../model/payable';
import { Web3ConfigInfo } from '../core/compass_api';
import Bugsnag from '@bugsnag/js';

export type ReportParams = {
  message: string;
  paymentIntent?: IPaymentIntent;
  payable?: Payable;
  web3ConfigInfo?: Web3ConfigInfo;
};
export function bugsnagReport({
  message,
  paymentIntent,
  payable,
  web3ConfigInfo,
}: ReportParams): void {
  Bugsnag.notify(message, (event) => {
    if (paymentIntent) {
      event.addMetadata('paymentIntent', {
        guid: paymentIntent?.guid,
        amount: paymentIntent?.amount,
        addressIdentifier: paymentIntent?.paymentMethod.addressIdentifier,
        functionName: paymentIntent?.paymentMethod.functionName,
        token: paymentIntent?.paymentMethod.token.addressIdentifier,
        chain: paymentIntent?.paymentMethod.token.chain.id,
      });
    }
    if (payable) {
      event.addMetadata('payable', {
        guid: payable.guid,
        modelName: payable.modelName,
        acceptedPaymentMethod: payable.acceptedPaymentMethod,
        minAmount: payable.minAmount,
        maxAmount: payable.maxAmount,
        incrementAmount: payable.incrementAmount,
      });
    }
    if (web3ConfigInfo) {
      event.addMetadata('web3config', {
        account: {
          address: web3ConfigInfo.account.address,
          status: web3ConfigInfo.account.status,
        },
        chain: web3ConfigInfo.chain.name,
        connector: {
          id: web3ConfigInfo.connector.id,
          name: web3ConfigInfo.connector.name,
          ready: web3ConfigInfo.connector.ready,
        },
      });
    }
  });
}
