import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { gsap } from 'gsap'

export default class extends Controller {
  static targets = ['menu', 'button', 'icon']
  static values = {
    hoisted: { type: Boolean, default: false },
    hoistedId: { type: String, default: '' },
  }
  originalParent = null;
  clonedMenu = null;

  connect () {
    this.dropdownOpen = false
    this.lastActiveElement = null

    useClickOutside(this, this.close)

    // You may need to hoist the dropdown menu if it is in a container with overflow: hidden
    if (this.hoistedValue) {
      this.originalParent = this.menuTarget.parentElement;
      const hoistedElement = this.hoistedIdValue ? document.getElementById(this.hoistedIdValue) : document.body;

      // Clone the menu and append it to the hoisted element
      this.clonedMenu = this.menuTarget.cloneNode(true);
      hoistedElement.appendChild(this.clonedMenu);
    }
  }

  toggle (e) {
    e.stopPropagation();
    this.dropdownOpen ? this.close(e) : this.open(e)
  }

  open(e) {
    e.preventDefault()

    if(this.hasIconTarget) {
      this.iconTarget.classList.add('rotate-180')
    }

    this.lastActiveElement = document.activeElement;
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.dropdownOpen = true

    if (this.hoistedValue) {
      this.repositionMenu()
      // Animate the cloned menu in
      gsap.fromTo(this.clonedMenu, { opacity: 0, scale: 0.95 }, { opacity: 1, scale: 1, duration: 0.2, onStart: () => {
          if (this.clonedMenu) {
            this.clonedMenu.classList.remove('hidden');
          }
        }});
    } else {
      // Animate the menuTarget in
      gsap.fromTo(this.menuTarget, { opacity: 0, scale: 0.95 }, { opacity: 1, scale: 1, duration: 0.2, onStart: () => {
          this.menuTarget.classList.remove('hidden');
        }});
    }
  }

  close(e) {
    e.preventDefault()

    if(this.hasIconTarget) {
      this.iconTarget.classList.remove('rotate-180')
    }

    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.dropdownOpen = false

    if (this.hoistedValue) {
      // Animate the cloned menu out
      gsap.to(this.clonedMenu, { opacity: 0, scale: 0.95, duration: 0.2, onComplete: () => {
          if (this.clonedMenu) {
            this.clonedMenu.classList.add('hidden');
            this.clonedMenu.style.top = 0;
            this.clonedMenu.style.left = 0;
          }
        }});
    } else {
      // Animate the menuTarget out
      gsap.to(this.menuTarget, { opacity: 0, scale: 0.95, duration: 0.2, onComplete: () => {
          this.menuTarget.classList.add('hidden');
        }});
    }
  }

  repositionMenu() {
    if (!this.dropdownOpen) {
      return;
    }

    // Temporarily show the cloned menu to get its bounding rectangle
    this.clonedMenu.style.visibility = 'hidden';
    this.clonedMenu.classList.remove('hidden');

    // Calculate the top and left values based on the position of the buttonTarget element
    const buttonRect = this.buttonTarget.getBoundingClientRect();
    const menuRect = this.clonedMenu.getBoundingClientRect();
    let top = buttonRect.bottom + window.scrollY;
    let left = buttonRect.right - menuRect.width;

    this.clonedMenu.style.visibility = '';
    this.clonedMenu.classList.add('hidden');

    // Check if the cloned menu is clipped by the left side of the screen
    if (left < 0) {
      left = 0;
    }

    this.clonedMenu.style.top = `${top}px`;
    this.clonedMenu.style.left = `${left}px`;
  }
}
