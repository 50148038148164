import { Controller } from 'stimulus';
import { estimateTokenPriceValues, numberWithPrecision } from './utils';
import debounce from 'debounce';

export default class extends Controller {
  static targets = [
    'tokenAmountField',
    'priceAmountField',
    'tokenPriceAmountField',
    'tokenPrice',
    'tokenPriceX',
  ];

  declare readonly tokenAmountFieldTarget: HTMLInputElement;
  declare readonly priceAmountFieldTarget: HTMLInputElement;
  declare readonly tokenPriceAmountFieldTarget: HTMLInputElement;
  declare readonly tokenPriceXTarget: HTMLSpanElement;
  declare readonly hasTokenAmountFieldTarget: boolean;
  declare readonly hasPriceAmountFieldTarget: boolean;
  declare readonly hasTokenPriceAmountFieldTarget: boolean;
  declare readonly hasTokenPriceXTarget: boolean;

  static values = {
    coingeckoUnitPrice: Number,
    tokenTicker: String,
    contributableTicker: String,
  };

  declare readonly coingeckoUnitPriceValue: number;
  declare readonly tokenTickerValue: string;
  declare readonly contributableTickerValue: string;
  declare readonly hasCoingeckoUnitPriceValue: boolean;

  initialize() {
    this.estimate = debounce(this.estimate.bind(this), 300);
  }

  async estimate(e: any) {
    e.preventDefault();

    this.setValues(e.target);
  }

  async setValues(target: HTMLInputElement) {
    const values = {
      coingeckoUnitPrice: this.coingeckoUnitPriceValue,
      tokenAmount: parseFloat(this.tokenAmountFieldTarget.value),
      priceAmount: parseFloat(this.priceAmountFieldTarget.value),
      tokenPriceAmount: parseFloat(this.tokenPriceAmountFieldTarget.value),
    };
    if (
      !!values.priceAmount &&
      !!values.tokenPriceAmount &&
      (target === this.tokenPriceAmountFieldTarget ||
        target === this.priceAmountFieldTarget)
    ) {
      values.tokenAmount = 0;
    }
    const { tokenPriceAmount, tokenAmount, priceAmount, tokenPriceX } =
      estimateTokenPriceValues(values);

    if (
      target !== this.tokenAmountFieldTarget &&
      this.hasTokenAmountFieldTarget &&
      !!tokenAmount
    ) {
      this.tokenAmountFieldTarget.value = numberWithPrecision(tokenAmount);
    }

    if (
      target !== this.priceAmountFieldTarget &&
      this.hasPriceAmountFieldTarget &&
      !!priceAmount
    ) {
      this.priceAmountFieldTarget.value = numberWithPrecision(priceAmount);
    }

    if (
      target !== this.tokenPriceAmountFieldTarget &&
      this.hasTokenPriceAmountFieldTarget &&
      !!tokenPriceAmount
    ) {
      this.tokenPriceAmountFieldTarget.value =
        numberWithPrecision(tokenPriceAmount);
    }
    if (this.hasTokenPriceXTarget && tokenPriceX) {
      let sign = '';
      if (tokenPriceX > 0) {
        sign = '+';
      } else if (tokenPriceX < 0) {
        sign = '';
      }
      this.tokenPriceXTarget.textContent = ` (${sign}${tokenPriceX}%)`;
    }
  }
}
