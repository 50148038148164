import { Controller } from "stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["list", "loadMoreButton"];
  static values = { offset: Number, limit: Number };

  connect() {
    this.setupAdditionObserver();
    document.addEventListener("turbo:before-stream-render", this.handleTurboStream.bind(this));
  }

  disconnect() {
    if (this.additionObserver) {
      this.additionObserver.disconnect();
    }
    document.removeEventListener("turbo:before-stream-render", this.handleTurboStream.bind(this));
  }

  setupAdditionObserver() {
    this.additionObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedElements = Array.from(mutation.addedNodes).filter(node => node.nodeType === Node.ELEMENT_NODE);
          gsap.from(addedElements, {
            opacity: 0,
            y: -20,
            height: 0,
            duration: 0.3,
            ease: "power2.out",
            stagger: 0.1 // Delay each child by 0.1 seconds
          });
        }
      }
    });

    this.additionObserver.observe(this.listTarget, { childList: true });
  }

  handleTurboStream(event) {
    const action = event.target.getAttribute("action");
    const elementId = event.target.getAttribute("target");

    if (action !== "remove" || !elementId.startsWith("list_item_")) {
      return;
    }

    // Prevent Turbo from immediately removing the element, this needs to be below the above if statement to not block other actions
    event.preventDefault();

    const element = document.getElementById(elementId);
    if (!element) {
      return console.error(`Element with id ${elementId} not found in load_more_schedules_controller.js`);
    }

    const siblingElements = Array.from(this.listTarget.children);
    const index = siblingElements.indexOf(element);

    element.style.overflow = 'hidden';

    gsap.to(element, {
      opacity: 0,
      height: 0,
      marginBottom: 0,
      padding: 0,
      borderWidth: 0,
      duration: 0.3,
      ease: "power2.in",
      onComplete: () => {
        element.remove();
      }
    });

    if (this.hasLoadMoreButtonTarget && this.offsetValue > 0) {
      this.offsetValue -= 1;
    }
  }

  loadMoreDeals(event) {
    const offset = this.offsetValue;
    const limit = this.limitValue;

    fetch(`/partner/deals_with_no_schedule/${offset}/${limit}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(html => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;

        const newItems = Array.from(tempDiv.children);

        newItems.forEach(item => {
          this.listTarget.appendChild(item);
        });
        if (this.hasLoadMoreButtonTarget) {
          this.loadMoreButtonTarget.remove();
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
}
