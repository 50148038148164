import { Chain } from '../model/chain';

export function explorerUrl(
  chain: Chain,
  txIdentifier: string
): URL | undefined {
  if (chain.explorerUrlPattern) {
    return new URL(
      chain.explorerUrlPattern.replace('${tx_identifier}', txIdentifier)
    );
  }
}
