import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['acceptedCurrenciesCheckbox', "atLeastOneAcceptedCurrencyError"];

    declare readonly acceptedCurrenciesCheckboxTargets: [HTMLInputElement];
    declare readonly atLeastOneAcceptedCurrencyErrorTarget: HTMLLabelElement;

    validateAndSubmitForm(event: Event): void {
        const isAnyCheckboxSelected = this.acceptedCurrenciesCheckboxTargets.some((checkbox) => checkbox.checked);
        if (!isAnyCheckboxSelected) {
            event.preventDefault();
            this.atLeastOneAcceptedCurrencyErrorTarget.classList.remove('hidden');
        } else {
            this.atLeastOneAcceptedCurrencyErrorTarget.classList.add('hidden');
        }
    }
}


