export function walletShortname(addressIdentifier: string): string {
  const leadingCharactersLength =
    addressIdentifier.substring(0, 2) === '0x' ? 6 : 4;

  return `${addressIdentifier.substring(
    0,
    leadingCharactersLength
  )}...${addressIdentifier.substring(
    addressIdentifier.length - 4,
    addressIdentifier.length
  )}`;
}
