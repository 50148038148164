/**
 * @typedef {import('../tables/multi_select_controller').default} MultiSelectController
 */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'hiddenForm', 'countSpan', 'recordIdsInput'];

  connect() {
    this.disableButton();

    document.addEventListener(
      'selectCountChanged',
      this.handleSelectCountChanged.bind(this)
    );
  }

  /**
   * @param e CustomEvent
   */
  submitForm(e) {
    e.preventDefault();

    const selectedValues = this.getSelectedValues();

    this.setHiddenFormValues(selectedValues);
    this.submit();
  }

  submit() {
    if (this.hiddenFormTarget.requestSubmit) {
      this.hiddenFormTarget.requestSubmit();
    } else {
      const submitButton = this.hiddenFormTarget.querySelector(
        '[type="submit"]'
      );
      if (submitButton) {
        submitButton.click();
      } else {
      }
    }
  }

  /**
   * @param e CustomEvent
   */
  handleSelectCountChanged(e) {
    const newCount = e.detail;

    this.buttonTarget.disabled = newCount === 0;
    const countSpan = this.countSpanTarget;
    if (newCount > 0) {
      this.buttonTarget.classList.add('active');
      if (countSpan) {
        countSpan.innerHTML = `(${newCount})`;
        countSpan.classList.remove('hidden');
      }
    } else {
      this.buttonTarget.classList.remove('active');
      if (countSpan) {
        countSpan.innerHTML = '';
        countSpan.classList.add('hidden');
      }
    }
  }

  /**
   * @param values string[]
   */
  setHiddenFormValues(values) {
    this.recordIdsInputTarget.value = values.join(',');
  }

  disableButton() {
    this.buttonTarget.disabled = true;
  }

  /**
   * @returns {string[]}
   */
  getSelectedValues() {
    const multiSelectController = this.multiSelectController;
    if (multiSelectController) {
      return multiSelectController.getSelectedRecordIds();
    }

    return [];
  }

  /**
   * @returns {MultiSelectController|null}
   */
  get multiSelectController() {
    const element = this.element.closest(
      '[data-controller~=tables--multi-select]'
    );
    if (!element) return null;

    return this.application.getControllerForElementAndIdentifier(
      element,
      'tables--multi-select'
    );
  }
}
