import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container'
  ];

  connect() {
    if (!this.hasContainerTarget) {
      return console.error('No container target found for draggable controller');
    }
    this.pos = { top: 0, left: 0, x: 0, y: 0 };
    this.boundMouseDownHandler = this.mouseDownHandler.bind(this);
    this.boundMouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.boundMouseUpHandler = this.mouseUpHandler.bind(this);

    this.containerTarget.addEventListener('mousedown', this.boundMouseDownHandler);
  }

  mouseDownHandler = (e) => {
    if(!this.hasContainerTarget) {
      return console.error('No container target found');
    }

    this.pos = {
      // The current scroll
      left: this.containerTarget.scrollLeft,
      top: this.containerTarget.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', this.boundMouseMoveHandler);
    document.addEventListener('mouseup', this.boundMouseUpHandler);
  }

  mouseMoveHandler = function (e) {
    if(!this.hasContainerTarget) {
      return console.error('No container target found');
    }
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.containerTarget.scrollTop = this.pos.top - dy;
    this.containerTarget.scrollLeft = this.pos.left - dx;
  };

  mouseUpHandler = function () {
    if(!this.hasContainerTarget) {
      return console.error('No container target found');
    }

    this.containerTarget.style.cursor = 'grab';
    this.containerTarget.style.removeProperty('user-select');

    document.removeEventListener('mousemove', this.boundMouseMoveHandler);
    document.removeEventListener('mouseup', this.boundMouseUpHandler);
  };
}
