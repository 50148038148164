import { Controller } from "stimulus";
import Persona from 'persona';

export default class extends Controller {
  static values = { reference: String, inq: String, templateId: String };

  static targets = ["kycBtn", "kycErrorText"];

  connect() {
    if (this.inqValue != "") {
      this.kycBtnTarget.innerHTML = "Continue KYC Process";
    }
  }

  async open() {
    this.kycErrorTextTarget.classList.add("hidden");
    const client = new Persona.Client({
      inquiryId: this.inqValue,
      templateId: this.inqValue == '' ? this.templateIdValue : null, // only submit a templateId if there is no inquiryId
      referenceId: this.referenceValue,
      environment: "production",
      onComplete: ({ inquiryId, status, fields }) => {
        console.log("KYC complete", inquiryId, status, fields);
        this.inqValue = inquiryId;
        this.updateKyc({ inquiry_id: inquiryId }).then(() => {
          window.location.reload();
        });
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        console.log("KYC canceled", inquiryId, sessionToken);
        this.inqValue = inquiryId;
        this.updateKyc({ inquiry_id: inquiryId, session_token: sessionToken });
        document.getElementById("kyc-status").innerHTML = "Pending";
      },
      onError: (error) => {
        console.log("KYC error", error.code, error.message)
        this.updateKyc({
          error_message: error.code,
          inquiry_id: this.inqValue
        });
      }
    });
    client.open();
  }

  async updateKyc(data) {
    if (this.inqValue == "") {
      console.log("No inquiry ID");
      return;
    } else {
      await fetch(`/kycs/id_verifications/${this.inqValue}`, {
        method: "PATCH",
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]")
            .content,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then((response) => {
        if (!response.ok) {
          this.kycErrorTextTarget.classList.remove("hidden");
        }
      });
    }
  }
}
