import { Controller } from "stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  connect() {
    this.handleScroll = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.handleScroll, { once: true })

    gsap.set(this.element, {
      opacity: 1,
      y: 0
    })
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    gsap.to(this.element, {
      opacity: 0,
      y: 20,
      duration: 0.5,
      ease: "power2.out"
    })
  }
}
