import { Controller } from 'stimulus';
import { hideElement, showElement } from '@/helpers/animations_helpers';

/**
 * @property {boolean} hasCheckboxContainerTarget
 * @property {HTMLElement} checkboxContainerTarget
 * @property {HTMLElement} checkboxTarget
 * @property {HTMLElement[]} radioTargets
 */

export default class extends Controller {
  static targets = ['checkboxContainer', 'checkbox', 'radio'];

  connect() {
    if (!this.hasCheckboxContainerTarget) {
      return
    }

    this.radioTargets.forEach((radio) => {
      if (radio.checked && radio.value === 'approval') {
        this.checkboxContainerTarget.classList.remove('hidden');
      }
    })
  }

  toggle(event) {
    event.preventDefault();
    if (!this.hasCheckboxContainerTarget) {
      return
    }

    if(event.currentTarget.value === 'approval') {
      void showElement(this.checkboxContainerTarget);
    } else {
      void hideElement(this.checkboxContainerTarget);
      this.checkboxTarget.checked = false
    }
  }
}
