import { Controller } from 'stimulus';
import { hideElement, showElement } from '@/helpers/animations_helpers';

export default class extends Controller {
  static targets = ['card', 'addButton', 'deleteButton', 'title', 'maxAlert'];

  connect() {
    this.deleteButtonTargets.forEach((deleteButton, index) => {
      if (index !== 0) {
        deleteButton.classList.remove('hidden');
      }
    });

    this.updateAddButtonAndMaxAlert();
  }

  async addRule(event) {
    if (this.cardTargets.length >= 5) return;

    // Clone the first card
    const firstCard = this.cardTargets[0];
    const newCard = firstCard.cloneNode(true);

    newCard.classList.add('hidden');

    // Update the index in the cloned card
    const newIndex = this.cardTargets.length;
    newCard.querySelectorAll('input, select, label').forEach(el => {
      if (el.name) {
        el.name = el.name.replace(/\[\d+\]/, `[${newIndex}]`);
      }
      if (el.id) {
        el.id = el.id.replace(/_\d+_/, `_${newIndex}_`);
      }
      if (el.htmlFor) {
        el.htmlFor = el.htmlFor.replace(/_\d+_/, `_${newIndex}_`);
      }
    });
    newCard.querySelector(`[data-tier-group-rules-target="title"]`).textContent = `Rule #${newIndex + 1}`;

    // Show the delete button
    const deleteButton = newCard.querySelector('[data-tier-group-rules-target="deleteButton"]');
    deleteButton.classList.remove('hidden');

    this.element.insertBefore(newCard, this.addButtonTarget);

    // Smoothly animate the new card
    await showElement(newCard);

    this.refreshTargets();

    this.updateAddButtonAndMaxAlert();
  }

  async deleteRule(event) {
    const card = event.target.closest('[data-tier-group-rules-target="card"]');

    // Smoothly animate the removal of the card
    await hideElement(card);

    card.remove();

    // Update indexes of remaining cards
    this.cardTargets.forEach((card, index) => {
      card.querySelectorAll('input, select, label').forEach(el => {
        if (el.name) {
          el.name = el.name.replace(/\[\d+\]/, `[${index}]`);
        }
        if (el.id) {
          el.id = el.id.replace(/_\d+_/, `_${index}_`);
        }
        if (el.htmlFor) {
          el.htmlFor = el.htmlFor.replace(/_\d+_/, `_${index}_`);
        }
      });
      card.querySelector(`[data-tier-group-rules-target="title"]`).textContent = `Rule #${index + 1}`;
    });

    this.refreshTargets();

    this.updateAddButtonAndMaxAlert();
  }

  updateAddButtonAndMaxAlert() {
    if (this.cardTargets.length >= 5) {
      this.addButtonTarget.classList.add('hidden')
      this.maxAlertTarget.classList.remove('hidden');
    } else {
      this.maxAlertTarget.classList.add('hidden')
      this.addButtonTarget.classList.remove('hidden');
    }
  }

  refreshTargets() {
    this.targets.findAll('card');
    this.targets.findAll('deleteButton');
  }
}
