import { Controller } from 'stimulus';

export default class extends Controller {

  static values = { message: String };

  confirm(event) {
    const formattedMessage = this.messageValue.replace(/\\n/g, '\n');

    if (!window.confirm(formattedMessage)) {
      event.preventDefault();
    }
  }
}
