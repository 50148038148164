import { Controller } from 'stimulus';

export default class DialogController extends Controller {
  static targets = ['dialog'];

  declare readonly dialogTarget: any;

  open(event?: Event) {
    event?.preventDefault();
    if ((event?.currentTarget as HTMLElement).hasAttribute('disabled')) {
      return;
    }

    this.dialogTarget.showModal();
    this.dialogTarget.addEventListener('click', (e: any) =>
      this.backdropClick(e)
    );
  }

  close(event?: Event) {
    event?.preventDefault();

    this.dialogTarget.close();
  }

  backdropClick(event: Event) {
    event.target === this.dialogTarget && this.close(event);
  }
}
