import * as Chains from '@wagmi/core/chains';
import * as CustomChains from './chains/chains';
import * as Contracts from './abis/abis';
import { erc20Abi } from 'viem';

export function chainName(chainId: number | undefined): string {
  if (chainId === undefined) {
    return 'Unknown';
  }
  const allChains = { ...Chains, ...CustomChains };
  const chain = Object.entries(allChains).find(([_, chain]) => {
    return chain.id === chainId;
  });
  if (chain === undefined) {
    return 'Unknown';
  }
  return chain[1].name || 'Unknown';
}

const ETH_MAINNET_USDT_ADDRESS_IDENTIFIER =
  '0xdac17f958d2ee523a2206206994597c13d831ec7';

export function abiForToken(tokenAddress: string, chainId: number): any {
  if (
    tokenAddress === ETH_MAINNET_USDT_ADDRESS_IDENTIFIER &&
    chainId === Chains.mainnet.id
  ) {
    return Contracts.usdtAbi;
  }
  return erc20Abi;
}
