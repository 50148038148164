import { Connector, GetAccountReturnType } from '@wagmi/core';
import { Address } from 'viem';

interface Web3AccountChangeEvent
  extends CustomEvent<{ account: GetAccountReturnType }> {}
declare global {
  interface DocumentEventMap {
    Web3AccountChange: Web3AccountChangeEvent;
  }
}
interface Web3InitializedEvent
  extends CustomEvent<{ connectors: Array<Web3Connector> }> {}
declare global {
  interface DocumentEventMap {
    Web3Initialized: Web3InitializedEvent;
  }
}
interface Web3ConnectorsAddedEvent
  extends CustomEvent<{ connectors: Array<Web3Connector> }> {}
declare global {
  interface DocumentEventMap {
    Web3ConnectorsAdded: Web3ConnectorsAddedEvent;
  }
}

type ChainNode = {
  url: string;
};
export type ChainConfig = {
  id: number;
  name: string;
  dropletAddress?: Address;
  deckAddress?: Address;
  nativeCurrency: string;
  chainNodes: ChainNode[];
  testnet: boolean;
};
export type Web3Chain = {
  id: number;
  name: string;
  supported: boolean;
};
export type Web3Connector = {
  id: string;
  name: string;
  type: string;
  icon?: string;
};
export type Web3Account = {
  status: string;
  address: string | undefined;
  chain: Web3Chain;
  connector?: Web3Connector;
};
export type Web3Token = {
  name: string | undefined;
  symbol: string | undefined;
  decimals: number | undefined;
};
export type Web3ErrorLogger = {
  logError: (error: Error | string, metadata?: any) => void;
};
export function mapConnector(connector: Connector | undefined) {
  if (connector) {
    return {
      id: connector.id,
      name: connector.name,
      icon: connector.icon,
      type: connector.type,
    };
  }
}
