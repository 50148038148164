import { Controller } from 'stimulus';

// Used to embed YouTube videos in ActionText attachments
export default class extends Controller {
  connect(){
    this.element.querySelectorAll('action-text-attachment[content-type="image"]').forEach(attachment => {
      const imgUrl = attachment.querySelector('img').src;
      if (imgUrl.includes("youtube.com/vi/")) {
        /// Extract the video ID from the URL
        const videoID = imgUrl.split('/vi/')[1].split('/')[0];

        // Create a wrapper div for responsiveness
        const divWrapper = document.createElement('div');
        divWrapper.className = 'responsive-video-wrapper';

        // Create an iframe element
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.youtube.com/embed/${videoID}`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', 'true');

        // Append iframe to the div wrapper
        divWrapper.appendChild(iframe);

        // Replace the figure with the responsive div wrapper
        const figure = attachment.querySelector('figure');
        figure.parentNode.replaceChild(divWrapper, figure);
      }
    })
  }
}
