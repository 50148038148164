import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pepFields", "pepAdjacentFields", "notPepFields"];
  toggleFields(event) {
    switch (event.target.value) {
      case "pep":
        this.pepFieldsTarget.classList.remove("hidden");
        this.pepAdjacentFieldsTarget.classList.add("hidden");
        this.notPepFieldsTarget.classList.add("hidden");
        break;
      case "pep_adjacent":
        this.pepFieldsTarget.classList.add("hidden");
        this.pepAdjacentFieldsTarget.classList.remove("hidden");
        this.notPepFieldsTarget.classList.add("hidden");
        break;
      case "not_pep":
        this.pepFieldsTarget.classList.add("hidden");
        this.pepAdjacentFieldsTarget.classList.add("hidden");
        this.notPepFieldsTarget.classList.remove("hidden");
        break;
      default:
        console.error("Invalid value for pep declaration: ", event.target.value);
    }
  }
}
