import { Controller } from 'stimulus';
import debounce from 'debounce';

export default class extends Controller {
  static targets = ['tab', 'button'];

  declare readonly tabTargets: HTMLDivElement[];
  declare readonly buttonTargets: HTMLButtonElement[];

  connect() {
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 300);
  }

  handleButtonClick(event: Event) {
    const button = event.target as HTMLButtonElement;
    const tab = this.tabTargets.find(
      (tab) => tab.dataset.tab === button.dataset.tab
    );

    if (tab) {
      this.tabTargets.forEach((tab) => tab.classList.add('hidden'));
      this.buttonTargets.forEach((button) => {
        button.classList.remove('otc-tab-active');
        button.classList.add('otc-tab');
        button.removeAttribute('disabled'); // Enable all buttons first
      });

      tab.classList.remove('hidden');
      button.classList.remove('otc-tab');
      button.classList.add('otc-tab-active');
      button.setAttribute('disabled', 'true'); // Disable the active button
    }
  }
}
