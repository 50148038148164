import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['disableableElement'];

  declare readonly disableableElementTargets: [HTMLInputElement];

  disableElements() {
    this.disableableElementTargets.forEach((element: HTMLInputElement) => {
      element.disabled = true;
      element.classList.add('n-button--disabled');
    })
  }
}
