import { Status, StatusType } from '../../javascript/controllers/compass/model/status';
import { ICompassStatusViewInput } from '../../javascript/controllers/compass/payments/status/view_input';
import { Controller } from 'stimulus';

export default class StatusController
  extends Controller
  implements ICompassStatusViewInput
{
  static targets = ['title', 'message', 'link', 'loadingIndicator'];

  declare readonly titleTarget: HTMLHeadingElement;
  declare readonly messageTarget: HTMLParagraphElement;
  declare readonly linkTarget: HTMLLinkElement;
  declare readonly loadingIndicatorTarget: HTMLElement;

  showStatus({
    title,
    statusType = StatusType.info,
    message,
    linkText,
    linkUrl,
  }: Status): void {
    this.element.classList.remove('hidden');
    this.titleTarget.textContent = title;
    this.messageTarget.textContent = message || '';

    if (linkText && linkUrl) {
      this.linkTarget.classList.remove('hidden');
      this.linkTarget.href = linkUrl.toString();
      this.linkTarget.textContent = linkText;
    } else {
      this.linkTarget.classList.add('hidden');
    }
    if (statusType == StatusType.loading) {
      this.loadingIndicatorTarget.classList.remove('hidden');
    } else {
      this.loadingIndicatorTarget.classList.add('hidden');
    }
  }

  hideStatus(): void {
    this.element.classList.add('hidden');
  }
}
