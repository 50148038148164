import Web3Controller from './web3_controller';
import { Web3Account } from '../web3/magnet/types';

export default class extends Web3Controller {
  static targets = [
    'dealId',
    'distributionId',
    'submitBtn',
    'cancelBtn',
    'form',
    'aggregateBtn',
    'aggregateWarning',
    'processingMessage',
  ];

  static values = {
    contractAddress: String,
    deckReplacedDistributionId: Number,
    deckDistributionId: Number,
    queryDlis: String,
    creatorAddress: String,
  };

  declare readonly submitBtnTarget: HTMLFormElement;
  declare readonly cancelBtnTarget: HTMLFormElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly aggregateBtnTarget: HTMLDivElement;
  declare readonly aggregateWarningTarget: HTMLDivElement;
  declare readonly processingMessageTarget: HTMLSpanElement;
  declare readonly hasAggregateBtnTarget: boolean;
  declare readonly hasAggregateWarningTarget: boolean;

  declare readonly contractAddressValue: string;
  declare readonly deckReplacedDistributionIdValue: number;
  declare readonly deckDistributionIdValue: number;
  declare readonly queryDlisValue: string;
  declare readonly creatorAddressValue: string;

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountConnected(account: Web3Account) {
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountConnecting(account: Web3Account) {
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  onAccountDisconnected(account: Web3Account) {
    this.warnSignerMismatch(this.magnet?.web3Account, this.creatorAddressValue);
  }

  warnSignerMismatch(currentAccount: any, creatorAddress: string) {
    if (!this.hasAggregateBtnTarget || !this.hasAggregateWarningTarget) return;
    const address = currentAccount?.address?.toLowerCase();
    if (!address) return;

    if (address != creatorAddress) {
      this.aggregateBtnTarget.classList.add('hidden');
      this.aggregateWarningTarget.classList.remove('hidden');
    } else {
      this.aggregateBtnTarget.classList.remove('hidden');
      this.aggregateWarningTarget.classList.add('hidden');
    }
  }

  // Intercepts deck distribution creation to provide aggregation data
  // Marks the deck distribution for replacement (or finds out if already done in the contract)
  // Once it knows for sure that it has been marked for replacement, it retrieves the claim status from the contract for each supplied DLI entry
  // Then it adds the claim status data to the form submission for the rails controller to pick it up.
  async replaceDistribution(event: Event) {
    event.preventDefault();
    this.processingMessageTarget.classList.remove('hidden');
    this.submitBtnTarget.classList.add('hidden');
    this.submitBtnTarget.classList.remove('p-button--primary--green');
    this.cancelBtnTarget.classList.add('hidden');
    try {
      // 1- Read the contract and get the distribution object that we are going to replace
      let distributionIsMarkedForReplacement = false;
      const distribution = await this.magnet?.deckGetDistribution({
        distributionId: this.deckReplacedDistributionIdValue,
      });
      // 2- If it has not been marked for replacement, we attempt to do so
      distributionIsMarkedForReplacement = distribution[6];
      let transactionReceipt = null;
      if (!distributionIsMarkedForReplacement) {
        const preparedRequest =
          await this.magnet?.prepareDeckInvalidateDistributionForReplacement({
            distributionId: this.deckReplacedDistributionIdValue,
          });
        transactionReceipt = await this.magnet?.writePreparedTransaction({
          preparedRequest: preparedRequest,
        });

        if (transactionReceipt) distributionIsMarkedForReplacement = true;
      }

      // 3- If it is marked for replacement, then we read each dli claimed status (from the contract)
      // And we send that data back to the rails controller for processing the creation of the merkle tree
      if (distributionIsMarkedForReplacement) {
        const dlis = JSON.parse(this.queryDlisValue);
        const claimResults = [];
        const distributionIdIndexes = dlis.map((dli) => [dli.deck_index]);

        const results = await this.magnet?.deckGetClaimedDLIs({
          distributionId: this.deckReplacedDistributionIdValue,
          distributionIdIndexes: distributionIdIndexes,
        });

        for (let i = 0; i < dlis.length; i++) {
          const result = results[i];
          claimResults.push({
            id: dlis[i].id,
            deckID: dlis[i].deck_distribution_id,
            leafIndex: dlis[i].deck_index,
            claimed: result,
          });
        }

        // Add the retrieved data to the form to submit it to the controller.
        let form = this.formTarget;
        this.updateForm(claimResults, form);
        form.submit();
      }
    } catch (error) {
      console.error('Error:', error);
      this.processingMessageTarget.classList.add('hidden');
      this.submitBtnTarget.classList.add('p-button--primary--green');
      this.submitBtnTarget.classList.remove('hidden');
      this.cancelBtnTarget.classList.remove('hidden');
    }
  }

  // Intercepts the cancellation of a replacement distribution to check the contract data
  // Cancels the marking for replacement (or finds out if already done on the contract)
  // Once it knows for sure that it has been marked for replacement, it relays back to the rails controller to complete the cancellation.
  async cancelReplaceDistribution(event: Event) {
    event.preventDefault();
    this.processingMessageTarget.classList.remove('hidden');
    this.cancelBtnTarget.classList.add('hidden');
    this.cancelBtnTarget.classList.remove('p-button--secondary--red');
    this.submitBtnTarget.classList.add('hidden');
    try {
      // 1- Get the distribution we want to un-replace from the contract
      let distributionIsMarkedForReplacement = false;
      const distribution = await this.magnet?.deckGetDistribution({
        distributionId: this.deckReplacedDistributionIdValue,
      });
      // 2- If it has been marked for replacement, then we can submit the tx to undo that.
      distributionIsMarkedForReplacement = distribution[6];
      let transactionReceipt = null;
      if (distributionIsMarkedForReplacement) {
        const preparedRequest =
          await this.magnet?.prepareDeckCancelInvalidateDistributionForReplacement(
            {
              distributionId: this.deckReplacedDistributionIdValue,
            }
          );
        transactionReceipt = await this.magnet?.writePreparedTransaction({
          preparedRequest: preparedRequest,
        });
        if (transactionReceipt) distributionIsMarkedForReplacement = false;
      }

      // 3- If it is not marked for replacement anymore, then we let the rails controller know
      if (!distributionIsMarkedForReplacement) {
        let form = this.formTarget;
        form.submit();
      }
    } catch (error) {
      console.error('Error:', error);
      this.processingMessageTarget.classList.add('hidden');
      this.submitBtnTarget.classList.remove('hidden');
      this.cancelBtnTarget.classList.remove('hidden');
      this.cancelBtnTarget.classList.add('p-button--secondary--red');
    }
  }

  // Intercepts deck distribution invalidation to provide extra data
  // Invalidates a distribution and reclaims the tokens not yet claimed
  async invalidateDistribution(event: Event) {
    event.preventDefault();
    this.processingMessageTarget.classList.remove('hidden');
    this.submitBtnTarget.classList.add('hidden');
    this.submitBtnTarget.classList.remove('p-button--primary--red');
    try {
      // 1- Get the distribution we want to invalidate
      let distributionIsInvalidated = false;
      const distribution = await this.magnet?.deckGetDistribution({
        distributionId: this.deckDistributionIdValue,
      });
      // 2- If it is not invalidated, we can invalidate it and reclaim the tokens.
      distributionIsInvalidated = distribution[4];
      let transactionReceipt = null;
      if (!distributionIsInvalidated) {
        const preparedRequest = await this.magnet?.prepareDeckReclaimTokens({
          distributionId: this.deckDistributionIdValue,
        });
        transactionReceipt = await this.magnet?.writePreparedTransaction({
          preparedRequest: preparedRequest,
        });
        if (transactionReceipt) distributionIsInvalidated = true;
      }

      // 3- If it is now invalidated, then we let the rails controller know
      if (distributionIsInvalidated) {
        let form = this.formTarget;
        form.submit();
      }
    } catch (error) {
      console.error('Error:', error);
      this.processingMessageTarget.classList.add('hidden');
      this.submitBtnTarget.classList.remove('hidden');
      this.submitBtnTarget.classList.add('p-button--primary--red');
    }
  }

  updateForm(claimedData: any, form: HTMLFormElement) {
    // Add a hidden field to the form with the itemsClaimed array
    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'claimed_data');
    input.setAttribute('value', JSON.stringify(claimedData));
    form.appendChild(input);
  }
}
