// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import Carousel from 'stimulus-carousel'

const application = Application.start();

if(!!import.meta.env.VITE_STIMULUS_DEBUG) {
  application.debug = true;
}

const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
const typescriptControllers = import.meta.glob('./**/*_controller.ts', {
  eager: true,
});
const nakamotoControllers = import.meta.glob(
  '../../../lib/**/*_controller.js',
  { eager: true }
);
const nakamotoTypescriptControllers = import.meta.glob(
  '../../../lib/**/*_controller.ts',
  { eager: true }
);
const appComponentsControllers = import.meta.glob(
  '../../components/**/*_controller.js',
  { eager: true }
);
const appTypescriptComponentsControllers = import.meta.glob(
  '../../components/**/*_controller.ts',
  { eager: true }
);

registerControllers(application, controllers);
registerControllers(application, nakamotoControllers);
registerControllers(application, appComponentsControllers);
registerControllers(application, typescriptControllers);
registerControllers(application, nakamotoTypescriptControllers);
registerControllers(application, appTypescriptComponentsControllers);
application.register('carousel', Carousel)
