import Web3Controller from '../../javascript/controllers/web3_controller';
import { CompassConfirmPresenter } from '../../javascript/controllers/compass/payments/confirm/presenter';
import CompassConfirmRouter from '../../javascript/controllers/compass/payments/confirm/router';
import { Payable } from '../../javascript/controllers/compass/model/payable';
import { IPaymentIntent } from '../../javascript/controllers/compass/model/payment_intent';
import { ICompassConfirmViewInput } from '../../javascript/controllers/compass/payments/confirm/view_input';
import { ICompassConfirmViewOutput } from '../../javascript/controllers/compass/payments/confirm/view_output';
import { walletShortname } from '../../javascript/helpers/wallet_helpers';
import { ICompassStatusViewInput } from '../../javascript/controllers/compass/payments/status/view_input';
import { ICompassStatusViewOutput } from '../../javascript/controllers/compass/payments/status/view_output';
import { PresailMagnetWrapper } from '../../javascript/web3/presail_magnet_wrapper';
import { Web3Account, Web3Connector } from '../../javascript/web3/magnet/types';

export default class ConfirmationController
  extends Web3Controller
  implements ICompassConfirmViewInput
{
  static targets = [
    'actionButton',
    'backButton',
    'cancelButton',
    'paymentIntentGUIDDisplay',
    'countdown',
  ];

  declare readonly actionButtonTarget: HTMLButtonElement;
  declare readonly backButtonTarget: HTMLButtonElement;
  declare readonly cancelButtonTarget: HTMLButtonElement;
  declare readonly paymentIntentGUIDDisplayTarget: HTMLSpanElement;
  declare readonly countdownTarget: HTMLElement;

  declare readonly hasBackButtonTarget: boolean;
  declare readonly hasCancelButtonTarget: boolean;
  declare readonly hasCountdownTarget: boolean;
  declare stopCountingDown: boolean;

  static values = {
    compassBaseUrl: String,
    payable: Object,
    paymentIntent: Object,
    paymentState: String,
    successUrl: String,
    expiresAt: String,
  };

  declare readonly compassBaseUrlValue: string;
  declare readonly payableValue: Payable;
  declare readonly paymentIntentValue: IPaymentIntent;
  declare readonly paymentStateValue: string;
  declare readonly successUrlValue: string;
  declare readonly expiresAtValue: string;

  static outlets = ['compass--status'];
  protected declare readonly compassStatusOutlet: ICompassStatusViewInput;

  compassPresenter?: ICompassConfirmViewOutput & ICompassStatusViewOutput;

  async connect() {
    /*
     Need to wait for the next tick to have compassStatusOutlet instantiated.
     See https://stimulus.hotwired.dev/reference/lifecycle-callbacks#order-and-timing and
     https://github.com/hotwired/stimulus/issues/201
     */
    Promise.resolve().then(async () => {
      this.compassPresenter ||= new CompassConfirmPresenter({
        compassBaseUrl: this.compassBaseUrlValue,
        payable: this.payableValue,
        paymentIntent: this.paymentIntentValue,
        paymentState: this.paymentStateValue,
        expiresAt: new Date(this.expiresAtValue),
        view: this,
        statusView: this.compassStatusOutlet,
        router: new CompassConfirmRouter({
          application: this.application,
          compassBaseUrl: this.compassBaseUrlValue,
          successUrl: this.successUrlValue,
        }),
        addressFormatter: walletShortname,
      });
      await super.connect();
    });

    if (this.hasCountdownTarget) {
      this.countdown();
    }
  }

  disconnect() {
    this.stopCountingDown = true;
  }

  compassStatusOutletConnected(
    outlet: ICompassStatusViewInput,
    element: HTMLElement
  ) {
    this.compassPresenter?.onCompassStatusViewInputUpdated(outlet);
  }

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    await this.compassPresenter?.onWeb3Connect(
      new PresailMagnetWrapper(this.magnet!)
    );
  }

  onAccountConnected(account: Web3Account) {
    this.compassPresenter?.onWeb3ChainChange({
      id: this.magnet!.web3Account!.chain.id,
      name: this.magnet!.web3Account!.chain.name,
    });
    super.onAccountConnected(account);
  }

  onAccountDisconnected(account: Web3Account) {
    this.disableActionButton();
    super.onAccountDisconnected(account);
  }

  onActionButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onActionButtonClick();
  }

  onBackButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onBackButtonClick();
  }

  onCancelButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onCancelButtonClick();
  }

  onCloseButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onCloseButtonClick();
  }

  /*
   * IViewInput implementation
   */
  enableActionButton(): void {
    this.actionButtonTarget.disabled = false;
    this.actionButtonTarget.classList.remove('n-button--disabled');
  }

  disableActionButton(): void {
    this.actionButtonTarget.disabled = true;
    this.actionButtonTarget.classList.add('n-button--disabled');
  }

  showBackOrCancelButton(): void {
    if (this.hasBackButtonTarget) {
      this.backButtonTarget.classList.remove('hidden');
    }
    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.remove('hidden');
    }
  }

  hideBackOrCancelButton(): void {
    if (this.hasBackButtonTarget) {
      this.backButtonTarget.classList.add('hidden');
    }
    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.add('hidden');
    }
  }

  showPaymentIntentGUID(paymentIntentGUID: string): void {
    this.paymentIntentGUIDDisplayTarget.textContent = paymentIntentGUID;
  }

  countdown(): void {
    if (this.stopCountingDown) {
      return;
    }

    let currentNumber = parseInt(this.countdownTarget.textContent || '0', 10);
    if (currentNumber > 0) {
      this.countdownTarget.textContent = (currentNumber - 1).toString();
      setTimeout(() => this.countdown(), 1000);
    } else {
      // Close the form when the time expires, if it's in a state where it's possible to close
      if (!this.cancelButtonTarget.classList.contains('hidden')) {
        this.compassPresenter?.onCancelButtonClick();
      }
    }
  }
}
