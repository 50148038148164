import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['clearable'];

  clear() {
    for (let clearable of this.clearableTargets) {
      this.clearableTarget.value = '';
    }
  }
}
