export type PreparedTransaction = {
  transaction: any;
  gasLimit?: number | undefined;
  data?: string | undefined;
}

export abstract class AbstractPreparedTransaction implements PreparedTransaction {
  transaction: any;

  public constructor(transaction: any) {
    this.transaction = transaction;
  }

  get gasLimit(): number | undefined {
    return this.transaction.request?.gasLimit;
  }

  get data(): string | undefined {
    return this.transaction.request?.data;
  }
}
