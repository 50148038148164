import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.checkIfAccordionShouldOpen();
  };

  // We also trigger this method from the hashchange event.
  // That way, we run this method every time only the page anchor changes in the URL
  checkIfAccordionShouldOpen() {
    if (this.anchorElementIsInsideAccordion()) {
      this.open();
    } else {
      this.close();
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.contentTarget.parentElement.classList.toggle("open");
  }

  open() {
    this.contentTarget.classList.remove("hidden");
    this.contentTarget.parentElement.classList.add("open");
  }

  close() {
    this.contentTarget.classList.add("open");
    this.contentTarget.parentElement.classList.remove("hidden");
  }

  anchorElementIsInsideAccordion() {
    const anchor = window.location.hash.substring(1);
    if (!anchor) return false;

    const anchorElement = document.getElementById(anchor);
    if (!anchorElement) return false;

    return this.element.contains(anchorElement);
  }
}
