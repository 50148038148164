import { Controller } from 'stimulus';
import ListController from '../list/list_controller';
import DialogController from '../dialog/dialog_controller';

export default class DialogSelectController extends Controller {
  static targets = [
    'value',
    'icon',
    'iconEmpty',
    'iconEmptyIcon',
    'loadingIndicator',
  ];

  declare readonly valueTarget: HTMLElement;
  declare readonly iconTarget: HTMLImageElement;
  declare readonly loadingIndicatorTarget: HTMLElement;
  declare readonly iconEmptyTarget: HTMLElement;
  declare readonly iconEmptyIconTarget: HTMLElement;

  onButtonClick(): void {
    this.dialogController?.open();
  }

  closeDialog(): void {
    this.dialogController?.close();
  }

  showLoadingIndicator(): void {
    this.valueTarget.classList.add('hidden');
    this.loadingIndicatorTarget.classList.remove('hidden');
  }

  hideLoadingIndicator(): void {
    this.valueTarget.classList.remove('hidden');
    this.loadingIndicatorTarget.classList.add('hidden');
  }

  select(listItemId: string) {
    this.listController?.select(listItemId);
    const selectedListItem = this.listController?.getSelectedListItem()!;
    this.valueTarget.textContent = selectedListItem.dataset.listItemValue!;
    if (selectedListItem.dataset.listItemIconPath) {
      this.iconTarget.src = selectedListItem.dataset.listItemIconPath;
      this.iconTarget.classList.remove('hidden');
      this.iconEmptyTarget.classList.add('hidden');
    } else {
      this.iconTarget.classList.add('hidden');
      this.iconEmptyTarget.classList.remove('hidden');
      this.iconEmptyIconTarget.textContent =
        selectedListItem.dataset.listItemValue?.substring(0, 1)!;
    }
    this.hideLoadingIndicator();
  }

  unselectAll(displayText?: string) {
    this.listController?.unselectAll();
    if (displayText) {
      this.valueTarget.textContent = displayText;
    }
  }

  private get dialogController(): DialogController | undefined {
    const controllerElement = this.element.querySelector(
      '[data-controller~=dialog--dialog]'
    );
    if (controllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        controllerElement,
        'dialog--dialog'
      ) as DialogController;
    }
  }

  private get listController(): ListController | undefined {
    const controllerElement = this.element.querySelector(
      '[data-controller~=list--list]'
    );
    if (controllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        controllerElement,
        'list--list'
      ) as ListController;
    }
  }
}
