import { BackendJSON, IPaymentIntent, TokenTxJSON } from '../payment_intent';
import { Payable } from '../payable';
import { PaymentMethod } from '../payment_method';
import { PreparedTransaction } from '../prepared_transaction';

type TokenTxPaymentIntentParams = {
  guid?: string;
  payable: Payable;
  amount?: number;
  paymentMethod: PaymentMethod;
  preparedTransaction?: PreparedTransaction;
};
export class TokenTxPaymentIntent implements IPaymentIntent {
  guid?: string;
  payable: Payable;
  amount?: number;
  paymentMethod: PaymentMethod;
  preparedTransaction?: PreparedTransaction;

  public constructor({
    guid,
    payable,
    amount,
    paymentMethod,
    preparedTransaction,
  }: TokenTxPaymentIntentParams) {
    this.guid = guid;
    this.payable = payable;
    this.amount = amount;
    this.paymentMethod = paymentMethod;
    this.preparedTransaction = preparedTransaction;
  }

  toJson(): TokenTxJSON {
    return {
      to: this.toAddressIdentifier,
      amount: this.amount,
      token: this.paymentMethod.token.addressIdentifier,
      decimals: this.paymentMethod.token.decimals,
    } as TokenTxJSON;
  }

  toBackendJson(): BackendJSON {
    return {
      from_address_identifier: this.paymentMethod.addressIdentifier,
      to_address_identifier: this.toAddressIdentifier,
      via_address_identifier: this.paymentMethod.token.addressIdentifier,
      token_id: this.paymentMethod.token.id,
      amount: this.amount?.toString(),
      gas: this.preparedTransaction?.gasLimit?.toString(),
      data: this.preparedTransaction?.data?.toString(),
    } as BackendJSON;
  }

  get toAddressIdentifier(): string {
    return this.payable.transfers[0].toAddressIdentifier;
  }
}
