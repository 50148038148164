import { Controller } from "stimulus";
import debounce from "debounce";
import { flash } from "../lib/flashes";

export default class extends Controller {
  static targets = ["hiddenSubmitButton", "form", "formLabel", "checkbox"];
  static values = { debounceTimeMs: Number, confirmation: String, flashMessage: String };

  connect() {
    super.connect();
    if (this.hasDebounceTimeMsValue) {
      this.submit = debounce(this.submit.bind(this), this.debounceTimeMsValue);
    }
  }

  async submit(event) {
    if (!this.#hasConfirmationMessage() || confirm(this.confirmationValue)) {
      if (this.hasFormTarget && this.formTarget.requestSubmit) {
        this.formTarget.requestSubmit();
      } else {
        this.hiddenSubmitButtonTarget.click();
      }
    } else {
      event.preventDefault();
    }

    if (this.hasFlashMessageValue){
      flash(this.flashMessageValue);
    }
  }

  #hasConfirmationMessage() {
    return this.confirmationValue != undefined && this.confirmationValue != "";
  }
}
