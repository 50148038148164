export const abi = [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'target',
          type: 'address',
        },
      ],
      name: 'AddressEmptyCode',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'AddressInsufficientBalance',
      type: 'error',
    },
    {
      inputs: [],
      name: 'ArraysLengthMismatch',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'claimedTokens',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'lockedTokens',
          type: 'uint256',
        },
      ],
      name: 'ClaimedTokensExceedLockedAmount',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
      ],
      name: 'DistributionAlreadyReplaced',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
      ],
      name: 'DistributionInvalidated',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
      ],
      name: 'DistributionMarkedForReplacement',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
      ],
      name: 'DistributionNotInvalidated',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
      ],
      name: 'DistributionNotMarkedForReplacement',
      type: 'error',
    },
    {
      inputs: [],
      name: 'FailedInnerCall',
      type: 'error',
    },
    {
      inputs: [],
      name: 'InvalidMerkleProof',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NonExactAmountTransfer',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'SafeERC20FailedOperation',
      type: 'error',
    },
    {
      inputs: [],
      name: 'TokenMismatch',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'recipient',
          type: 'address',
        },
      ],
      name: 'TokensAlreadyClaimed',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'caller',
          type: 'address',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'UnauthorizedAccess',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionReplacedId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
      ],
      name: 'DistributionReplaced',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'invalidated',
          type: 'bool',
        },
      ],
      name: 'DistributionSetInvalidated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'recipient',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'transferRecipient',
          type: 'address',
        },
      ],
      name: 'TokensClaimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'lockedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'depositedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'forceExactAmountTransfer',
          type: 'bool',
        },
      ],
      name: 'TokensDeposited',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'merkleRoot',
          type: 'bytes32',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'lockedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'bool',
          name: 'forceExactAmountTransfer',
          type: 'bool',
        },
      ],
      name: 'TokensLocked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'distributionId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'reclaimedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'TokensReclaimed',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
        {
          internalType: 'uint256[]',
          name: '_indexes',
          type: 'uint256[]',
        },
      ],
      name: 'areClaimed',
      outputs: [
        {
          internalType: 'bool[]',
          name: '',
          type: 'bool[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
      ],
      name: 'cancelInvalidateDistributionForReplacement',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256[]',
          name: '_distributionIds',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '_indexes',
          type: 'uint256[]',
        },
        {
          internalType: 'uint256[]',
          name: '_amounts',
          type: 'uint256[]',
        },
        {
          internalType: 'bytes32[][]',
          name: '_proofs',
          type: 'bytes32[][]',
        },
      ],
      name: 'claimMultipleTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_index',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'bytes32[]',
          name: '_proof',
          type: 'bytes32[]',
        },
      ],
      name: 'claimTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_index',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: '_account',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'bytes32[]',
          name: '_proof',
          type: 'bytes32[]',
        },
      ],
      name: 'clawbackTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_tokenAmount',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: '_forceExactAmountTransfer',
          type: 'bool',
        },
      ],
      name: 'depositTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      name: 'distributions',
      outputs: [
        {
          internalType: 'bytes32',
          name: 'merkleRoot',
          type: 'bytes32',
        },
        {
          internalType: 'uint256',
          name: 'lockedTokens',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'claimedTokens',
          type: 'uint256',
        },
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'invalidated',
          type: 'bool',
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          internalType: 'bool',
          name: 'markedForReplacement',
          type: 'bool',
        },
        {
          internalType: 'bool',
          name: 'replaced',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'distributionsCount',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
      ],
      name: 'invalidateDistributionForReplacement',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: '_index',
          type: 'uint256',
        },
      ],
      name: 'isClaimed',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_token',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_totalTokens',
          type: 'uint256',
        },
        {
          internalType: 'bytes32',
          name: '_merkleRoot',
          type: 'bytes32',
        },
        {
          internalType: 'bool',
          name: '_replacesDistribution',
          type: 'bool',
        },
        {
          internalType: 'uint256',
          name: '_distributionToReplace',
          type: 'uint256',
        },
        {
          internalType: 'bool',
          name: '_forceExactAmountTransfer',
          type: 'bool',
        },
      ],
      name: 'lockTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_distributionId',
          type: 'uint256',
        },
      ],
      name: 'reclaimTokensAndInvalidateDistribution',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];
  