// Calculates the modulo of two numbers, even if they are floating-point numbers
export function floatModulo(a: number, b: number): number {
  const epsilon = 1e-18; // Set a small epsilon value for floating-point comparisons

  // Check if the numbers are close to integers
  const isACloseToInt = Math.abs(Math.round(a) - a) < epsilon;
  const isBCloseToInt = Math.abs(Math.round(b) - b) < epsilon;

  // If both numbers are close to integers, use the built-in modulo operator
  if (isACloseToInt && isBCloseToInt) {
    return a % b;
  }

  // Otherwise, perform modulo using floor division and subtraction
  // Adjust the numbers to avoid floating-point errors (otherwise, 3.1 % 0.1 = 0.09999999999999992)
  const adjustmentFactor = 1000;
  const adjustedA = a * adjustmentFactor;
  const adjustedB = b * adjustmentFactor;
  const quotient = Math.floor(adjustedA / adjustedB);
  const result = adjustedA - quotient * adjustedB;

  return result;
}
