import { Controller } from 'stimulus';
import TextFieldController from '../../../../lib/nakamoto/components/forms/text_field_controller';

export default class GroupTransferFormController extends Controller {
  static outlets = ['forms--new-text-field'];
  declare readonly formsNewTextFieldOutlet: TextFieldController;

  onSelectedGroupChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    const maxAmount = select.options[select.selectedIndex].dataset.maxAmount;
    if (maxAmount) {
      this.formsNewTextFieldOutlet.setAddOnValue(maxAmount);
    }
  }
}
