import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scrollTarget"]
  static values = {
    behavior: { type: String, default: "smooth" },
    offset: { type: Number, default: 0 },
    targetSelector: String
  }

  connect() {
  }

  scrollTo(event) {
    event.preventDefault()

    let target

    // First try to find target via stimulus target
    if (this.hasScrollTargetTarget) {
      target = this.scrollTargetTarget
    }
    // Then try via targetSelector value
    else if (this.hasTargetSelectorValue) {
      target = document.querySelector(this.targetSelectorValue)
    }
    // Finally try finding by ID
    else {
      target = document.getElementById("gleam")
    }

    if (!target) {
      console.warn("Scroll target not found")
      return
    }

    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = targetPosition - this.offsetValue

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behaviorValue
    })
  }
}
